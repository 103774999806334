import React from 'react'
import img from '../Assets/Rectangle 63.png'

function Experience() {
  return (
   <>
    <div className='exprience'>
    <div className='exprience-left'>
    <img src={img}/>

    </div>
    <div className='exprience-right'>
<h1>EXPERIENCE <span>THE DIVINE</span> WITH<br/> BHARAT360</h1>
<p>Immerse yourself in the sacred with Bharat360. Explore spiritual journeys, hire experienced Pandits, receive divine Prashad, participate in Satsangs, and witness live Darshan. Earn blessings through our Bharat Sikka rewards program.</p>
</div>

    </div>
   </>
  )
}

export default Experience