import React, { useEffect } from "react";
import "./home.css";
import AOS from "aos";
import Slider from "react-slick";
import FirstItem from "./heroSliderCompo/FirstItem";
import SecondItem from "./heroSliderCompo/SecondItem";
import ThirdItem from "./heroSliderCompo/ThirdItem";
import FourItem from "./heroSliderCompo/FourItem";
import FiveItem from "./heroSliderCompo/FiveItem";
import SixItem from "./heroSliderCompo/SixItem";

function HeroSection() {
  useEffect(() => {
    AOS.init({
      duration: 1000
    });
    
  }, [])

  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <div>
      <Slider {...settings}>
        <div>
          <FirstItem />
        </div>
        <div>
          <SecondItem />
        </div>
        <div>
          <ThirdItem />
        </div>
        <div>
          <FourItem />
        </div>
        <div>
          <FiveItem />
        </div>
        <div>
          <SixItem />
        </div>
      </Slider>
    </div>
  );
}

export default HeroSection;
