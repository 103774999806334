import React, { useState } from "react";
import BookingContext from "./bookingContext";


const BookingState = (props) => {
    const [pujaName, setPujaName] = useState("");
    const [cityLocation, setCityLocation] = useState("");
    const [selectedDate, setSelectedDate] = useState("");
    const [selectedTime, setSelectedTime] = useState("");
    const [templeName, setTempleName] = useState("");
    const [selectTempId, setSelectTempId] = useState("");
    const [templeId, setTempleId] = useState("");
    const [selectPuja, setSelectPuja] = useState("");
    const [price, setPrice] = useState("");
    const [Mode, setMode] = useState("ONLINE");
    const [selectPanditName, setPanditName]= useState("");
    const [pujaId, setPujaId] = useState("");

    
    return(
        <BookingContext.Provider value={{pujaId, setPujaId, pujaName, setPujaName, cityLocation, selectPanditName, setPanditName, setCityLocation, selectedDate, setSelectedDate, selectedTime, setSelectedTime, templeName, setTempleName, selectTempId, setSelectTempId, price, setPrice, Mode, setMode, templeId, setTempleId, selectPuja, setSelectPuja}} >
            {props.children}
        </BookingContext.Provider>
    )
}

export default BookingState;