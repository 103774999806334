import { Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import pandit_img from "../../assets/image 25.png";
import { useNavigate } from "react-router-dom";

function Pandit() {
  const navigate = useNavigate();
  function handleOnClick() {
    navigate("/pandit")
  }
  return (
    <>
      <div className="pandit-section">
        <Container>
          <Grid container rowSpacing={4}>
            <Grid item sm={12} lg={6}>
              <Typography>
                <img className="pandit-img" src={pandit_img} />
              </Typography>
            </Grid>
            <Grid item sm={12} lg={6}>
              <div className="pujas-box">
                <div className="pandit-title">
                  <span>Pandit</span>
                </div>
                <Typography className="pandit-text">
                  Bhart 360 addresses the need to make Indian spiritual services
                  accessible worldwide at the click of a button, whether it’s a
                  live Aarti or prasad delivered to your doorstep. While we move
                  towards digital accessibility in all of life’s daily
                  requirements, our founders believe that there is no reason our
                  spirituality should be left behind. Our Puja service offerings
                  bring you closer, virtually, to pujas conducted at renowned
                  temples by prestigious priests, irrespective of your
                  geographical location.
                </Typography>
                <Typography textAlign="left">
                  <Button className="pandit-book-btn" variant="contained" onClick={handleOnClick}>
                    Book a Puja
                  </Button>
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}

export default Pandit;
