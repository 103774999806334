import { createSlice } from "@reduxjs/toolkit";
// import { API } from "../api/BaseCall";


// const isLogin = false;
const slice = createSlice({
  name: "prashad",
  initialState: [],
  reducers: {
    userAdded: (state, action) => {
      state.push({
        displayName: action.payload.displayName,
        email: action.payload.email,
        password: action.payload.password,
        isAdmin: action.payload.isAdmin,
        phone: action.payload.phone,
        token: action.payload.token
      });
    },
    loggedIn: (state, action) => {
      state.push({
        email: action.payload.email,
        password: action.payload.password,
        token: action.payload.token,
        logIn: action.payload.logIn
      });
    },
   /*  loggedStatus: (state, action) => {
      state[action.payload.editIndex].token = action.payload.editToken;
      state[action.payload.editIndex].logIn = action.payload.editLogin;
    }, */
  },
});

export const { userAdded, loggedIn, loggedStatus} = slice.actions;
export default slice.reducer;
