import React, { useEffect } from "react";
import Navbar from "../components/header/Navbar";
import Footer from "../components/footer/footer";
import Joinus from '../components/BannerSection/JionUs'
import HeroSection from "../components/panditpage/HeroSection";
import SelectPuja from "../components/panditpage/SelectPuja";
import AccordingPuja from "../components/panditpage/AccordingPuja";
import BookPandit from "../components/panditpage/BookPandit";
import HowItsWorks from "../components/panditpage/HowItsWorks";

function Pandit() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="full">
      <Navbar />

<HeroSection />
{/* <SelectPuja /> */}
{/* <AccordingPuja /> */}
{/* <BookPandit />
<HowItsWorks /> */}
<Joinus/>
<Footer />
    </div>
  );
}

export default Pandit;
