import React, { useEffect } from 'react'
import Navbar from '../components/header/Navbar'
import Footer from '../components/footer/footer'
import SatsangHero from '../components/satsnag/SatsangHero'
import img from '../components/Assets/satgang (1).png'
import img2 from '../components/Assets/satgang (2).png'
import img3 from '../components/Assets/satgang (3).png'
import img4 from '../components/Assets/satgang (4).png'

import ss from '../components/Assets/SS.png'
import JionUs from '../components/BannerSection/JionUs'
function Satsang() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='full'>
    <Navbar />
    {/* <SatsangHero /> */}
    <div className='banner satgangbanner'>
  
  <h1>Discover Inner Peace Through Satsangs</h1>
  <p>Join our online spiritual gatherings to embark on a journey of self-discovery, wisdom, and tranquility.<br/> Connect with renowned spiritual leaders and fellow seekers in the comfort of your home.</p>
  

  <div className='filterinputs'>
  <div className='filterinputs-input find-satnag' >
  <i class="bi bi-search"></i>
  <input placeholder='Search for a place'/>

  </div>
  <button className='bannerbtn'>Search</button>
  

  </div>
  </div>
  <br/>
  <br/>
  <br/>

  <>
   
       <div className='TeerthPackages'>
        <h1>Explore <span>Our Teerth</span> Packages</h1>
        <p className='TeerthPackagesP'>Embark on a divine journey with our Teerth Yatra packages. Discover spiritual adventures <br></br>
        designed to enrich your soul and connect you with the divine. Explore Now.</p>
        <br/>
        <br/>
        <div className='scroll-container'>
      
        <div className='TeerthPackages-cards'>
        <div className='TeerthPackages-card'>
        <div className='TeerthPackages-card-img'>
        <img src={img}/>

        </div>
        <h3>Soulful Serenity"</h3>

        <p>Join us for "Soulful Serenity"
         satsangs, where we explore 
         the 
         depths of inner peace and serenity
          through guided meditations and 
          spiritual wisdom.</p>
         <h4>₹499 per package</h4>
         <button>Book your virtual seat</button>

        </div>
        <div className='TeerthPackages-card'>
        <div className='TeerthPackages-card-img'>
        <img src={img2}/>

        </div>
        <h3>Path to Enlightenment</h3>

        <p>Embark on the "Path to Enlightenment" through enlightening discussions, ancient teachings, and a supportive community of seekers</p>
         <h4>Free</h4>
         <button>Book your virtual seat</button>

        </div>
        <div className='TeerthPackages-card'>
        <div className='TeerthPackages-card-img'>
        <img src={img3}/>

        </div>
        <h3>Mystic Insights</h3>

        <p>Mystic Insights" satsangs delve into the mysteries of existence, inviting you to explore the profound and mystical aspects of spirituality</p>
         <h4>₹199 per package</h4>
         <button>Watch now</button>

        </div>
        <div className='TeerthPackages-card'>
        <div className='TeerthPackages-card-img'>
        <img src={img4}/>

        </div>
        <h3>Harmony Within</h3>

        <p>Discover the "Harmony Within" through satsangs that focus on inner balance, self-awareness, and the unity of mind, body, and soul.</p>
         <h4>₹149 per package</h4>
         <button>Book your virtual seat</button>

        </div>

        </div>
        </div>


<br/>
        <button className='vi-btn' style={{marginTop:'30px'}}>View all</button>

        </div>
 
    </>
<br/>
<br/>
    <div className='MomentBanner'>
    <h1>Previous Satsang Moments</h1>
  
    <p>A glimpse of the enriching experiences shared during our past satsangs. Join our upcoming satsangs to create your own meaningful<br/> memories.</p>
    <br/>
      <img src={ss}/>

    </div>
    <br/><br/>
    <JionUs/>
    <Footer />
    </div>
  )
}

export default Satsang