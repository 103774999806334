import { Grid } from "@mui/material";
import React from "react";
import RequestPrashad from "../prashadpage/requestPrashad/RequestPrashad";
import img7 from "../../assets/prashad-img/image 51.png";
import img8 from "../../assets/prashad-img/image 55.png";
import img9 from "../../assets/prashad-img/image 52.png";
// import { Link } from "react-router-dom";

function Discription() {
  return (
    <>
      <div className="discription-section">
        <div className="discription-title">
          <span>About Shree Siddhivinayak Ganapati Temple At Mumbai.</span>
        </div>
        <div className="discription-text">
          <p className="text-one">
            Shree Ganesh is the first to be worshipped before beginning any new
            project or venture as he is the destroyer of obstacles
            (Vighnaharta). This is Shree Siddhivinayak Ganapati Temple at
            Prabhadevi in Mumbai, a two-century-old Temple that fulfills the
            desires of the worshipers.
          </p>
          <p className="text-one">
            The city of Mumbai is a mute witness to places of worship &
            historical interest, which are not only popular but also of
            archaeological importance.
          </p>
          <p className="text-one">
            Arguably the most popular & significant places of worship are the
            Shree Siddhivinayak Ganapati Mandir situated at Prabhadevi. This
            temple was first consecrated on Thursday 19th November 1801, a fact
            that is noted in government records. The temple then was a small
            structure housing the black stone idol of Shree Siddhivinayak, which
            was two and half feet wide. The outstanding feature of this deity is
            the tilt of the trunk to the right side. The idol has four hands
            (Chaturbhuj), which contains a lotus in upper right, a small axe in
            upper left, holy beads in the lower right and a bowl full of Modaks
            (a delicacy which is a perennial favorite with Shree Siddhivinayak).
            Flanking the deity on both sides are Riddhi & Siddhi, goddesses
            signifying sanctity, fulfillment, prosperity and riches. Etched on
            the forehead of the deity is an eye, which resembles the third eye
            of Lord Shiva.
          </p>
          <p className="text-one">
            Disclaimer: Shreetemple.com is not a temple agent. We will do
            pooja/pray on your behalf to offer and deliver Prasad to your home.
            The amount will be used to buy and provide Prasad at the temple.
            And, to awning all assorted costs such as – Covering, Shipping
            Materials and also includes all government taxes. We Do original
            packing and safe guarded by Shreetemple’s packing. Normally it takes
            7 days you to receive this prasadam from temple, if you are outside
            India it takes 15 days to receive your prasadam.
          </p>
          <p className="text-one">
            # Shree Siddhivinayak Parsad , #Siddhivinayak temple
          </p>
          <p className="text-one">
            The temple is open to devotees from early morning till night, and it
            is considered auspicious to visit the temple on Tuesdays and
            Thursdays. The temple also organizes various cultural events and
            charitable activities throughout the year.
          </p>
          <p className="text-one">
            The temple also has a Dharamshala (guesthouse) where devotees can
            stay overnight and a charitable trust that provides financial
            assistance to the needy.
          </p>
          <p className="text-one">
            It is also one of the most visited temples in Mumbai and is known
            for its architectural beauty. It is also said that the temple
            fulfills the wishes of its devotees.
          </p>
        </div>
        <div className="how-to-rich">
          <h6>How To Reach?</h6>
          <Grid
            container
            rowSpacing={4}
            justifyContent={"space-between"}
          >
            <Grid item lg={6}>
              <div className="discription-box">
                <p className="text-one">
                  Here are a few options to reach the temple:
                </p>
                <p className="text-one">
                  By train: The nearest railway station to the temple is the
                  Dadar Central Railway Station, which is approximately 2 km
                  away. From there, you can take a taxi, auto-rickshaw, or local
                  bus to reach the temple.
                </p>
                <p className="text-one">
                  By bus: The nearest bus stop to the temple is the
                  Siddhivinayak Bus Stop, which is located just a few minutes
                  walk away. Mumbai has a well-developed network of local and
                  express buses, making it convenient for travelers to reach the
                  temple.
                </p>
              </div>
            </Grid>
            <Grid item lg={6}>
              <div className="map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d241316.6729344495!2d72.71637698729475!3d19.082502000415392!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c6306644edc1%3A0x5da4ed8f8d648c69!2sMumbai%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1683180106542!5m2!1sen!2sin"
                  width="537"
                  height="300"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="related-prashad">
          <div className="related-prashad-title">
            <span>Related Prashad</span>
          </div>
          <Grid container rowSpacing={4}>
              <RequestPrashad img={img7} title={"Omkareshwar Prashad"} />
              <RequestPrashad img={img8} title={"Dwarkadish prashad"} />
              <RequestPrashad img={img9} title={"Salasar Balaji"} />
          </Grid>
        </div>
      </div>
    </>
  );
}

export default Discription;
