import React from 'react'
import Navbar from '../header/Navbar'
import img from '../Assets/Rectangle 2422.png'
import img2 from '../Assets/TEERTHPAK (1).png'
import img3 from '../Assets/TEERTHPAK (2).png'
import img4 from '../Assets/TEERTHPAK (3).png'
import img1 from '../Assets/Rectangle 2224.png'
import img8 from '../Assets/SPRITIDIVERSITY (1).png'
import img5 from '../Assets/SPRITIDIVERSITY (2).png'
import img6 from '../Assets/SPRITIDIVERSITY (3).png'
import img7 from '../Assets/SPRITIDIVERSITY (4).png'
import Joinus from '../BannerSection/JionUs'
import Footer from '../footer/footer'
import { Link } from 'react-router-dom'



function TeerthHome() {
  return (
   <div className='full'>
    <Navbar/>
    <div className='banner Teerthbanner'>
  
  <h1>Discover Spiritual Journeys</h1>
  <p>Experience the Divine with Bharat360</p>
  

  <div className='filterinputs'>
  <div className='filterinputs-input'>

{/*  */}
<div className='searchfrom'>
<i class="bi bi-geo-alt"></i>
<div className='fromdiv'>
  <lable className='fromdivlabel' >FROM</lable>
  <input placeholder='city'/>
</div>



</div>
{/*  */}
  {/*  */}
  <div className='searchfrom'>
<i class="bi bi-geo-alt"></i>
<div className='fromdiv'>
  <lable className='fromdivlabel' >To</lable>
  <input placeholder='city'/>
</div>



</div>
{/*  */}
  {/*  */}
  <div className='searchfrom'>
  <i class="bi bi-calendar-date"></i>
<div className='fromdiv'>
  <lable className='fromdivlabel' >DATE</lable>
  <input  placeholder='Date'/>
</div>



</div>
{/*  */}

{/*  */}
<div className='searchfrom'>
<i class="bi bi-person"></i>
<div className='fromdiv'>
  <lable className='fromdivlabel' >PERSONS</lable>
  <input placeholder='Select'/>
</div>



</div>
{/*  */}



</div>

 
  <button className='bannerbtn'> <Link to='/newcart' className='cartsearch'>Search</Link></button>
  

  </div>
  </div>
  <br/>
  <br/>
  <br/>

  <div className='TeerthPackages'>
        <h1>Discover   <span>Divine</span> Destinations</h1>
        <p className='TeerthPackagesP'>Browse our carefully curated Teerth Yatra packages, each designed to offer you a profound<br/> spiritual experience. Explore the sacred destinations, itineraries, and inclusions for each package</p>
        <br/>
        <br/>
        <div className='scroll-container'>
      
        <div className='TeerthPackages-cards'>
        <div className='TeerthPackages-card'>
        <div className='TeerthPackages-card-img'>
        <img src={img}/>

        </div>
        <h3>Divine Dwarka Darshan</h3>

        <p>Vorem ipsum dolor sit amet, <br/>
        consectetur adipiscing elit. Nunc<br/>
         vulputate libero et velit interdum, <br/>
         ac aliquet odio mattis.</p>
         <h4>Package 20,000 for 4 people</h4>
         <button>View More</button>

        </div>
        <div className='TeerthPackages-card'>
        <div className='TeerthPackages-card-img'>
        <img src={img2}/>

        </div>
        <h3>Panch Kedar Pilgrimage</h3>

        <p>Vorem ipsum dolor sit amet, <br/>
        consectetur adipiscing elit. Nunc<br/>
         vulputate libero et velit interdum, <br/>
         ac aliquet odio mattis.</p>
         <h4>Package 20,000 for 4 people</h4>
         <button>View More</button>

        </div>
        <div className='TeerthPackages-card'>
        <div className='TeerthPackages-card-img'>
        <img src={img3}/>

        </div>
        <h3>Sacred Himalayan Sojourn</h3>

        <p>Vorem ipsum dolor sit amet, <br/>
        consectetur adipiscing elit. Nunc<br/>
         vulputate libero et velit interdum, <br/>
         ac aliquet odio mattis.</p>
         <h4>Package 20,000 for 4 people</h4>
         <button>View More</button>

        </div>
        <div className='TeerthPackages-card'>
        <div className='TeerthPackages-card-img'>
        <img src={img4}/>

        </div>
        <h3>Jyotirlinga Journey</h3>

        <p>Vorem ipsum dolor sit amet, <br/>
        consectetur adipiscing elit. Nunc<br/>
         vulputate libero et velit interdum, <br/>
         ac aliquet odio mattis.</p>
         <h4>Package 20,000 for 4 people</h4>
         <button>View More</button>

        </div>

        </div>
        </div>


<br/>
        <button className='vi-btn' style={{marginTop:'30px'}}>View all</button>

        </div>
        <br/>
  <br/>
  <br/>

        <div className='TeerthPackages'>
    <h1>Explore <span>Spiritual</span> Diversity</h1>
    <p className='TeerthPackagesP' style={{textAlign:'center'}}>Discover Teerth Yatra packages categorized by different regions of India. Each region has its<br/> unique spiritual significance and offerings</p>
    <br/>
    <br/>
    <div className='scroll-container'>
  
    <div className='TeerthPackages-cards'>
    <div className='TeerthPackages-card'>
    <div className='TeerthPackages-card-img'>
    <img src={img8}/>

    </div>
    <h3>Pilgrimages in the North</h3>

    <p>Journey to sacred realms in North India, where the Himalayas whisper ancient wisdom.</p>
     <h4>Package 20,000 for 4 people</h4>
     <button>View More</button>

    </div>
    <div className='TeerthPackages-card'>
    <div className='TeerthPackages-card-img'>
    <img src={img5}/>

    </div>
    <h3>Southern Spiritual Journeys</h3>

    <p>Embark on soul-stirring Teerth Yatras to the vibrant temples of South India.</p>
     <h4>Package 20,000 for 4 people</h4>
     <button>View More</button>

    </div>
    <div className='TeerthPackages-card'>
    <div className='TeerthPackages-card-img'>
    <img src={img6}/>

    </div>
    <h3>Journey to the West</h3>

    <p>Experience the spiritual splendor of West India through our Teerth Yatra offerings.</p>
     <h4>Package 20,000 for 4 people</h4>
     <button>View More</button>

    </div>
    <div className='TeerthPackages-card'>
    <div className='TeerthPackages-card-img'>
    <img src={img7}/>

    </div>
    <h3>Divine Dwarka Darshan</h3>

    <p>Vorem ipsum dolor sit amet, <br/>
    consectetur adipiscing elit. Nunc<br/>
     vulputate libero et velit interdum, <br/>
     ac aliquet odio mattis.</p>
     <h4>Package 20,000 for 4 people</h4>
     <button>View More</button>

    </div>

    </div>
    </div>




    


<br/>
    <button className='vi-btn' style={{marginTop:'30px'}}>View all</button>

    </div>

<br/>
<br/>

        <Joinus/>
        <Footer/>

   </div>
  )
}

export default TeerthHome