import React, { useContext, useEffect, useState } from "react";
import "./booking-page.css";
import Navbar from "../header/Navbar";
import Footer from "../footer/footer";
import { Link, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import image from "../../assets/image 106.png";
import BookingContext from "../../context/booking/bookingContext";

function Booking() {
  const BookingStates = useContext(BookingContext);
  const { pujaName, cityLocation, selectedDate, selectedTime, templeName, price,} = BookingStates;
  const params = useParams();

  return (
    <>
      <Navbar />
      <section className="diwali-detail-hero">
        <div className="container-fluid">
          <div className="text-center">
            <h1>Choose a Pandit </h1>
          </div>
          <div className="container">
            <div className="diwali-head">
              <p>
                Home / Pujas /<span> Diwali Puja</span>
              </p>
            </div>
            <div className="select-city text-center">
              {/* <select
                name="city"
                value={cityLocation}
                onChange={(e) => setCityLocation(e.target.value)}
              >
                {allCity.map((element, index) => {
                  return (
                    <>
                      <option value={element[index]} key={index}>
                        {element["City Name"]}
                      </option>
                    </>
                  );
                })}
              </select> */}
            </div>
            <div className="booking-page-section">
              <Grid container columnSpacing={3}>
                <Grid item lg={6}>
                  <img
                    src={image}
                    style={{ maxWidth: "100%", height: "auto" }}
                    alt=""
                  />
                </Grid>
                <Grid item lg={6}>
                  <div className="select-pandit-title">
                    <span>{pujaName}</span>
                    <p className="event-price">Rs.{price}</p>
                    <p className="event-date">Online</p>
                    <p className="event-date">Temple Name : {templeName}</p>
                    <p className="event-date">Puja Date : {selectedDate}</p>
                    <p className="event-date">Time : {selectedTime}</p>
                    <div className="booking-total">
                      <div className="d-flex justify-content-between">
                        <p className="booking-total-text">Sub Total</p>
                        <p className="booking-total-text">{price}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="booking-total-text">Total</p>
                        <p className="booking-total-text">{price}</p>
                      </div>
                    </div>
                    <Link to={`/pujas/puja-booking/booking/checkout/${params.id}`}>
                      <button className="checkout-btn">Checkout</button>
                    </Link>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Booking;
