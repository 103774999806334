import React from 'react'

function Banner() {
  return (
  <>
  <div className='banner'>
  
  <h1>Discover Spiritual Journeys</h1>
  <p>Experience the Divine with Bharat360</p>
  

  <div className='filterinputs'>
  <div className='filterinputs-input'>

  {/*  */}
  <div className='searchfrom'>
  <i class="bi bi-geo-alt"></i>
  <div className='fromdiv'>
    <lable className='fromdivlabel' >FROM</lable>
    <input placeholder='city'/>
  </div>



  </div>
  {/*  */}
    {/*  */}
    <div className='searchfrom'>
  <i class="bi bi-geo-alt"></i>
  <div className='fromdiv'>
    <lable className='fromdivlabel' >To</lable>
    <input placeholder='city'/>
  </div>



  </div>
  {/*  */}
    {/*  */}
    <div className='searchfrom'>
    <i class="bi bi-calendar-date"></i>
  <div className='fromdiv'>
    <lable className='fromdivlabel' >DATE</lable>
    <input  placeholder='Date'/>
  </div>



  </div>
  {/*  */}

  {/*  */}
  <div className='searchfrom'>
  <i class="bi bi-person"></i>
  <div className='fromdiv'>
    <lable className='fromdivlabel' >PERSONS</lable>
    <input placeholder='Select'/>
  </div>



  </div>
  {/*  */}



  </div>
  <button className='bannerbtn'>Search</button>
  

  </div>
  </div>

  </>
  )
}

export default Banner