import React, { useState } from "react";
import LoginContext from "./LoginContext";


const LoginState = (props) => {
    const [loggedIn, setLoggedIn] = useState(false);
    const token = localStorage.getItem("token")
    return(
        <LoginContext.Provider value={{loggedIn, setLoggedIn, token}}>
            {props.children}
        </LoginContext.Provider>
    )
}

export default LoginState;