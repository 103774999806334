import React, { useState } from "react";
import CartContext from "./CartContext";


const CartState = (props) => {
    const [cartToast, setCartToast] = useState('');
    return(
        <CartContext.Provider value={{cartToast, setCartToast}}>
            {props.children}
        </CartContext.Provider>
    )
}

export default CartState;