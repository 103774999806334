import React, { useContext, useEffect, useState } from "react";
import "./puja-booking.css";
import ImageGallery from "react-image-gallery";
import { Box, Grid, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useNavigate, useParams } from "react-router-dom";
import PujaBookingDiscription from "./PujaBookingDescription";
import Navbar from "../header/Navbar";
import Footer from "../footer/footer";
import axios from "axios";
import moment from "moment";
import BookingContext from "../../context/booking/bookingContext";

function PujaBookingPage() {
  const BookingStates = useContext(BookingContext);

  const { setPujaName, setCityLocation, setSelectedDate, setPujaId, templeId, setTempleId, Mode, setMode, setSelectedTime, setTempleName, setSelectTempId, setPrice } = BookingStates;
  const params = useParams();
  const navigate = useNavigate()
  const [tabs, setTabs] = useState("1");

  const handleChange = (event, newValue) => {
    setTabs(newValue);
  };
  const lists = [
    { id: 1, title: "Online", mode: "ONLINE" },
    { id: 2, title: "Offline", mode: "OFFLINE" },
  ];
  const [selected, setSelected] = useState(0);
  const [puja, setPuja] = useState(null);
  const [temple, setTemple] = useState([]);
  const [selectDate, setSelectDate] = useState(null);


  useEffect(() => {
    async function fetchData() {
      try {
        const pujas = await axios({
          method: "get",
          url: `https://starfish-app-lyrtb.ondigitalocean.app/api/puja/getpuja/`+ params.bookingId,
          headers: {
            authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        setPujaId(pujas.data.data._id)
        setPuja([pujas.data.data]);
        setPujaName(pujas.data.data.name)
      } catch (e) {}
      try {
        const temples = await axios({
          method: "get",
          url: `https://starfish-app-lyrtb.ondigitalocean.app/api/temple/getalltemple`,
          headers: {
            authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        setTemple(temples.data.data);
        setSelectedDate("");
        setSelectedTime("");
      } catch (e) {}
      try {
        const dates = await axios({
          method: "get",
          url:
            `https://starfish-app-lyrtb.ondigitalocean.app/api/temple/gettemple/` +
            templeId,
          headers: {
            authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        setSelectDate([dates.data.data.availableTimings]);
        setCityLocation(dates.data.data.location);
        setSelectTempId(dates.data.data._id);
        setPrice(dates.data.data.price);
      } catch (e) {}
    }

    fetchData();
  }, [Mode, templeId]);

  const handleColor = (row) => {
    setSelected(row.id);
    setMode(row.mode);
  };

  const handleChangeTemple = (e) => {
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const option = el.getAttribute("id");
    setTempleName(e.target.value);
    setTempleId(option);
  };
  const handleChangeDate = (e) => {
    setSelectedDate(e.target.value);
  };
  const handleChangeTime = (e) => {
    setSelectedTime(e.target.value);
  };
  const images = [
    {
      original: puja && puja.length && (puja[0].image),
      thumbnail: puja && puja.length && (puja[0].image),
    },
    {
      original: puja && puja.length && (puja[0].image),
      thumbnail: puja && puja.length && (puja[0].image),
    },
    {
      original: puja && puja.length && (puja[0].image),
      thumbnail: puja && puja.length && (puja[0].image),
    },
    {
      original: puja && puja.length && (puja[0].image),
      thumbnail: puja && puja.length && (puja[0].image),
    },
    {
      original: puja && puja.length && (puja[0].image),
      thumbnail: puja && puja.length && (puja[0].image),
    },
  ];

  function BookNow() {
    if(Mode === 'ONLINE'){
      navigate(`/pujas/puja-booking/booking/${params.bookingId}`);
    } else {
      navigate(`/pujas/booking-pandit/${params.bookingId}`);
    }
  }
  return (
    <>
      <Navbar />
      <section className="diwali-detail-hero">
        <div className="container-fluid">
          <div className="text-center">
            <h1>Diwali Puja </h1>
          </div>
          <div className="container">
            <div className="diwali-head">
              <p>
                Home / Pandits /<span> Diwali Puja</span>
              </p>
            </div>
            <div className="select-city text-center">
              {/* <select
                name="city"
                value={cityLocation}
                onChange={(e) => setCityLocation(e.target.value)}
              >
                {allCity.map((element, index) => {
                  return (
                    <>
                      <option value={element[index]} key={index}>
                        {element["City Name"]}
                      </option>
                    </>
                  );
                })}
              </select> */}
            </div>
          </div>
          <div className="prashad-image-gallery">
            <div className="container">
              <Grid
                container
                columnSpacing={4}
                rowSpacing={4}
                justifyContent={"center"}
              >
                <Grid item md={6} lg={6}>
                  <ImageGallery items={images} />
                </Grid>
                <Grid item md={6} lg={6}>
                  <div className="diwali-puja-title">
                    {puja && puja.length && (<span>{puja[0].name}</span>)}
                    {puja && puja.length && (<p className="event-price">Rs. {puja[0].cost}</p>)}
                    <div className="status-btn">
                      {lists.map((element, index) => {
                        return (
                          <button
                            className="online-btn"
                            key={index}
                            onClick={() => handleColor(element)}
                            style={{
                              backgroundColor:
                                element.id === selected ? "#ff6000" : "",
                              color: element.id === selected ? "#fffff" : "",
                            }}
                          >
                            {element.title}
                          </button>
                        );
                      })}
                    </div>
                    <div className="d-flex flex-column diwali-label">
                      <label htmlFor="">select Temple</label>
                      <select
                        className="select-option"
                        onChange={(e) => handleChangeTemple(e)}
                      >
                        {temple.map((element, index) => {
                          return (
                            <>
                              <option
                                key={index}
                                id={element._id}
                                value={element.name}
                              >
                                {element.name}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    </div>
                    <div className="d-flex flex-column diwali-label">
                      {selectDate && selectDate.length && (
                        <>
                          <label htmlFor="">select Date</label>
                          <select
                            className="select-option"
                            onChange={(e) => handleChangeDate(e)}
                          >
                            <option value=""></option>
                            {selectDate.map((element, index) => {
                              return element.map((date, index2) => {
                                return (
                                  <>
                                    <option
                                      key={index2}
                                      value={moment(date.date).format(
                                        "DD - MM - YYYY"
                                      )}
                                    >
                                      {moment(date.date).format(
                                        "DD - MM - YYYY"
                                      )}
                                    </option>
                                  </>
                                );
                              });
                            })}
                          </select>
                        </>
                      )}
                    </div>
                    <div className="d-flex flex-column diwali-label">
                      {selectDate && selectDate.length && (
                        <>
                          <label htmlFor="">select Time</label>
                          <select
                            className="select-option"
                            onChange={(e) => handleChangeTime(e)}
                          >
                            <option value=""></option>
                            {selectDate.map((element, index) => {
                              return element.map((time, index2) => {
                                return (
                                  <>
                                    <option key={index2} value={time.time}>
                                      {time.time}
                                    </option>
                                  </>
                                );
                              });
                            })}
                          </select>
                        </>
                      )}
                    </div>
                  </div>
                    <button className="bookNow-btn" onClick={() => BookNow()}>
                      Book Now
                    </button>
                </Grid>
              </Grid>
              <div className="discription">
                <Box sx={{ width: "100%", typography: "body1" }}>
                  <TabContext value={tabs}>
                    <Box>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                      >
                        <Tab
                          className="tabs-btn"
                          label="Discription"
                          value="1"
                        />
                        <Tab
                          className="tabs-btn"
                          label="Specification"
                          value="2"
                        />
                        <Tab className="tabs-btn" label="Reviews" value="3" />
                        <Tab className="tabs-btn" label="Feedback" value="4" />
                        <Tab className="tabs-btn" label="Q&A" value="5" />
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <PujaBookingDiscription />
                    </TabPanel>
                    <TabPanel value="2">Item Two</TabPanel>
                    <TabPanel value="3">Item Three</TabPanel>
                    <TabPanel value="4">Item Three</TabPanel>
                    <TabPanel value="5">Item Three</TabPanel>
                  </TabContext>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default PujaBookingPage;
