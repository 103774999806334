import React, { useEffect } from 'react'
import LoginPage from '../components/loginpage/loginpage'
import Navbar from '../components/header/Navbar'
import Footer from '../components/footer/footer'

function Login() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>

    <LoginPage />

    </>
  )
}

export default Login