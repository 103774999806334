import React from 'react'
import Navbar from '../header/Navbar'
import img from '../Assets/profile-picture.png'
import Joinus from '../BannerSection/JionUs'
import Footer from '../footer/footer'
import './yatra.css'
import img1 from '../Assets/Rectangle 417.png'
import { Link } from 'react-router-dom'


function NewProfileTwo() {
  return (
   <>
    <div className='Npro'>


<Navbar/>


<div className='NPHeading'>

</div>
<div className='Profile'>
<div className='profile-list'>
<div className='profile-list-top'>
<ul>
<li class="active"><Link className='prolink' to='/profiles'>PROFILE</Link></li>
        <li><Link className='prolink' to='/profilestwo'>ORDERS</Link></li>
    <li>SAVE ADDRESS</li>
    <li>FAQ AND HELP</li>
    <li>LOGOUT</li>

</ul>



</div>


</div>


<div className='profile-mainprofile'>

<div class="yatra-2">
    <div >
    <ul className='ols'>
        <p class="all">All</p>
        <p class="another">upcoming</p>
        <p class="another">completed deliverd</p>
        <p class="another">cancelled</p>
        <p class="another">refund</p>

    </ul>
    <div class="yatra-4">
        
        <div class="image-section">
        <img className='yrimg' src={img1}/>

        </div>
        <div class="text-section">
            <h1>HIMALAYAN BLISS YATRA</h1>
            <p>&#8377; 30000</p>
            <p className='upst'>STATUS UPCOMING</p>
            <p class="group">Group Package</p>
            <p>Rishikesh|Rishikesh|Rishikesh|Rishikesh </p> 
            <p class="group">Oct 3 2023</p>
            <p>From New Delhi</p>
            <p>1 Adults</p>
            <a className='seeinvoice'>See Invoice</a>
        </div>
    </div>


    </div>
 

</div>





</div>

</div>
<br/>
<br/>
<br/>


<Joinus />
<Footer/>
</div>

   </>
  )
}

export default NewProfileTwo