import React, { useEffect } from 'react'
import Navbar from '../components/header/Navbar'
import Footer from '../components/footer/footer'
import PujaBooking from '../components/pujabooking/PujaBooking'

function Puja() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
    <Navbar />
    <PujaBooking />
    <Footer />
    </>
  )
}

export default Puja