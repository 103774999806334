import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import img from '../Assets/Rectangle 401.png';
import logo from '../Assets/bharat 360 primary logo prototype A 2.png';
import google from '../Assets/image 157.png';
import fb from '../Assets/image 156.png';


function LoginPage() {
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  // const [error, setError] = useState(false)
  // const [submitted, setSubmitted] = useState(false)
  // const LoginStatus = useContext(LoginContext)
  // const [alert, setAlert] = useState('');

  // const navigate = useNavigate();
  // const loginData = {"email": email, "password": password}
  // function handleOnSubmit(e) {
  //   e.preventDefault()
  //   if(email.length === 0 || password.length === 0 ){
  //     setError(true)
  //     setSubmitted(true)
  //   }
  //     LoginUser.postUser(loginData, (response) =>{
  //       setAlert(response.message)
  //      /*  if(response.message === "User not found"){
  //         navigate("/sign-in")
  //       } */
  //       const token = response.data.token;
  //       localStorage.setItem("token", token);
  //       setAlert("Login Successful");
  //       setEmail("")
  //       setPassword("")
  //       LoginStatus.setLoggedIn(true)
  //       navigate("/profile")
  //     })
  // }
  const navigate = useNavigate(); // Get the navigate function

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    // Validate form inputs
    if (!email || !password) {
      setError(true);
      return;
    }

    // Assuming you have a login API endpoint
    try {
      const response = await fetch('https://bharat-360-app-vzet3.ondigitalocean.app/api/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      });

      if (response.ok) {
        // Login successful
        const data = await response.json();
        // Store user token or data if needed
        // Redirect to the dashboard or wherever you want using navigate
        navigate('/'); // Use navigate here
      } else {
        // Login failed
        setError(true);
      }
    } catch (error) {
      console.error('Login error:', error);
      setError(true);
    }
  };
  
  return (
    <>
      {/* <section className="login"> */}
      {/* <Snackbar
        open={alert === '' ? false : true}
        autoHideDuration={6000}
        onClose={() => {
            setAlert('');
        }}
        severity="error"
        message={alert}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
      /> */}
        {/* <div className="container">
          <Grid container alignItems={"center"} rowSpacing={4}>
            <Grid item md={6} xs={12} lg={6}>
              <div className="login-img">
                <img src={loginImg} alt="" />
              </div>
            </Grid>
            <Grid item md={6} xs={12} lg={6}>
              <div className="login-container">
                <div className="text-left">
                  <h2 className="login-title">Login Now</h2>
                  <hr />
                  <form onSubmit={handleOnSubmit}>
                    <div className="login-input-container">
                      <div className="d-flex flex-column">
                        <label htmlFor="" className="input-title">
                          Email
                        </label>
                        {error && email.length <= 0 ? <p
                          className="error-text"
                          // style={{
                          //   display: phoneNo.length > 0 === error ? "" : "none",
                          // }}
                        >
                          *Please enter the Email.
                        </p> : ""}
                        <input
                        value={email}
                        onChange={(e) => {setEmail(e.target.value); setSubmitted(false)}}
                          className="input-box"
                          type="email"
                          name="email"
                          placeholder="email"
                        />
                      </div>
                      <div className="d-flex flex-column">
                        <label htmlFor="" className="input-title">
                          Password
                        </label>
                        {error && password.length <= 0 ?
                        <p
                        className="error-text"
                        // style={{
                        //   display: phoneNo.length > 0 === error ? "" : "none",
                        // }}
                      >
                        *Please enter the Password.
                      </p> : ""}
                        <input className="input-box" name="password" placeholder="Enter The Password" autoComplete="current-password" value={password} onChange={(e) =>  {setPassword(e.target.value); setSubmitted(false)}} type="password" />
                      </div>
                    </div>
                    <button className="submit-btn" onClick={(e) => handleOnSubmit(e)}>Submit</button>
                    <p>
                      Doesn't have an account? <Link to="/sign-in">Register Now</Link>
                    </p>
                  </form>
                </div>
              </div>
            </Grid>
          </Grid>
        </div> */}


       
      {/* </section> */}
      <div className="NewLoginPage">
      <div className="NewLoginPage-left">
        <img src={img} alt="Profile" />
      </div>
      <div className="NewLoginPage-right">
        <img src={logo} alt="Logo" />
        <h1>SIGN IN</h1>
        <p>Join Us in the spiritual journey</p>
        <label>E-mail or phone number</label>
        {error && email.length <= 0 && submitted ? (
          <p className="error-text">*Please enter the Email.</p>
        ) : null}
        <input
          className="logInput"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setError(false);
          }}
          type="email"
          name="email"
          placeholder="Enter your Email"
        />
        <label>Password</label>
        {error && password.length <= 0 && submitted ? (
          <p className="error-text">*Please enter the Password.</p>
        ) : null}
        <input
          className="logInput"
          name="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            setError(false);
          }}
          type="password"
          placeholder="Enter your Password"
        />
        <div className="checks">
          <input type="checkbox" />
          <p>I agree to all terms and conditions</p>
        </div>
        <button onClick={(e) => handleOnSubmit(e)}>Login</button>
        <h3 className="Forgot-Password">Forgot Password?</h3>
        <div className="socialbtnss">
          <button className="authbtn">
            <img src={google} alt="Google" /> Sign up with Google
          </button>
          <button className="authbtn">
            <img src={fb} alt="Facebook" /> Sign up with Facebook
          </button>
        </div>
        <h4 className="dnthvacc">
          Don’t have an account? <span><Link to="/sign-in">Signup</Link></span>
        </h4>
      </div>
    </div>
    </>
  );
}

export default LoginPage;
