import React from "react";
import "./satsang.css";
import SatsangCard from "./SatsangCard";
import { Grid } from "@mui/material";

function SatsangHero() {
  return (
    <>
      <section className="satsang-hero">
        <div className="container-fluid">
          <div className="text-center">
            <h1>Satsang & Gurus</h1>
          </div>
        </div>
        <div className="container">
          <p>
            Home / <span>Satsang & Gurus</span>
          </p>
          <div className="satsang-title">
            <span>Satsang & Gurus</span>
          </div>
          <div className="all-satsang">
            <Grid container columnSpacing={4}>
              <SatsangCard />
              <SatsangCard />
              <SatsangCard />
              <SatsangCard />
              <SatsangCard />
              <SatsangCard />
              <SatsangCard />
              <SatsangCard />
            </Grid>
            <div className="text-center">
                <button className="satsang-load-more-btn">Load More</button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SatsangHero;
