import React, { useEffect } from "react";
import "./prashad.css";
import { Grid } from "@mui/material";
import RequestPrashad from "./requestPrashad/RequestPrashad";
import ganeshImg from "../../assets/prashad-img/image 53.png";
import { PrashadApi, } from "../../api/ProjectsAPI";
import { useState } from "react";
import TeerthPackages from "../BannerSection/TeerthPackages";
import img from '../Assets/Laddus (1).png'
import img2 from '../Assets/Laddus (2).png'
import img3 from '../Assets/Laddus (3).png'
import img4 from '../Assets/Laddus (4).png'
import JionUs from "../BannerSection/JionUs";

import img8 from '../Assets/SPRITIDIVERSITY (1).png'
import img5 from '../Assets/SPRITIDIVERSITY (2).png'
import img6 from '../Assets/SPRITIDIVERSITY (3).png'
import img7 from '../Assets/SPRITIDIVERSITY (4).png'


function HeroSection() {
  const [prashadData, setPrashadData] = useState([])
  useEffect(() => {  
    PrashadApi.get({}, (response) => {
      setPrashadData(response.data)
    })
  }, [])

  const allPrashad = prashadData.map((element, index) => {
  return(
    <>
    <RequestPrashad
    key={index}
              img={element.displayImage}
              cost={element.cost}
              location={element.location[0].address}
              title={element.templeName}
              id={element._id}
            />
    </>
  )
})
  return (
    // <>
    //   <div className="container">
    //     <section className="hero-section">
    //       <div className="text-center">
    //         <h1>Prashad Delivery</h1>
    //         <p>
    //           Prasad services are currently live from these select temples. We
    //           expect all temples to re-start offerings very soon.
    //         </p>
    //       </div>
    //       <div className="text-left">
    //         <h6>
    //           Home / <span>Online Prashad</span>
    //         </h6>
    //       </div>
    //     </section>
    //     <section className="all-prashad">
    //       <div className="all-prashad-title">
    //         <span>All Prashad Available</span>
    //       </div>
    //       <Grid container rowSpacing={{ xs: 4, sm: 6, md: 8, lg: 7 }}>
    //         {allPrashad}
    //         {/* <RequestPrashad
    //           img={img1}
    //           title={"Siddhi Vinayak Temple Prashad (Mumbai)"}
    //         />
    //         <RequestPrashad
    //           img={img2}
    //           title={"Mahalaxmi Temple Prashad (Mumbai)"}
    //         />
    //         <RequestPrashad img={img3} title={"Somnath Temple Prashad"} />
    //         <RequestPrashad
    //           img={img4}
    //           title={"Triumala Tirupati Devasthanam Prashad"}
    //         />
    //         <RequestPrashad img={img5} title={"Banke Bihari Prashad"} />
    //         <RequestPrashad img={img6} title={"Mahakaleshwar Prashad"} />
    //         <RequestPrashad img={img7} title={"Omkareshwar Prashad"} />
    //         <RequestPrashad img={img8} title={"Dwarkadish prashad"} />
    //         <RequestPrashad img={img9} title={"Salasar Balaji"} /> */}
    //       </Grid>
    //       <div className="load-more-btn text-center">
    //         <button>Load More</button>
    //       </div>
    //     </section>
    //     <section className="importance-section">
    //       <div className="importance-title">
    //         <span>What is the Importance of Prasad</span>
    //       </div>
    //       <Grid container rowSpacing={4} justifyContent={"space-between"}>
    //         <Grid item sm={12} md={12} lg={6}>
    //           <div className="importance-box">
    //             <p className="importance-text">
    //               'Prasad' literally means a gracious gift from The GRACOUS ONE.
    //               It could be anything, but mostly we and people in general
    //               think it is edible – sweets, but it is not so. What we
    //               prepared at home for daily consumption lunch; dinner or for
    //               break-fast or any special preparations we prepare for holiday,
    //               in normal families it is first offered to a deity of the HOME
    //               may be a saint, Perfect Master or an avatar or an idol at home
    //               where they worship, and then distributed to family members or
    //               guest in His or Her name to their followers or others as a
    //               good sign. Further in Hinduism, each day of a week is
    //               dedicated to a particular god in the SANATAN pantheon various
    //               type of foods as Prasad; the Vrats (fasting of particular
    //               type) are kept. Each day in a week has a specialty and food
    //               items there are numerous folklores associated with that
    //               particular type of fasting observed on the day.
    //             </p>
    //           </div>
    //         </Grid>
    //         <Grid item sm={12} md={12} lg={6}>
    //           <div className="importance-img">
    //             <img src={ganeshImg} alt="ganesh" />
    //           </div>
    //         </Grid>
    //       </Grid>
    //     </section>
    //   </div>
    // </>
    <>
      <div className="New-prshad-homeSection">
      <div className='banner prshadbanner'>
  
  <h1>Experience Divine Blessings at Your Doorstep</h1>
  <p>Welcome to our Prashad Delivery service, where you can receive sacred Prashad from renowned <br/>temples and shrines delivered right to your doorstep. Immerse yourself in the divine flavors and <br/>blessings of these sacred offerings.</p>
  
  <div className='filterinputs'>
  <div className='filterinputs-input find-satnag' >
  <i class="bi bi-search"></i>
  <input placeholder='Search for a place'/>

  </div>
  <button className='bannerbtn'>Search</button>
  

  </div>
  {/* <div className='filterinputs'>
  <div className='filterinputs-input find-satnag' >
  <i class="bi bi-search"></i>
  <input placeholder='Search for a place'/>

  </div>
  <button className='bannerbtn'>Search</button>
  

  </div> */}
  </div>

  <br/>
  <br/>

  <>
   
  <div className='TeerthPackages'>
        <h1>Sacred <span>Prashad</span> Collection</h1>
        <p className='TeerthPackagesP'>Discover a wide range of Prashad offerings from famous temples and spiritual destinations across India. Each Prashad is prepared with utmost devotion and purity.</p>
        <br/>
        <br/>
        <div className='scroll-container'>
      
        <div className='TeerthPackages-cards'>
        <div className='TeerthPackages-card'>
        <div className='TeerthPackages-card-img'>
        <img src={img}/>

        </div>
        <h3>Maha Prashad from Puri Jagannath Temple</h3>

        <p>Vorem ipsum dolor sit amet, <br/>
        consectetur adipiscing elit. Nunc<br/>
         vulputate libero et velit interdum, <br/>
         ac aliquet odio mattis.</p>
         <h4>₹499 per package</h4>
         <button>View More</button>

        </div>
        <div className='TeerthPackages-card'>
        <div className='TeerthPackages-card-img'>
        <img src={img2}/>

        </div>
        <h3>Tirupati Laddu Prashad</h3>

        <p>Vorem ipsum dolor sit amet, <br/>
        consectetur adipiscing elit. Nunc<br/>
         vulputate libero et velit interdum, <br/>
         ac aliquet odio mattis.</p>
         <h4>₹299 per package</h4>
         <button>View More</button>

        </div>
        <div className='TeerthPackages-card'>
        <div className='TeerthPackages-card-img'>
        <img src={img3}/>

        </div>
        <h3>Sacred Himalayan Sojourn</h3>

        <p>Vorem ipsum dolor sit amet, <br/>
        consectetur adipiscing elit. Nunc<br/>
         vulputate libero et velit interdum, <br/>
         ac aliquet odio mattis.</p>
         <h4>Package 20,000 for 4 people</h4>
         <button>View More</button>

        </div>
        <div className='TeerthPackages-card'>
        <div className='TeerthPackages-card-img'>
        <img src={img4}/>

        </div>
        <h3>Jyotirlinga Journey</h3>

        <p>Vorem ipsum dolor sit amet, <br/>
        consectetur adipiscing elit. Nunc<br/>
         vulputate libero et velit interdum, <br/>
         ac aliquet odio mattis.</p>
         <h4>Package 20,000 for 4 people</h4>
         <button>View More</button>

        </div>

        </div>
        </div>


<br/>
        <button className='vi-btn' style={{marginTop:'30px'}}>View all</button>

        </div>

</>
  <br/>
  <br/>
  <br/>
  <br/>
  <>
   
   <div className='TeerthPackages'>
    <h1>Regional Prashad: <span> Taste the Flavors</span> of India</h1>
    <p className='TeerthPackagesP' style={{textAlign:'center'}}>Dive into the diverse culinary and spiritual traditions of India with our Regional Prashad selection. Each region brings its own unique taste and cultural<br/> significance to these sacred Prashad offerings.</p>
    <br/>
    <br/>
    <div className='scroll-container'>
  
    <div className='TeerthPackages-cards'>
    <div className='TeerthPackages-card'>
    <div className='TeerthPackages-card-img'>
    <img src={img}/>

    </div>
    <h3>Divine Delights from the North</h3>

    <p>Explore the sweetness of devotion with North Indian Prashad. From the warm Aloo Ka Halwa to the comforting Pinni, every bite is a divine offering to the gods.</p>
     <h4>₹499 per package</h4>
     <button>Order now</button>

    </div>
    <div className='TeerthPackages-card'>
    <div className='TeerthPackages-card-img'>
    <img src={img2}/>

    </div>
    <h3>South Indian Culinary Bliss</h3>

    <p>Relish the flavors of devotion with South Indian Prashad. From the aromatic Sakkarai Pongal to the delightful Coconut Ladoo, each bite is a sacred taste of tradition.</p>
     <h4>₹299 per package</h4>
     <button>Order now</button>

    </div>
    <div className='TeerthPackages-card'>
    <div className='TeerthPackages-card-img'>
    <img src={img3}/>

    </div>
    <h3>Flavors of the East</h3>

    <p>Indulge in the sweetness of spirituality with East Indian Prashad. From the heavenly Sandesh to the syrupy Rasgulla, these sweets are offerings to the gods in every bite.</p>
     <h4>₹199 per package</h4>
     <button>Order now</button>

    </div>
    <div className='TeerthPackages-card'>
    <div className='TeerthPackages-card-img'>
    <img src={img4}/>

    </div>
    <h3>Savory Offerings from the West</h3>

    <p>Spice up your spiritual journey with West Indian Prashad. From the crispy Chakli to the steamed Dhokla, these savory delights are offerings to deities who savor flavorful devotion.</p>
     <h4>₹149 per package</h4>
     <button>Order now</button>

    </div>

    </div>
    </div>




    


<br/>
    <button className='vi-btn' style={{marginTop:'30px'}}>View all</button>

    </div>

</>
  <br/>
  <br/>
<JionUs/>





      </div>
    </>
  );
}

export default HeroSection;
