import React from 'react'
import Navbar from '../header/Navbar'
import Footer from './footer'

function SippingandReturnpolicy() {
  return (
<div className='full'>
<Navbar/>
<div className='ftdt'>Sipping and Return policy</div>
<Footer/>
</div>

)
}

export default SippingandReturnpolicy