import React from 'react'
import img from '../Assets/Rectangle 2411.png'
import img2 from '../Assets/sprit (1).png'
import img3 from '../Assets/sprit (2).png'
import img4 from '../Assets/sprit (3).png'


function Tapestry() {
  return (
    <>
        <div className='Tapestry'>
        <h1>EXPLORE THE <span>SPIRITUAL TAPESTRY</span> OF INDIA</h1>
        <p>Discover diverse Teerth Yatra categories, each offering a unique spiritual journey. From the heights of Kailash Mansarovar to the <br/>
        shores of Rameswaram, find the pilgrimage that resonates with your soul. Start your spiritual journey today.</p>
        <br/>
        <br/>
        <div class="scroll-container">
       
       
        <div className='Tapestry-cards'>
        <div className='Tapestry-card'>
        <div className='Tapestry-card-img'>
        <img src={img}/>
        </div>
        <h3>Char Dham Yatra</h3>


        

        </div>
        <div className='Tapestry-card'>
        <div className='Tapestry-card-img'>
        <img src={img2}/>
        </div>
        <h3>Jyotirlinga Yatra</h3>


        

        </div>
        <div className='Tapestry-card'>
        <div className='Tapestry-card-img'>
        <img src={img3}/>
        </div>
        <h3>Shakti Peeth Yatra</h3>


        

        </div>
        <div className='Tapestry-card'>
        <div className='Tapestry-card-img'>
        <img src={img4}/>
        </div>
        <h3>Kailash Mansarovar Yatra</h3>


        

        </div>

        </div>
        </div>
        <button className='vi-btn'>View all</button>

        </div>
    </>
  )
}

export default Tapestry