import React from 'react'
import img from '../Assets/Rectangle 2422.png'
import img2 from '../Assets/TEERTHPAK (1).png'
import img3 from '../Assets/TEERTHPAK (2).png'
import img4 from '../Assets/TEERTHPAK (3).png'


function TeerthPackages() {
  return (
    <>
   
       <div className='TeerthPackages'>
        <h1>EXPLORE <span>OUR TEERTH</span> PACKAGES</h1>
        <p className='TeerthPackagesP'>Embark on a divine journey with our Teerth Yatra packages. Discover spiritual adventures <br></br>
        designed to enrich your soul and connect you with the divine. Explore Now.</p>
        <br/>
        <br/>
        <div className='scroll-container'>
      
        <div className='TeerthPackages-cards'>
        <div className='TeerthPackages-card'>
        <div className='TeerthPackages-card-img'>
        <img src={img}/>

        </div>
        <h3>Divine Dwarka Darshan</h3>

        <p>Vorem ipsum dolor sit amet, <br/>
        consectetur adipiscing elit. Nunc<br/>
         vulputate libero et velit interdum, <br/>
         ac aliquet odio mattis.</p>
         <h4>Package 20,000 for 4 people</h4>
         <button>View More</button>

        </div>
        <div className='TeerthPackages-card'>
        <div className='TeerthPackages-card-img'>
        <img src={img2}/>

        </div>
        <h3>Panch Kedar Pilgrimage</h3>

        <p>Vorem ipsum dolor sit amet, <br/>
        consectetur adipiscing elit. Nunc<br/>
         vulputate libero et velit interdum, <br/>
         ac aliquet odio mattis.</p>
         <h4>Package 20,000 for 4 people</h4>
         <button>View More</button>

        </div>
        <div className='TeerthPackages-card'>
        <div className='TeerthPackages-card-img'>
        <img src={img3}/>

        </div>
        <h3>Sacred Himalayan Sojourn</h3>

        <p>Vorem ipsum dolor sit amet, <br/>
        consectetur adipiscing elit. Nunc<br/>
         vulputate libero et velit interdum, <br/>
         ac aliquet odio mattis.</p>
         <h4>Package 20,000 for 4 people</h4>
         <button>View More</button>

        </div>
        <div className='TeerthPackages-card'>
        <div className='TeerthPackages-card-img'>
        <img src={img4}/>

        </div>
        <h3>Jyotirlinga Journey</h3>

        <p>Vorem ipsum dolor sit amet, <br/>
        consectetur adipiscing elit. Nunc<br/>
         vulputate libero et velit interdum, <br/>
         ac aliquet odio mattis.</p>
         <h4>Package 20,000 for 4 people</h4>
         <button>View More</button>

        </div>

        </div>
        </div>


<br/>
        <button className='vi-btn' style={{marginTop:'30px'}}>View all</button>

        </div>
 
    </>
  )
}

export default TeerthPackages