import React from 'react'
import Navbar from '../header/Navbar'
import Footer from '../footer/footer'
import img1 from "../Assets/Rectangle 241.png";
import JionUs from '../BannerSection/JionUs';
import '../../../src/index.css'
import coin from '../Assets/coin 1.png'
import coins from '../Assets/coin 1 (1).png'
import sikka1 from '../Assets/sikkaas (1).png'
import sikka2 from '../Assets/sikkaas (2).png'
import sikka3 from '../Assets/sikkaas (3).png'
import sikka4 from '../Assets/sikkaas (4).png'
import sikka5 from '../Assets/sikkaas (5).png'


function BharatSikka() {
  return (
   <>
    <div className='full'>
    <Navbar/>
    <div className='banner bharatsikkaBanner' >
  
  <h1>Bharat Sikka - Your Spiritual Wallet</h1>
  <p>Welcome to Bharat Sikka, your spiritual wallet. With every transaction, unlock the blessings <br/>of spirituality. Earn coins and embark on a divine journey like never before.</p>
  

  <div className='filterinputs ftpd'>
  <div className='TotalCoin'>
  <div className='coin-img'>  
  <img src={coins}/>

  </div>
  <div className='coin-total'>  
  <h1>Total Bharat Sikka Earned</h1>
  <p>View all your rewards</p>

</div>
 <div className='coin-totalss'>  
 <h1>5000 coins</h1>


</div>

  </div>
  

  </div>
  </div>
<br/>
<br/>

  <h1 className='coins'> <img src={coin}/> 100 Bharat Sikka coin = ₹50</h1>
  <br/>
<br/>

<div className="EasyScheduling">
        <div className="EasyScheduling-center">
        <div className="EasyScheduling-center-img">
        <div className="EasyScheduling-center-img-overlay">
        <h2>
        How Bharat Sikka Works
        </h2>
        <p>Discover the seamless process of earning and utilizing Bharat Sikka coins to enhance your spiritual journey.</p>
        <button>Book Now</button>

        </div>

        </div>
        <div className="EasyScheduling-center-detail">
        <div className="EasyScheduling-center-detail-top">
        <div className="EasyScheduling-center-detail-top-box">

        <h3>01</h3>
        <h4>Earn Coins</h4>
        <p>Every rupee spent on Bharat360 services earns you Bharat Sikka coins. The more you spend, the more coins you collect</p>



</div>
    <div className="EasyScheduling-center-detail-top-box">

<h3>02</h3>
<h4>Accumulate Blessings</h4>
<p>Watch your coin balance grow as you explore spiritual services. Each coin represents a step on your divine journey.</p>



</div>


        </div>
        <div className="EasyScheduling-center-detail-top">
        <div className="EasyScheduling-center-detail-top-box">

        <h3>03</h3>
        <h4>Redeem for Discounts</h4>
        <p>When you book services, redeem your coins for discounts. Save as you continue to embrace spirituality.</p>



</div>
    <div className="EasyScheduling-center-detail-top-box">

<h3>04</h3>
<h4>Enhance Your Journey</h4>
<p>Your accumulated coins not only save you money but also enrich your spiritual path. Check your balance anytime.</p>



</div>


        </div>
       


        </div>
        

</div>


        </div>

<br/>
<br/>
<br/>

        <div className="offerings">
        <h1>Ready to Enjoy Spiritual Discounts</h1>
        <p>Your accumulated Bharat Sikka coins are your key to unlocking savings on your next spiritual experience. Redeem them now by<br/> choosing from our wide range of spiritual services</p>
        <br/>
        <br/>
        <div class="scroll-container">
        <div className="offring-cards">
          <div className="offring-card">
            <div className="offring-card-img">
              <img src={img1} />
            </div>

            <h3>Teerth Yatras</h3>
          </div>
          <div className="offring-card">
            <div className="offring-card-img">
              <img src={sikka1} />
            </div>

            <h3>Pandit Services</h3>
          </div>
          <div className="offring-card">
            <div className="offring-card-img">
              <img src={sikka2} />
            </div>

            <h3>Prashad Delivery</h3>
          </div>
          <div className="offring-card">
            <div className="offring-card-img">
              <img src={sikka3} />
            </div>

            <h3>Satsang and Spiritual Gatherings</h3>
          </div>
          <div className="offring-card">
            <div className="offring-card-img">
              <img src={sikka4} />
            </div>

            <h3>Live Darshan</h3>
          </div>
          <div className="offring-card">
            <div className="offring-card-img">
              <img src={sikka5} />
            </div>

            <h3>Bharat Sikka Rewards</h3>
          </div>
        </div>
        </div>
      </div>
      <br/>
<br/>
<JionUs/>




    <Footer/>

    </div>
   </>
  )
}

export default BharatSikka