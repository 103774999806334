import React from 'react'
import Navbar from '../header/Navbar'
import Footer from './footer'

function PrivacyPolicy() {
  return (
  <div className='full'>
      <Navbar/>
    <div className='ftdt'>Privacy Policy</div>
    <Footer/>
  </div>
  )
}

export default PrivacyPolicy