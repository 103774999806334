import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../header/Navbar'
import Footer from '../footer/footer'
import img from '../Assets/Rectangle 2422.png'
import img1 from '../Assets/Rectangle 2224.png'

import Joinus from '../BannerSection/JionUs'

function PrashadList() {
  const [temples, setTemples] = useState([]);

  useEffect(() => {
    // Fetch data from the API when the component mounts
    fetch('https://bharat-360-app-vzet3.ondigitalocean.app/api/prasad/getallprasad')
      .then((response) => response.json())
      .then((data) => {
        // Update the state with the fetched data
        setTemples(data.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);
  return (
   <>
    <div className='PrashadList full'>
    <Navbar/>
    <div className='TeerthList'>
    <div className='TeerthList-heading prshadlistBanner'>


    

    </div>
    <br/>
    <br/>
    <h1 style={{textAlign:'center' , fontSize:'32px'}}>Explore <snap>Spiritual</snap> Diversity</h1>
    <br/>
  
  <button className='TeerthPackages-drop-btn'>Sort by : Relevance</button>
  <br/>

    
      
  <div className='TeerthPackages' id='TeerthPackagess'>
  <div className='scroll-container'>
    <div className='TeerthPackages-cards'>
    <div className='temple-list'>
            {temples.map((temple) => (
              <div className='TeerthPackages-card' key={temple._id}>
                <div className='TeerthPackages-card-img'>
                  <img src={temple.displayImage} alt={temple.templeName} />
                </div>
                <h3>{temple.templeName}</h3>
                <p>{temple.description}</p>
                <h4>Package {temple.cost} for 4 people</h4>
                <Link to={`/prashadlistdetails/${temple._id}`}>
                  <button>View More</button>
                </Link>
              </div>
            ))}
          </div>
    </div>
  </div>

  <br />
  <br />
  <br />

  {/* <button className='vi-btn' style={{ marginTop: '30px' }}>View all</button> */}
</div>

<br />
<br />
<br />

<Joinus />
<Footer />

    </div>

    

    </div>
   </>
  )
}

export default PrashadList