import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import router from "./config/router";
import { RouterProvider } from "react-router-dom";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import "../node_modules/slick-carousel/slick/slick.css";
import "../node_modules/slick-carousel/slick/slick-theme.css";
import "../node_modules/react-image-gallery/styles/css/image-gallery.css";
import { Provider } from "react-redux";
import configureStore from "./store/configureStore";
import "aos/dist/aos.css";
import LoginState from "./context/login/LoginState";
import BookingState from "./context/booking/bookingState";
import PrashadBookingState from "./context/prashadBooking/PrashadBookingState";
import CartState from "./context/cartContaxt/CartState";

const store = configureStore();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Provider store={store}>
      <LoginState>
        <BookingState>
          <PrashadBookingState>
            <CartState>
              <RouterProvider router={router} />
            </CartState>
          </PrashadBookingState>
        </BookingState>
      </LoginState>
    </Provider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
