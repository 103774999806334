import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import RegisterModal from "../Modals/RegisterModal";
// import LoginModal from "../Modals/LoginModal";
import logo from "../Assets/bharat 360 primary logo prototype A 2.png";
import like from "../Assets/solar_heart-outline.png";
import cart from "../Assets/Shopping--cart.png";
import profile from "../Assets/Vector.png";

import search from "../Assets/search.svg";
import Topnav from "./Topnav";
import './nav.css'
import {Modal , ModalBody, ModalHeader} from "reactstrap";
// import img from '../Assets/Rectangle 421.png'


import img from '../Assets/Rectangle 401.png';
// import logo from '../Assets/bharat 360 primary logo prototype A 2.png';
import google from '../Assets/image 157.png';
import fb from '../Assets/image 156.png';

const Navbar = () => {
  const [modal ,setModal] = useState(false)

  const [signmodal ,setSignmodal] = useState(false)

  let location = useLocation();
  const [path, setPath] = useState("/");
  // const navigate = useNavigate();



  const handleSignUp = () => {
    // Your sign-up logic goes here

    // Close the sign-up modal and reset the form
    setSignmodal(false);
    setEmail('');
    setPassword('');
    setError(false);
    setSubmitted(false);
  };

  useEffect(() => {
    setPath(location.pathname);
  }, [location]);


  const navigate = useNavigate(); // Get the navigate function

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    // Validate form inputs
    if (!email || !password) {
      setError(true);
      return;
    }



  

    // Assuming you have a login API endpoint
    try {
      const response = await fetch('https://bharat-360-app-vzet3.ondigitalocean.app/api/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      });

      if (response.ok) {
        // Login successful
        const data = await response.json();
        // Store user token or data if needed
        // Redirect to the dashboard or wherever you want using navigate
        navigate('/'); // Use navigate here
      } else {
        // Login failed
        setError(true);
      }
    } catch (error) {
      console.error('Login error:', error);
      setError(true);
    }
  };

  return (
    <>
      {/* <RegisterModal />
            <LoginModal /> */}

      <nav className="navbar">
        <div className="newnav">
          <div className="top-nav">
          <div className="side-icon">
          <Link to='/profiles' style={{margin: "0"}}>
            <img src={search} />
          </Link>

          </div>
            <Link to={`/`}>
              <img src={logo} />           
            </Link>

            {/* <div className="side-icon">
              <Link className="social-btn" to={`/`}>
                <img src={like} />
              </Link>
              <Link className="social-btn" to={`/cart`}>
                <img src={cart} />
              </Link>
              <Link className="social-btn" to={`/profile`}>
                <img src={profile} />
              </Link>
            </div> */}
            <button className="login-tbn">
            <Modal
   size='lg'
    isOpen={modal}
    toggle={()=> setModal(!modal)}
    >

    {/* <ModalHeader className="ModalHeader"
    toggle={()=> setModal(!modal)}>
 <p className='Runwithus'>Confirm Your Pandit Booking</p>

    </ModalHeader> */}
    <div className="container">
    <ModalBody className='modelbody'>

    {/* <div className="modelbody-left">
    <img src={img}/>

    </div>

    
    <div className="modelbody-right">
    <div>
    <h1>Himalayan Bliss Yatra</h1>
    <h3>₹30,053 per person*<br/>
    <span> *Excluding applicable taxes</span></h3>
    <hr/>
    
    <div className='PackageTypess'>
        <p>Package Type</p>
        <h2>Spiritual Pilgrimage</h2>
    </div>
    <div className='PackageTypess'>
        <p>Duration</p>
        <h2>12 days</h2>
    </div>
    <div className='PackageTypess'>
        <p>Package Type</p>
        <h2>Spiritual Pilgrimage</h2>
    </div>

    
    <button className='book'>Book</button>
    <button className='book-cancel'>Cancel</button>


    </div>

    </div> */}
    <div className="NewLoginPage">
      <div className="NewLoginPage-left">
        <img src={img} alt="Profile" />
      </div>
      <div className="NewLoginPage-right">
        <img src={logo} alt="Logo" />
        <h1>SIGN IN</h1>
        <p>Join Us in the spiritual journey</p>
        <label>E-mail or phone number</label>
        {error && email.length <= 0 && submitted ? (
          <p className="error-text">*Please enter the Email.</p>
        ) : null}
        <input
          className="logInput"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setError(false);
          }}
          type="email"
          name="email"
          placeholder="Enter your Email"
        />
        <label>Password</label>
        {error && password.length <= 0 && submitted ? (
          <p className="error-text">*Please enter the Password.</p>
        ) : null}
        <input
          className="logInput"
          name="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            setError(false);
          }}
          type="password"
          placeholder="Enter your Password"
        />
        <div className="checks">
          <input type="checkbox" />
          <p>I agree to all terms and conditions</p>
        </div>
        <button onClick={(e) => handleOnSubmit(e)}>Login</button>
        <h3 className="Forgot-Password">Forgot Password?</h3>
        {/* <div className="socialbtnss">
          <button className="authbtn">
            <img src={google} alt="Google" /> Sign up with Google
          </button>
          <br/>
          <button className="authbtn">
            <img src={fb} alt="Facebook" /> Sign up with Facebook
          </button>
        </div> */}
        <h4 className="dnthvacc">
          Don’t have an account? <span><Link onClick={()=>setSignmodal(true)}>Signup</Link></span>
        </h4>
      </div>
    </div>
   

   </ModalBody>


   <Modal
   size='lg'
    isOpen={signmodal}
    toggle={() => {
          setSignmodal(!signmodal);
          setModal(false); // Close the login modal when opening sign-up modal
        }}
    >

    {/* <ModalHeader className="ModalHeader"
    toggle={()=> setModal(!modal)}>
 <p className='Runwithus'>Confirm Your Pandit Booking</p>

    </ModalHeader> */}
    <div className="container">
    <ModalBody className='modelbody'>

    {/* <div className="modelbody-left">
    <img src={img}/>

    </div>

    
    <div className="modelbody-right">
    <div>
    <h1>Himalayan Bliss Yatra</h1>
    <h3>₹30,053 per person*<br/>
    <span> *Excluding applicable taxes</span></h3>
    <hr/>
    
    <div className='PackageTypess'>
        <p>Package Type</p>
        <h2>Spiritual Pilgrimage</h2>
    </div>
    <div className='PackageTypess'>
        <p>Duration</p>
        <h2>12 days</h2>
    </div>
    <div className='PackageTypess'>
        <p>Package Type</p>
        <h2>Spiritual Pilgrimage</h2>
    </div>

    
    <button className='book'>Book</button>
    <button className='book-cancel'>Cancel</button>


    </div>

    </div> */}
    <div className="NewLoginPage">
      <div className="NewLoginPage-left">
        <img src={img} alt="Profile" />
      </div>
      <div className="NewLoginPage-right">
        <img src={logo} alt="Logo" />
        <h1>SIGN UP</h1>
        <p>Join Us in the spiritual journey</p>
        <label>E-mail or phone number</label>
        {error && email.length <= 0 && submitted ? (
          <p className="error-text">*Please enter the Email.</p>
        ) : null}
        <input
          className="logInput"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setError(false);
          }}
          type="email"
          name="email"
          placeholder="Enter your Email"
        />
        <label>E-mail or phone number</label>
        {error && email.length <= 0 && submitted ? (
          <p className="error-text">*Please enter the Email.</p>
        ) : null}
        <input
          className="logInput"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setError(false);
          }}
          type="email"
          name="email"
          placeholder="Enter your Email"
        />
        <label>Password</label>
        {error && password.length <= 0 && submitted ? (
          <p className="error-text">*Please enter the Password.</p>
        ) : null}
        <input
          className="logInput"
          name="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            setError(false);
          }}
          type="password"
          placeholder="Enter your Password"
        />
        <div className="checks">
          <input type="checkbox" />
          <p>I agree to all terms and conditions</p>
        </div>
        <button onClick={(e) => handleOnSubmit(e)}>Login</button>
        <h3 className="Forgot-Password">Forgot Password?</h3>
        {/* <div className="socialbtnss">
          <button className="authbtn">
            <img src={google} alt="Google" /> Sign up with Google
          </button>
          <br/>
          <button className="authbtn">
            <img src={fb} alt="Facebook" /> Sign up with Facebook
          </button>
        </div> */}
        <h4 className="dnthvacc">
          Already Have an Account <span><Link onClick={handleSignUp}>Login</Link></span>
        </h4>
      </div>
    </div>
   

   </ModalBody>





    </div>
   

    </Modal>


    </div>
   

    </Modal>
            <Link className="logBtn" onClick={()=>setModal(true)}>Login</Link>
            
         
            </button>
          </div>
       
          {/* <div className="bottom-nav">
            <ul>
              <li>
                {" "}
                <Link
                  to="/"
                  id="navlinks"
                  className={path === "/" ? "mob-nav1-active" : ""}
                >
                  Home
                </Link>
              </li>
              <li>
                {" "}
                <Link
                  to="/collection"
                  className={path === "/collection" ? "mob-nav1-active" : ""}
                >
                  Collections
                </Link>{" "}
              </li>
              <li>
                {" "}
                <Link
                  to="/bharat"
                  className={path === "/bharat" ? "mob-nav1-active" : ""}
                >
                  Bharat 360
                </Link>
              </li>
              <li>
                <Link
                  to="/blog"
                  className={path === "/blog" ? "mob-nav1-active" : ""}
                >
                  Blogs
                </Link>
              </li>
              <li>
                <Link
                  to="/about"
                  className={path === "/about" ? "mob-nav1-active" : ""}
                >
                  About Us
                </Link>
              </li>
            </ul>
          </div> */}
          <Topnav/>
        </div>

        {/* 
                <div className="nav1 py-2.5 px-16 flex justify-between">
                    <div className="nav11 flex items-center"> */}
        {/* <Link to={`/`} className="nav-logo">
                            <img src="/images/i1.png" alt="" />
                        </Link> */}
        {/* 
                        <div className="mob-nav hidden">
                            <svg onClick={() => {
                                document.querySelector('.mob-nav1').classList.toggle('mob-active');
                                console.log('here toggle')
                            }} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                            </svg>
                        </div>
                        <div className="mob-nav1 hidden pt-5">
                            <Link to="/" className={path==='/' ? 'mob-nav1-active' : ''}>Home</Link>
                            <Link to="/collection" className={path==='/collection' ? 'mob-nav1-active' : ''}>Collections</Link>
                            <Link to="https://bharat360.in/" target='_blank'>Services</Link>
                            <Link to="/blog" className={path==='/blog' ? 'mob-nav1-active' : ''}>Blogs</Link>
                            <Link to="/about" className={path==='/about' ? 'mob-nav1-active' : ''}>About Us</Link>
                        </div>
                    </div> */}
        {/* <div className="nav13 flex items-center">
                        <div className="top21">
                            <Link to="/" className={path==='/' ? 'nav-active' : ''}>Home</Link>
                        </div>
                        <div className="top21 top22">
                            <Link to="/collection" className={path==='/collection' ? 'nav-active' : ''}>Collections</Link>
                        </div>
                        <div className="top21 top22">
                            <Link to="https://bharat360.in/" target='_blank' className={path==='/services' ? 'nav-active' : ''}>Services</Link>
                        </div>
                        <div className="top21 top22">
                            <Link to="/blog" className={path==='/blog' ? 'nav-active' : ''}>Blogs</Link>
                        </div>
                        <div className="top21 top22">
                            <Link to="/about" className={path==='/about' ? 'nav-active' : ''}>About Us</Link>
                        </div>
                    </div> */}
        {/* <div className="nav12 flex items-center"> */}
        {/* <img src="/images/i3.png" alt="" /> */}
        {/* <img onClick={()=>{
                            navigate('/cart');
                        }} src="/images/i4.png" alt="" /> */}
        {/* <img onClick={()=>{
                            if(localStorage.getItem('aaradhak'))
                            navigate('/profile')
                            else
                            navigate('/login');
                        }} src="/images/i2.png" alt="" /> */}

        {/* 
</div>


                    </div> */}
        {/* </div> */}
      </nav>
    </>
  );
};

export default Navbar;
