import React, { useEffect } from 'react'
import Navbar from "../components/header/Navbar";
import Footer from "../components/footer/footer";
import CartPage from '../components/cart/CartPage';
import { useLoaderData } from 'react-router-dom';

export async function loader({ params }) {
  return params;
}


function Cart() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const data = useLoaderData()
  return (
    <>
    <Navbar />
    <CartPage cartId={data.cartID} />
    <Footer />
    </>
  )
}

export default Cart