import React from 'react'
import Navbar from '../header/Navbar'
import JionUs from '../BannerSection/JionUs'
import Footer from '../footer/footer'
import img from '../Assets/tags.png'
import './cart-page.css'

function NewCart() {
  return (
    <div className='full'>
        <Navbar/>

        <div className='Newcart'>
     
        <div className="cart-left">
            <h1 className="cart-heading">Your shopping cart</h1>
            <div className="addr">
            <br/>
              <div style={{marginTop:'30px'}}>
                <h1>ADDRESS</h1>
                <p>Hn0 135 Xyz colony, Ranjhi Jabalpur MP 482005</p>
              </div>
           
            </div>
            <br />
            <br />

            <hr />
            <div className="offer">
              <div>
                <h1>Available offers</h1>
                <p>Flat 20% off on all order above 999</p>
                <p style={{ color: "orange" }}>Show more</p>
              </div>
            </div>
            <br />
            <br />

            <hr />

        
          </div>




 <div className="cart-right">
            <div className="applycoupon">
              <h1>Price details</h1>
              <div className="coupon">
                <img src={img} />
                <p>Apply Coupons</p>
                <button>Apply</button>
              </div>
            </div>
            <br />
            <hr />
            <br />

            <p className="itemselected">Total items selected (2 items)</p>
            <div className="totalMRP">
              <p>Total MRP</p>
              <p>55</p>
            </div>
            <div className="discount">
              <p>Discount</p>
              <p style={{ color: "#C02026" }}>-00</p>
            </div>
            <div className="totalMRP">
              <p>Convenience fees</p>
              <p>000</p>
            </div>
            <div className="billline"></div>
            <br />
            <div className="totalMRP">
              <p>
                <strong>Total Amount</strong>
              </p>
              <p>
                <strong>555</strong>
              </p>
            </div>
            <button className="place-order-btn">Place Order</button>
          </div>




        </div>

        <JionUs/>
        <Footer/>
    </div>
  )
}

export default NewCart