import React, { useEffect, useState } from "react";
import "./panditPage.css";
import {Modal , ModalBody, ModalHeader} from "reactstrap";
// import CountUp from 'react-countup';

import img from '../Assets/Rectangle 421.png'

function HeroSection() {
  const [modal ,setModal] = useState(false)

  // const [cityLocation, setCityLocation] = useState("");
  // const [allCity, setAllCity] = useState([]);

  // const cities = [
  //   { "City Name": "Indore" },
  //   { "City Name": "Mumbai" },
  //   { "City Name": "Delhi" },
  //   { "City Name": "Surat" },
  //   { "City Name": "Rajkot" },
  // ];

  // useEffect(() => {
  //   setAllCity(cities);
  // }, []);
  
  return (
    <div className="full">
        {/* <section className="hero-section">
          <div className="text-center">
            <h1>Book pandit ji online all kinds of Puja!! </h1>
            <p>
              Panditjee Online is the most trusted platform for availing Vedic
              and Hindu Puja Services like performing Vedic Rituals, Religious
              Ceremonies, Vastu Yagya and many more
            </p>
          </div>
          <div className="d-inline">
            <h6 className="text-left d-inline">
              Home / <span>Pandits</span>
            </h6>
          <div className="select-city text-center">
            <select id="" value={cityLocation}
                onChange={(e) => setCityLocation(e.target.value)}>
            {allCity.map((element, index) => {
                  return (
                    <>
                      <option value={element[index]} key={index}>
                        {element["City Name"]}
                      </option>
                    </>
                  );
                })}
            </select>
          </div>
          </div>
          <div className="pandit-available pb-2"><span>Pandits available for puja or havans</span></div>
        </section> */}

        <div className="paidit-home">

        <div className='banner panditBanner'>
  
  <h1>Schedule a Pandit for Your Puja</h1>
  <p>Experience the divine blessings of sacred rituals made convenient.</p>
  

  <div className='filterinputs'>
  <div className='filterinputs-input'>

{/*  */}
<div className='searchfrom'>
<i class="bi bi-geo-alt"></i>
<div className='fromdiv'>
  <lable className='fromdivlabel' >FROM</lable>
  <input placeholder='city'/>
</div>



</div>
{/*  */}
  {/*  */}
  <div className='searchfrom'>
<i class="bi bi-geo-alt"></i>
<div className='fromdiv'>
  <lable className='fromdivlabel' >To</lable>
  <input placeholder='city'/>
</div>



</div>
{/*  */}
  {/*  */}
  <div className='searchfrom'>
  <i class="bi bi-calendar-date"></i>
<div className='fromdiv'>
  <lable className='fromdivlabel' >DATE</lable>
  <input  placeholder='Date'/>
</div>



</div>
{/*  */}

{/*  */}
<div className='searchfrom'>
<i class="bi bi-person"></i>
<div className='fromdiv'>
  <lable className='fromdivlabel' >PERSONS</lable>
  <input placeholder='Select'/>
</div>



</div>
{/*  */}



</div>
  <Modal
   size='lg'
    isOpen={modal}
    toggle={()=> setModal(!modal)}
    >

    <ModalHeader className="ModalHeader"
    toggle={()=> setModal(!modal)}>
 <p className='Runwithus'>Confirm Your Pandit Booking</p>

    </ModalHeader>
    <div className="container">
    <ModalBody className='modelbody'>

    <div className="modelbody-left">
    <img src={img}/>

    </div>

    
    <div className="modelbody-right">
    <div>
    <h1>Himalayan Bliss Yatra</h1>
    <h3>₹30,053 per person*<br/>
    <span> *Excluding applicable taxes</span></h3>
    <hr/>
    
    <div className='PackageTypess'>
        <p>Package Type</p>
        <h2>Spiritual Pilgrimage</h2>
    </div>
    <div className='PackageTypess'>
        <p>Duration</p>
        <h2>12 days</h2>
    </div>
    <div className='PackageTypess'>
        <p>Package Type</p>
        <h2>Spiritual Pilgrimage</h2>
    </div>

    
    <button className='book'>Book</button>
    <button className='book-cancel'>Cancel</button>


    </div>

    </div>
   

   </ModalBody>
    </div>
   

    </Modal>
  <button className='bannerbtn' onClick={()=>setModal(true)}>Search</button>
  

  </div>
  </div>
          
        </div>

        <div className="EasyScheduling">
        <div className="EasyScheduling-center">
        <div className="EasyScheduling-center-img">
        <div className="EasyScheduling-center-img-overlay">
        <h2>
        Easy Scheduling Process:
        </h2>
        <p>At Bharat360, we offer a seamless way to schedule a Pandit for your puja, ensuring your spiritual journey is as smooth as it is meaningful.</p>
        <button>Book Now</button>

        </div>

        </div>
        <div className="EasyScheduling-center-detail">
        <div className="EasyScheduling-center-detail-top">
        <div className="EasyScheduling-center-detail-top-box">

        <h3>01</h3>
        <h4>Choose Your Date</h4>
        <p>Pick the date that aligns with your spiritual journey.</p>



</div>
    <div className="EasyScheduling-center-detail-top-box">

<h3>02</h3>
<h4>Select Your Time</h4>
<p>Find the perfect time for your puja.</p>



</div>


        </div>
        <div className="EasyScheduling-center-detail-top">
        <div className="EasyScheduling-center-detail-top-box">

        <h3>03</h3>
        <h4>Share Your Location</h4>
        <p>Input the address or location where your ceremony will take place</p>



</div>
    <div className="EasyScheduling-center-detail-top-box">

<h3>04</h3>
<h4>Select Your Puja</h4>
<p>Choose from a variety of rituals and ceremonies to suit your needs.</p>



</div>


        </div>
        <h1>Click "Book" and we'll take care of the rest.</h1>


        </div>
        

</div>


        </div>

    </div>
  );
}

export default HeroSection;
