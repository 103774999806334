import React, { useContext, useEffect, useState } from "react";
import "./sipping-page.css";
import { Grid } from "@mui/material";
import { CartAPI, SinglePrashadApi } from "../../api/ProjectsAPI";
import { Link, useNavigate } from "react-router-dom";
import PrashadBookingContext from "../../context/prashadBooking/PrashadBooking";
import axios from "axios";

function SippingPage({sippingID}) {
  const PrashadState = useContext(PrashadBookingContext);
  const {email, setEmail, apartment, setApartment, cartValue, gotra, country, firstName,  lastName, address,  city,  state, pinCode, mobileNumber } = PrashadState;
  const navigate = useNavigate();


  const [shippingRadio, setShippingRadio] = useState(true);
  const [status, setStatus] = useState("")
  const [changeEmailStyle, setChangeEmailStyle] = useState({});
  const [changeShipToStyle, setChangeShipToStyle] = useState({});
  const [paymentMethods, setPaymentMethods] = useState([
    { name: "Credit / Debit Card, Net Banking", status: true },
    { name: "Sash free Payment", status: false },
    { name: "Payments from outside india", status: false },
  ]);
  const [billingAddress, setBillingAddress] = useState("");
  const [billingAddresses, setBillingAddresses] = useState([
    { name: "Same as shipping address", status: true },
    { name: "Use a different Billing Address", status: false },
  ]);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [sippingData, setSippingData] = useState([]);
  const data = paymentMethods.map((allJobs) => allJobs);

  useEffect(() => {
    if (email.length === 0) {
      setChangeEmailStyle({ display: "none" });
    } else {
      setChangeEmailStyle({ display: "block" });
    }
    if (apartment.length === 0) {
      setChangeShipToStyle({ display: "none" });
    } else {
      setChangeShipToStyle({ display: "block" });
    }
  }, [email, apartment]);
  function ClearEmail() {
    setEmail("");
    setChangeEmailStyle({ display: "none" });
  }
  function ClearShipTo() {
    setApartment("");
    setChangeShipToStyle({ display: "none" });
  }

  useEffect(() => {
    SinglePrashadApi.get(sippingID, {}, (response) => {
      setSippingData(response.data);
    })

  }, [])
  

  async function handleOnSubmit() {
    const raw = JSON.stringify({
      "amount": "10.00",
      // "amount": (sippingData.cost * cartValue).toString(),
      "purpose": "Prashad / Puja",
      "buyer_name": firstName + " " + lastName,
      "email": email,
      "phone": mobileNumber,
      "redirect_url": "http://localhost:3000",
      "orderId": sippingID

    });
    try {
      const res = await axios({
        method: "post",
        url: `https://starfish-app-lyrtb.ondigitalocean.app/api/payment/generatepaymentlink`,
        data: raw,
        headers: {
          'Content-Type': 'application/json',
          // authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      setStatus(res.status);
      window.location.replace(res.data.data.longurl);
    } catch (e) {}
    if(status === 200) {
      let body = JSON.stringify({
        email: email,
        address: {
          home: apartment,
          address: address,
          pin: pinCode,
          PO: mobileNumber,
          firstName: firstName,
          lastName: lastName,
          city: city,
          state: state,
          gotra: gotra,
          country: country,
        },
        payment_mode: "ONLINE",
      });
      try {
        const res = await axios({
          method: "post",
          url: `https://starfish-app-lyrtb.ondigitalocean.app/api/checkout/prasad/createhistory/prasad`,
          data: body,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        navigate("/prashad")
      } catch (e) {}
    }
  }


  let cart = null;
  let total = null;
  let subTotal = null;
  if (sippingData.length !== 0) {
    cart = (
      <div className="d-flex">
        <img src={sippingData.displayImage} alt="" />
        <div className="cart-img">
          <p className="cart-img-value">{cartValue}</p>
        </div>
        <div className="order-cart-text">
          <h6>
            {sippingData.templeName + " (" + sippingData.location[0].address + ")"}{" "}
          </h6>
          <div className="d-flex justify-content-between align-items-center">
            <p className="m-0">Prashad only</p>
            <p className="m-0 cart-price" style={{fontSize: "20px"}}>{"RS. " + sippingData.cost * cartValue + ".00"}</p>
          </div>
        </div>
      </div>
    );

    total = (
      <div className="d-flex justify-content-between">
        <span className="sub-total">Total</span>
        <span className="cart-price">INR {"RS. " + sippingData.cost * cartValue + ".00"}</span>
      </div>
    );
    subTotal = (
      <div className="d-flex justify-content-between">
        <span className="sub-total mb-2">Sub Total</span>
        <span className="sub-price">{"RS. " + sippingData.cost * cartValue + ".00"}</span>
      </div>
    );
  }
  

  return (
    <>
      <section className="cart-hero">
        <div className="container-fluid">
          <div className="text-center">
            <h1 className="cart-head">Shipping & Convenience Fee Cart</h1>
          </div>
          <div className="container">
            <div className="path">
              <p className="path-text">
                Home / Online Prashad / Siddhi vinayak temple Prashad (Mumbai) /
                Order summary / Cart /{" "}
                <span className="path-span"> shipping & convenience fee</span>
              </p>
            </div>
            <div className="cart-container">
              <Grid container>
                <Grid item lg={6}>
                  <div className="contact-information">
                    <form action="" onSubmit={handleOnSubmit}>
                      <label htmlFor="" className="d-block label">
                        Contact
                      </label>
                      <div className="sipping-email">
                        <input
                          className="input-box"
                          type="email"
                          value={email}
                          placeholder="Email"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <span
                          className="change-text"
                          style={changeEmailStyle}
                          onClick={() => ClearEmail()}
                        >
                          Change
                        </span>
                      </div>
                      <label htmlFor="" className="d-block label">
                        Ship To
                      </label>
                      <div className="sipping-email">
                        <input
                          className="input-box"
                          type="text"
                          value={apartment}
                          placeholder="address"
                          onChange={(e) => setApartment(e.target.value)}
                        />
                        <span
                          className="change-text"
                          style={changeShipToStyle}
                          onClick={() => ClearShipTo()}
                        >
                          Change
                        </span>
                      </div>
                      <label htmlFor="" className="d-block label">
                        Shipping Method
                      </label>
                      <div className="free-shipping">
                        <input
                          type="radio"
                          value={shippingRadio}
                          defaultChecked
                          onChange={(e) => setShippingRadio(e.target.value)}
                          id="sippingRadio"
                          className="input-radio"
                        />
                        <label
                          className="shipping-box"
                          type="text"
                          htmlFor="sippingRadio"
                        >
                          Free shipping and Delivery
                        </label>
                        <span className="free-text">Free</span>
                      </div>
                      <h6 className="payment-title">Payment </h6>
                      <p className="payment-text">
                        All transactions are secure and encrypted.
                      </p>
                      <div className="input-box d-flex align-items-center">
                        <input
                          type="radio"
                          className="radio-field"
                          defaultChecked
                          name="payment"
                          value={paymentMethod}
                          onChange={() => setPaymentMethod(data[0].status)}
                        />
                        <label>Credit / Debit Card, Net Banking</label>
                      </div>
                      <div className="input-box d-flex align-items-center">
                        <input
                          type="radio"
                          className="radio-field"
                          value={paymentMethod}
                          name="payment"
                          onChange={() => setPaymentMethod(data[1].status)}
                        />
                        <label>Cash free Payment</label>
                      </div>
                      <div className="input-box d-flex align-items-center">
                        <input
                          type="radio"
                          className="radio-field"
                          value={paymentMethod}
                          name="payment"
                          onChange={() => setPaymentMethod(data[2].status)}
                        />
                        <label>Payments from outside india</label>
                      </div>
                      <h6 className="payment-title">Billing Address </h6>
                      <p className="payment-text">
                        Select the address that matches your card or payment
                        method.
                      </p>
                      <div className="input-box d-flex align-items-center">
                        <input
                          type="radio"
                          className="radio-field"
                          defaultChecked
                          name="billing"
                          value={billingAddress}
                          onChange={() =>
                            setBillingAddress(billingAddresses[0].status)
                          }
                        />
                        <label>Same as shipping address</label>
                      </div>
                      <div className="input-box d-flex align-items-center">
                        <input
                          type="radio"
                          className="radio-field"
                          value={billingAddress}
                          name="billing"
                          onChange={() =>
                            setBillingAddress(billingAddresses[1].status)
                          }
                        />
                        <label>Use a different Billing Address</label>
                      </div>
                      <div className="return-container d-flex">
                        <Link to={`/prashad/order-summary/cart/${sippingID}`}>
                        <button className="return-btn">Return To Cart</button>
                        </Link>
                        <button className="proceed-payment-btn" onClick={handleOnSubmit}>
                          Copmlete order
                        </button>
                      </div>
                    </form>
                  </div>
                </Grid>
                <Grid item lg={6}>
                  <div className="order-cart">
                   {cart}
                    <br />
                    <hr />
                    <br />
                    <div className="cart-code d-flex">
                      <input
                        className="discount-code"
                        placeholder="Discount Code"
                      />
                      <button className="apply">Apply</button>
                    </div>
                    <br />
                    <hr />
                    <br />
                    <div>
                     {subTotal}
                      <div className="d-flex justify-content-between">
                        <span className="sub-total">Delivery And Shipping</span>
                        <span className="sub-calculate">
                          Calculate at Next Step
                        </span>
                      </div>
                    </div>
                    <br />
                    <hr />
                    {total}
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SippingPage;
