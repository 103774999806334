import React, { useContext, useEffect, useState } from "react";
import "./book-now-page.css";
import Navbar from "../header/Navbar";
import Footer from "../footer/footer";
import { Grid } from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import BookingContext from "../../context/booking/bookingContext";

function BookNowPage() {
  const params = useParams();
  const BookingStates = useContext(BookingContext);
  const {pujaName, selectedDate, selectedTime,  price, selectPuja, } = BookingStates;



  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [cityName, setCityName] = useState("");
  const [stateName, setStateName] = useState("");
  const [status, setStatus] = useState("");



  async function SubmitNow() {
    const data = JSON.stringify({
      "amount": "10.00",
      // "amount": (sippingData.cost * cartValue).toString(),
      "purpose": "Prashad / Puja",
      "buyer_name": name,
      "email": email,
      "phone": mobile,
      "redirect_url": "http://localhost:3000",
      "orderId": params.panditId

    });
    try {
      const res = await axios({
        method: "post",
        url: `https://starfish-app-lyrtb.ondigitalocean.app/api/payment/generatepaymentlink`,
        data: data,
        headers: {
          'Content-Type': 'application/json',
          // authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      setStatus(res.status);
      if(res.status){
        window.location.replace(res.data.data.longurl);
      }
    } catch (e) {}

    if(status === "200") {
      let raw = JSON.stringify({
        "address": {
          "loc": address,
          "city": cityName,
          "state": stateName
        },
        "puja": pujaName,
        "phone": mobile,
        "email": email,
        "date": selectedDate,
        "time": selectedTime,
        "cost": price,
        "payment_mode": "ONLINE",
        "panditId": params.panditId
      })
      try {
        const bookPuja = await axios({
          method: "post",
          url: `https://starfish-app-lyrtb.ondigitalocean.app/api/checkout/puja/create/offline`,
          data: raw,
          headers: {
            'Content-Type': 'application/json',
            authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        setName("");
        setEmail("");
        setAddress("");
        setCityName("");
        setMobile("");
        setStateName("");
      } catch (e) {}
    }
  }


  return (
    <>
      <Navbar />
      <section className="diwali-detail-hero">
        <div className="container-fluid">
          <div className="text-center">
            <h1>Book Now</h1>
          </div>
          <div className="container">
            <div className="diwali-head">
              <p>
                Home / Pandits /<span> Diwali Puja</span>
              </p>
            </div>
            <div className="book-pandit">
              <span>Book Pandit ji</span>
              <p className="book-pandit-text">
                Please fill the details below and book panditji in two minutes
              </p>
              <Grid container columnSpacing={5}>
                <Grid item xs={12} sm={12} lg={6}>
                  <div className="d-flex flex-column">
                    <label className="book-label" htmlFor="">
                      Name
                    </label>
                    <input value={name} onChange={(e) => setName(e.target.value)} className="book-input" type="name" />
                  </div>
                  <div className="d-flex flex-column">
                    <label className="book-label" htmlFor="">
                      Mobile
                    </label>
                    <input value={mobile} onChange={(e) => setMobile(e.target.value)} className="book-input" type="number" />
                  </div>
                  <div className="d-flex flex-column">
                    <label className="book-label" htmlFor="">
                      Email Id
                    </label>
                    <input value={email} onChange={(e) => setEmail(e.target.value)} className="book-input" type="email" />
                  </div>
                  <div className="d-flex flex-column">
                    <label className="book-label" htmlFor="">
                      Address
                    </label>
                    <input className="book-input" type="text" value={address} onChange={(e) => setAddress(e.target.value)} />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} lg={6}>
                  <div className="d-flex flex-column">
                    <label className="book-label" htmlFor="">
                      Selected Puja
                    </label>
                    <input value={pujaName} disabled className="book-input" type="text" />
                  </div>
                  <div className="d-flex flex-column">
                    <label className="book-label" htmlFor="">
                      Enter City Name
                    </label>
                    <input value={cityName} onChange={(e) => setCityName(e.target.value)} className="book-input" type="text" />
                  </div>
                  <div className="d-flex flex-column">
                    <label className="book-label" htmlFor="">
                      Enter State Name
                    </label>
                    <input value={stateName} onChange={(e) => setStateName(e.target.value)} className="book-input" type="text" />
                    {/* <input className="book-input" type="text" /> */}
                  </div>
                  <div className="d-flex flex-column">
                    <label className="book-label" htmlFor="">
                      Selected date and Time
                    </label>
                    <input value={`Date:- ${selectedDate} Time:- ${selectedTime} `} disabled className="book-input" type="text" />
                  </div>
                </Grid>
              </Grid>
              <div className="text-center">
                <button className="book-now-submit-btn" onClick={() => SubmitNow()}>Submit Now</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default BookNowPage;
