import React, { useContext, useEffect, useState } from "react";
import "./orderPage.css";
import { Button, Grid, Snackbar } from "@mui/material";
import img from "../../assets/image 41.svg";
import { Link } from "react-router-dom";
import { SinglePrashadApi } from "../../api/ProjectsAPI";
import axios from "axios";
import PrashadBookingContext from "../../context/prashadBooking/PrashadBooking";
import CartContext from "../../context/cartContaxt/CartContext";

function OrderPage({prasadID}) {
  const PrashadState = useContext(PrashadBookingContext);
  const cartContext = useContext(CartContext);

  
  const { cartValue } = PrashadState;
  const { cartToast } = cartContext;


  const [requestItem, setRequestItem] = useState(null);
  const [alert, setAlert] = useState(cartToast);
  
  useEffect(() => {
    
    SinglePrashadApi.get(prasadID, {}, (response) => {
      setRequestItem([response.data])
    });
  }, []);
  if(requestItem === null) {
    return(
      <>
      </>
    )
  }
  const OrderSummery = requestItem.map((element, index) => {
    return (
      <>
        <div className="order-detail" key={index}>
          <h2 className="order-name">{element.templeName + " (" + element.location[0].address + ") "}</h2>
          <p className="order-category">Prashad only</p>
          <div className="d-flex align-item-center">
            <span className="order-ctg">Qty</span>
            <span className="order-item">{cartValue}</span>
          </div>
          <div className="sub-total">
            <p className="total">
              Sub Total : <span>{"RS. " + element.cost * cartValue + ".00"}</span>
            </p>
            <Link to={`/prashad/order-summary/cart/${prasadID}`}>
              <Button variant="contained" className="proceed-btn">
                Proceed To Ship
              </Button>
            </Link>
            <p className="add-more text-center">Add More Items</p>
          </div>
        </div>
      </>
    );
  });
  return (
    <>
      <section className="order-hero">
      <Snackbar
        open={alert === '' ? false : true}
        autoHideDuration={6000}
        onClose={() => {
            setAlert('');
        }}
        severity="error"
        message={alert}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
      />
        <div className="container-fluid">
          <div className="text-center">
            <h1 className="order-head">Your Order Summary</h1>
          </div>
        </div>
        <div className="container">
          <div className="path">
            <p className="path-text">
              Home / Online Prashad / {requestItem[0].templeName + " (" + requestItem[0].location[0].address + ") "} /{" "}
              <span className="path-span">Order summary</span>
            </p>
          </div>
          <Grid container rowSpacing={4}>
            <Grid item lg={3}>
              <img src={img} alt="" />
            </Grid>
            <Grid item lg={5}>
              {OrderSummery}
            </Grid>
          </Grid>
        </div>
      </section>
    </>
  );
}

export default OrderPage;
