import { createHashRouter } from "react-router-dom";
import App from "../App";
import HomePage from "../page/HomePage";
import Prashad from "../page/Prashad";
import Pandit from "../page/Pandit";
import Puja from "../page/Puja";
import LiveDarshan from "../page/LiveDarshan";
import Blogs from "../page/Blogs";
import Satsang from "../page/Satsang";
import PrashadDetail, { loader as prasadLoader } from "../page/PrashadDetail";
import Order, { loader as orderLoader } from "../page/Order";
import Cart, { loader as cartLoader } from "../page/Cart";
import Sipping, { loader as sippingLoader } from "../page/Sipping";
import DiwaliPuja from "../page/DiwaliPuja";
import SelectPage from "../components/selectPage/SelectPage";
import BookNowPage from "../components/bookNowPage/BookNowPage";
import PujaBookingPage from "../components/pujaBookingPage/PujaBookingPage";
import BookingPage from "../page/BookingPage";
import CheckoutPage from "../components/checkoutpage/CheckoutPage";
import Login from "../page/Login";
import SignIn from "../page/SignIn";
// import Profile from "../page/Profile";
import BlogDetail from "../page/BlogDetail";
import About from "../components/About/About"
import NewProfile from "../components/Profile/NewProfile";
import TeerthHome from "../components/TeerthHome/TeerthHome";
import TeerthList from "../components/TeerthHome/TeerthList";
import TeerthYatraPackage from "../components/TeerthHome/TeerthYatraPackage";
import PrashadList from "../components/prashadpage/PrashadList";
import PrashadListDetails from "../components/prashadpage/PrashadListDetails";
import SatsangListing from "../components/satsnag/SatsangListing";
import BharatSikka from "../components/BharatSikka/BharatSikka";
import TeerthYatraPackageTwo from "../components/TeerthHome/TeerthYatraPackageTwo";
import NewProfileTwo from "../components/Profile/NewProfileTwo";
import Aaradhak from "../Aaradhak";
import NewCart from "../components/cart/NewCart";
import TandC from "../components/footer/TandC";
import PrivacyPolicy from "../components/footer/PrivacyPolicy";
import ReturnandRefund from "../components/footer/ReturnandRefund";
import Popularthisweek from "../components/footer/Popularthisweek";
import SippingandReturnpolicy from "../components/footer/SippingandReturnpolicy";
import LiveDarshanDetails from "../components/liveDarshan/LiveDarshanDetails";
import NewBlogDetails from "../components/blogs/NewBlogDetails";


const router = createHashRouter([
    {
        path: '/',
        element: <App />
    },
    {
        path: '/',
        element: <HomePage />
    },
    {
        path: '/aboutus',
        element: <About/>
    },
    {
        path: '/profiles',
        element: <NewProfile/>
    },
    {
        path: '/profilestwo',
        element: <NewProfileTwo/>
    },
    {
        path: '/aaradhak',
        element: <Aaradhak/>
    },
    {
        path: '/teerthhome',
        element: <TeerthHome/>
    },
    {
        path: '/teerthlist',
        element: <TeerthList/>
    },
    {
        path: '/teerthyatrapackage',
        element: <TeerthYatraPackage/>
    },
    // {
    //     path: '/teerthyatrapackagetwo',
    //     element: <TeerthYatraPackageTwo/>
    // },
    {
        path: '/prashad',
        element: <Prashad />
    },
    {
        path: '/prashadlist',
        element: <PrashadList/>
    },

    {
        path: '/prashadlistdetails/:id',
        element: <PrashadListDetails/>
    },
    {
        path: '/pandit',
        element: <Pandit />
    },
    {
        path: '/puja',
        element: <Puja />
    },
    {
        path: '/live-darshan',
        element: <LiveDarshan />
    },
    {
        path: '/live-darshan-details/:id',
        element: <LiveDarshanDetails/>
    },
    {
        path: '/blogs',
        element: <Blogs />
    },
    {
        path: '/blogsdetail/:id',
        element: <NewBlogDetails/>
    },
    {
        path: '/shop',
        element: <Satsang />
    },
    {
        path: '/shoplist',
        element: <SatsangListing/>
    },
    {
        path: '/prashad/prashad-detail/:prasadID',
        element: <PrashadDetail />,
        loader: prasadLoader,
    },
    {
        path: '/prashad/order-summary/:prasadID',
        element: <Order />,
        loader: orderLoader,
    },
    {
        path: '/prashad/order-summary/cart/:cartID',
        element: <Cart />,
        loader: cartLoader,
    },
    {
        path: '/prashad/order-summary/sipping/:sippingID',
        element: <Sipping />,
        loader: sippingLoader,
    },
    {
        path: '/pujas/booking-pandit/:bookingId',
        element: <DiwaliPuja />,
    },
    {
        path: '/pujas/choose-pandit',
        element: <SelectPage />
    },
    {
        path: '/pujas/choose-pandit/book-now/:panditId',
        element: <BookNowPage />
    },
    {
        path: '/pujas/puja-booking/:bookingId',
        element: <PujaBookingPage />,
    },
    {
        path: '/pujas/puja-booking/booking/:id',
        element: <BookingPage />
    },
    {
        path: '/pujas/puja-booking/booking/checkout/:id',
        element: <CheckoutPage />
    },
    {
        path: '/login',
        element: <Login />
    },
    {
        path: '/sign-in',
        element: <SignIn />
    }
    ,
    {
        path: '/bharatsikka',
        element: <BharatSikka/>
    },
    {
        path: '/newcart',
        element: <NewCart/>
    },
    {
        path: '/Termsandcondition',
        element: <TandC/>
    },
    {
        path: '/policy',
        element: <PrivacyPolicy/>
    },
    {
        path: '/returnrefund',
        element: <ReturnandRefund/>
    },
    {
        path: '/popularthisweek',
        element: <Popularthisweek/>
    },
    {
        path: '/sippingandreturn',
        element: <SippingandReturnpolicy/>
    },
 
])

export default router;