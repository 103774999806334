import React from 'react'
import Navbar from '../header/Navbar'
import Footer from './footer'

function Popularthisweek() {
  return (
    <div className='full'>
        <Navbar/>
        <div className='ftdt'>Popular this week
    </div>
    <Footer/>
    </div>
  )
}

export default Popularthisweek