import React from "react";
import img1 from "../Assets/Rectangle 241.png";
import img2 from '../Assets/Offerings (4).png'
import img3 from '../Assets/offering.png'
import img4 from '../Assets/Offerings (1).png'
import img5 from '../Assets/Offerings (2).png'
import img6 from '../Assets/Offerings (3).png'





function Offering() {
  return (
    <>
      <div className="offerings">
        <h1>OUR <span>SPRIITUAL</span> OFFERING</h1>
        <p>Enriching Your Journey with Divine Services</p>
        <br/>
        <br/>
        <div class="scroll-container">
        <div className="offring-cards">
          <div className="offring-card">
            <div className="offring-card-img">
              <img src={img1} />
            </div>

            <h3>Teerth Yatras</h3>
          </div>
          <div className="offring-card">
            <div className="offring-card-img">
              <img src={img2} />
            </div>

            <h3>Pandit Services</h3>
          </div>
          <div className="offring-card">
            <div className="offring-card-img">
              <img src={img3} />
            </div>

            <h3>Prashad Delivery</h3>
          </div>
          <div className="offring-card">
            <div className="offring-card-img">
              <img src={img4} />
            </div>

            <h3>Satsang and Spiritual Gatherings</h3>
          </div>
          <div className="offring-card">
            <div className="offring-card-img">
              <img src={img5} />
            </div>

            <h3>Live Darshan</h3>
          </div>
          <div className="offring-card">
            <div className="offring-card-img">
              <img src={img6} />
            </div>

            <h3>Bharat Sikka Rewards</h3>
          </div>
        </div>
        </div>
      </div>
    </>
  );
}

export default Offering;
