import React from "react";
import img from "../Assets/Ellipse 17.png";
import star from "../Assets/Frame 123.png";
import lightStar from "../Assets/light-star.svg";
import darkStar from "../Assets/dark-star.svg";
import shadow1 from '../Assets/Rectangle 39.png'
import shadow2 from '../Assets/Rectangle 40.png'


function Testimonials() {
  return (
    <>
      <div className="testimonial-section">
        <div className="testimonial">
          <div className="left-wrapper"></div>
          <div className="right-wrapper"></div>
          <h1 className="TBD-heading mb-3">
            <span className="Testimonials-heading">TESTIMONIALS AND <snap className="gradientreview">REVIEWS</snap></span>
          </h1>
          <br/>
          <br/>
          <br/>

          <div className="review-horizontal-scroll">
          {/* <img className="shadow1" src={shadow1}/> */}
          {/* <img className="shadow2" src={shadow2}/> */}

            <div className="review">
              <div className="comments">
                <div className="prof">
                  <img src={img} alt="Profile" />
                  <h3 className="prof-name">Priya S.</h3>
                </div>
                <div className="test-rating">
                  <img src={lightStar} alt="star" />
                  <img src={lightStar} alt="star" />
                  <img src={lightStar} alt="star" />
                  <img src={lightStar} alt="star" />
                  <img src={darkStar} alt="star" />
                </div>
                {/* <img className="star" src={star} alt="Star" /> */}
                <p>
                  I recently purchased a beautiful wall hanging and some
                  religious idols from Aaradhak. The quality of the products is
                  impressive, and they added a serene touch to my home. The only
                  reason I'm giving it 4 stars is because the delivery took a
                  bit longer than expected. Nonetheless, I'm satisfied with my
                  purchase!
                </p>
              </div>
            </div>
            <div className="review">
              <div className="comments">
                <div className="prof">
                  <img src={img} alt="Profile" />
                  <h3 className="prof-name">Rajan K.</h3>
                </div>
                <div className="test-rating">
                  <img src={lightStar} alt="star" />
                  <img src={lightStar} alt="star" />
                  <img src={lightStar} alt="star" />
                  <img src={lightStar} alt="star" />
                  <img src={darkStar} alt="star" />
                </div>
                <p>
                  Aaradhak's range of ritual essentials is commendable. I
                  ordered a puja thali and some incense sticks. The products are
                  undoubtedly authentic and well-crafted. However, I encountered
                  a slight issue with the packaging that caused some of the
                  incense sticks to break. Overall, I'm happy with my order, but
                  there's room for improvement.
                </p>
              </div>
            </div>
            <div className="review">
              <div className="comments">
                <div className="prof">
                  <img src={img} alt="Profile" />
                  <h3 className="prof-name">Meera A.</h3>
                </div>
                <div className="test-rating">
                  <img src={lightStar} alt="star" />
                  <img src={lightStar} alt="star" />
                  <img src={lightStar} alt="star" />
                  <img src={lightStar} alt="star" />
                  <img src={lightStar} alt="star" />
                </div>
                <p>
                  I am thrilled with the products I received from Aaradhak! The
                  attention to detail in their home decor items is truly
                  remarkable. The Ganesh idol I bought looks divine and adds an
                  auspicious aura to my living room. Prompt delivery and
                  top-notch customer service deserve a big thumbs up!
                </p>
              </div>
            </div>
            <div className="review">
              <div className="comments">
                <div className="prof">
                  <img src={img} alt="Profile" />
                  <h3 className="prof-name">Sameer P.</h3>
                </div>
                <div className="test-rating">
                  <img src={lightStar} alt="star" />
                  <img src={lightStar} alt="star" />
                  <img src={lightStar} alt="star" />
                  <img src={lightStar} alt="star" />
                  <img src={lightStar} alt="star" />
                </div>
                <p>
                  Aaradhak exceeded my expectations in every aspect. From the
                  exquisite religious items to the hassle-free shopping
                  experience, I couldn't be happier. The spiritual energy their
                  products bring into my space is truly special. I'm giving them
                  5 stars without a doubt!
                </p>
              </div>
            </div>
            <div className="review">
              <div className="comments">
                <div className="prof">
                  <img src={img} alt="Profile" />
                  <h3 className="prof-name">Niharika M.</h3>
                </div>
                <div className="test-rating">
                  <img src={lightStar} alt="star" />
                  <img src={lightStar} alt="star" />
                  <img src={lightStar} alt="star" />
                  <img src={lightStar} alt="star" />
                  <img src={lightStar} alt="star" />
                </div>
                <p>
                  I am thoroughly impressed with Aaradhak's offerings. The
                  variety of religious items they offer is remarkable, and the
                  quality is superb. I've purchased multiple items from them,
                  including a beautiful Om wall art, and each one has brought
                  positivity into my home. Their commitment to customer
                  satisfaction is evident, making it an easy 5-star rating from
                  me!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br/>
      <br/>

    </>
  );
}

export default Testimonials;
