import React from 'react'
import img from '../Assets/Rectangle 2411.png'
import img1 from '../Assets/Realtime (1).png'
import img2 from '../Assets/Realtime (2).png'
import img3 from '../Assets/Realtime (3).png'
import img4 from '../Assets/Realtime (4).png'


function RealTime() {
  return (
   <>
    <div className='Tapestry'>
        <h1 className='TeerthPackagesP'>Real-Time Divine Experiences</h1>
        <p className='TeerthPackagesP'>Witness real-time divine moments with our Live Darshan feature. Tune in to live streams from temples and holy places across India,<br/> connecting with the spiritual world from the comfort of your own space.</p>
        <br/>
        <br/>
      <div className='scroll-container'>
      <div className='Tapestry-cards'>
        <div className='Tapestry-card'>
        <div className='Tapestry-card-img'>
        <img src={img1}/>
        </div>
        <h3>Ganga Arti</h3>


        

        </div>
        <div className='Tapestry-card'>
        <div className='Tapestry-card-img'>
        <img src={img2}/>
        </div>
        <h3>Aarti from Varanasi</h3>


        

        </div>
        <div className='Tapestry-card'>
        <div className='Tapestry-card-img'>
        <img src={img3}/>
        </div>
        <h3>Live Cultural Events</h3>


        

        </div>
        <div className='Tapestry-card'>
        <div className='Tapestry-card-img'>
        <img src={img4}/>
        </div>
        <h3>Live Satsangs</h3>


        

        </div>

        </div>
      </div>
        <button className='vi-btn'>View all</button>

        </div>
   </>
  )
}

export default RealTime