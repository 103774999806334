import React, { useContext, useEffect, useState } from "react";
import "./cart-page.css";
import { Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { SinglePrashadApi } from "../../api/ProjectsAPI";
import LoginContext from "../../context/login/LoginContext";
import PrashadBookingContext from "../../context/prashadBooking/PrashadBooking";


function CartPage({ cartId }) {
  const navigate = useNavigate();
  

  const LoginStatus = useContext(LoginContext);
  const PrashadState = useContext(PrashadBookingContext);
  const {
    email,
    setEmail,
    apartment,
    setApartment,
    cartValue,
    gotra,
    setGotra,
    country,
    setCountry,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    address,
    setAddress,
    city,
    setCity,
    state,
    setState,
    pinCode,
    setPinCode,
    mobileNumber,
    setMobileNumber,
  } = PrashadState;

  const [emailChecked, setEmailChecked] = useState(false);
  const [error, setError] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [cartData, setCartData] = useState(null);

  useEffect(() => {
    SinglePrashadApi.get(cartId, {}, (response) => {
      setCartData([response.data]);
    });
  }, [cartId]);

  async function handleOnSubmit(e) {
    e.preventDefault();
    if (
      email.length < 1 ||
      firstName.length < 1 ||
      address.length < 1 ||
      apartment.length < 1 ||
      city.length < 1 ||
      pinCode.length < 1 ||
      mobileNumber.length < 1
    ) {
      setError(true);
      setSubmitted(true);
    }
    if(error === false ){
      navigate(`/prashad/order-summary/sipping/${cartId}`)
    }
  }

  let cart = null;
  let total = null;
  let subTotal = null;

  if (cartData !== null) {
    cart = (
      <div className="d-flex">
        <img src={cartData[0].displayImage} alt="" />
        <div className="cart-img">
          <p className="cart-img-value">{cartValue}</p>
        </div>
        <div className="order-cart-text">
          <h6>
            {cartData[0].templeName +
              " (" +
              cartData[0].location[0].address +
              ")"}{" "}
          </h6>
          <div className="d-flex justify-content-between align-items-center">
            <p className="m-0">Prashad only &nbsp;</p>
            <p className="m-0 cart-price" style={{ fontSize: "20px" }}>
              {"RS. " + cartData[0].cost * cartValue + ".00"}
            </p>
          </div>
        </div>
      </div>
    );
    total = (
      <div className="d-flex justify-content-between">
        <span className="sub-total">Total</span>
        <span className="cart-price">
          INR {"RS. " + cartData[0].cost * cartValue + ".00"}
        </span>
      </div>
    );
    subTotal = (
      <div className="d-flex justify-content-between">
        <span className="sub-total mb-2">Sub Total</span>
        <span className="sub-price">
          {"RS. " + cartData[0].cost * cartValue + ".00"}
        </span>
      </div>
    );
  }

  function onClickReturn() {
    navigate(`/prashad/order-summary/${cartId}`);
  }

  return (
    <>
      <section className="cart-hero">
        <div className="container-fluid">
          <div className="text-center">
            <h1 className="cart-head">Cart</h1>
          </div>
          <div className="container">
            <div className="path">
              {cartData !== null ? (
                <p className="path-text">
                  Home / Online Prashad /{" "}
                  {cartData[0].templeName +
                    " (" +
                    cartData[0].location[0].address +
                    ")"}{" "}
                  / Order summary /<span className="path-span"> Cart</span>
                </p>
              ) : (
                <></>
              )}
            </div>
            <div className="cart-container">
              <Grid container>
                <Grid item lg={6}>
                  <div className="contact-information">
                    <h6
                      className="contact-title"
                      // style={{
                      //   display:
                      //     LoginStatus.loggedIn === true ? "none" : "block",
                      // }}
                    >
                      Contact Information
                    </h6>
                    <div className="d-flex flex-column">
                      <label htmlFor="" className="d-block label">
                        Email
                      </label>
                      {error && email.length <= 0 ? (
                        <p className="error-text">*Please enter the Email.</p>
                      ) : (
                        ""
                      )}
                      <input
                        className="input-box"
                        type="text"
                        value={email}
                        placeholder="Email"
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setSubmitted(false);
                        }}
                      />
                    </div>
                    <form onSubmit={handleOnSubmit}>
                      <div
                        className="d-flex checked-text"
                        style={{
                          display:
                            LoginStatus.loggedIn === true ? "none" : "block",
                        }}
                      >
                        <p className="m-0 d-flex align-items-center email-text">
                          <input
                            className="checked-box"
                            type="checkbox"
                            checked={emailChecked}
                            onChange={(e) => {
                              setEmailChecked(e.target.checked);
                            }}
                          />
                          Email me with news and offers
                        </p>
                        <p className="m-0 email-text">
                          Already have an account?{" "}
                          <span>
                            <Link to="/login">Log in</Link>
                          </span>
                        </p>
                      </div>
                      <h6 className="contact-title">Shipping Address</h6>
                      <label htmlFor="" className="d-block label">
                        Country/Region
                      </label>
                      {error && country.length <= 0 ? (
                        <p className="error-text">*Please enter the Country.</p>
                      ) : (
                        ""
                      )}
                      <input
                        className="input-box"
                        type="text"
                        value={country}
                        onChange={(e) => {
                          setCountry(e.target.value);
                          setSubmitted(false);
                        }}
                      />
                      <Grid container spacing={3}>
                        <Grid item sm={6} lg={6}>
                          <label htmlFor="" className="d-block label">
                            First Name
                          </label>
                          {error && firstName.length <= 0 ? (
                            <p className="error-text">
                              *Please enter the FirstName.
                            </p>
                          ) : (
                            ""
                          )}
                          <input
                            className="input-box"
                            type="text"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                        </Grid>
                        <Grid item sm={6} lg={6}>
                          <label htmlFor="" className="d-block label">
                            Last Name
                          </label>
                          <input
                            className="input-box"
                            type="text"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </Grid>
                      </Grid>
                      <label htmlFor="" className="d-block label">
                        Address
                      </label>
                      {error && address.length <= 0 ? (
                        <p className="error-text">*Please enter the Address.</p>
                      ) : (
                        ""
                      )}
                      <input
                        className="input-box"
                        type="text"
                        value={address}
                        onChange={(e) => {
                          setAddress(e.target.value);
                          setSubmitted(false);
                        }}
                      />
                      <label htmlFor="" className="d-block label">
                        Apartment,suite,etc
                      </label>
                      {error && apartment.length <= 0 ? (
                        <p className="error-text">
                          *Please enter the Apartment.
                        </p>
                      ) : (
                        ""
                      )}
                      <input
                        className="input-box"
                        type="text"
                        value={apartment}
                        onChange={(e) => {
                          setApartment(e.target.value);
                          setSubmitted(false);
                        }}
                      />
                      <Grid container spacing={3}>
                        <Grid item sm={4} lg={4}>
                          <label htmlFor="" className="d-block label">
                            City
                          </label>
                          {error && city.length <= 0 ? (
                            <p className="error-text">
                              *Please enter the City.
                            </p>
                          ) : (
                            ""
                          )}
                          <input
                            className="input-box"
                            type="text"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                          />
                        </Grid>
                        <Grid item sm={4} lg={4}>
                          <label htmlFor="" className="d-block label">
                            State
                          </label>
                          <input
                            className="input-box"
                            type="text"
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                          />
                        </Grid>
                        <Grid item sm={4} lg={4}>
                          <label htmlFor="" className="d-block label">
                            Pincode
                          </label>
                          {error && pinCode.length <= 0 ? (
                            <p className="error-text">
                              *Please enter the Pin Code.
                            </p>
                          ) : (
                            ""
                          )}
                          <input
                            className="input-box"
                            type="number"
                            value={pinCode}
                            onChange={(e) => {
                              setPinCode(e.target.value);
                              setSubmitted(false);
                            }}
                          />
                        </Grid>
                      </Grid>
                      <label htmlFor="" className="d-block label">
                        Mobile Number
                      </label>
                      {error && mobileNumber.length <= 0 ? (
                        <p className="error-text">
                          *Please enter the Mobile Number.
                        </p>
                      ) : (
                        ""
                      )}
                      <input
                        className="input-box"
                        type="number"
                        value={mobileNumber}
                        onChange={(e) => {
                          setMobileNumber(e.target.value);
                          setSubmitted(false);
                        }}
                      />
                      <label htmlFor="" className="d-block label">
                        Gotra
                      </label>
                      <input
                        className="input-box"
                        type="text"
                        value={gotra}
                        onChange={(e) => setGotra(e.target.value)}
                      />
                      <p className="m-0 d-flex align-items-center email-text">
                        <input
                          className="checked-box"
                          type="checkbox"
                          checked={emailChecked}
                          onChange={(e) => setEmailChecked(e.target.checked)}
                        />
                        save this information for next time
                      </p>
                      <div className="return-container d-flex">
                        <button
                          className="return-btn"
                          onClick={() => onClickReturn()}
                        >
                          Return To Cart
                        </button>
                        <button
                          className="proceed-payment-btn"
                          onClick={handleOnSubmit}
                        >
                          Proceed To Payment
                        </button>
                      </div>
                    </form>
                  </div>
                </Grid>
                <Grid item lg={6}>
                  <div className="order-cart">
                    {cart}
                    <br />
                    <hr />
                    <br />
                    <div className="cart-code d-flex">
                      <input
                        className="discount-code"
                        placeholder="Discount Code"
                      />
                      <button className="apply">Apply</button>
                    </div>
                    <br />
                    <hr />
                    <br />
                    <div>
                      {subTotal}
                      <div className="d-flex justify-content-between">
                        <span className="sub-total">Delivery And Shipping</span>
                        <span className="sub-calculate">
                          Calculate at Next Step
                        </span>
                      </div>
                    </div>
                    <br />
                    <hr />
                    {total}
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CartPage;
