import React from 'react'
import sy1 from "../Assets/eos-icons_trusted-organization.png";
import sy2 from "../Assets/map_hindu-temple.png";
import sy3 from "../Assets/game-icons_platform.png";
import sy4 from "../Assets/ph_hands-praying-light.png";


function Diffrent() {
  return (
    <>
         <div className="diff">
        <h1>
        What Makes Bharat360 Different
        </h1>

        <div className="sprituality-section">
          
            <div className="trust">
              <img src={sy1} />
              <h3>
                TrusSacred Authenticity
              </h3>
              <p>
              Deep-rooted sacred traditions.
              </p>
            </div>

            <div className="trust">
              <img src={sy2} />
              <h3>
              Seamless Spiritual<br/> Journeys
              </h3>
              <p>
              Effortless Teerth Yatra planning
              </p>
            </div>

            <div className="trust">
              <img src={sy3} />
              <h3>
              Bharat Sikka<br/> Rewards
              </h3>
              <p>
              Earn blessings with every<br/> interaction
              </p>
            </div>
            <div className="trust">
              <img src={sy4} />
              <h3>
                Spirituality
                <br /> Redefined
              </h3>
              <p>
                Connect deeply with the divine
                <br /> through our platform
              </p>
            </div>
       
        </div>
      </div>
    </>
  )
}

export default Diffrent