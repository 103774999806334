import React, { useContext, useEffect, useState } from "react";
import "./prashad-detail.css";
import Original from "../../assets/prashad-img-gallery/image 41.png";
import Thumbnail from "../../assets/prashad-img-gallery/image 58.png";
import ImageGallery from "react-image-gallery";
import { Box, Button, Grid, Rating, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Discription from "./Discription";
import { SinglePrashadApi } from "../../api/ProjectsAPI";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PrashadBookingContext from "../../context/prashadBooking/PrashadBooking";
import CartContext from "../../context/cartContaxt/CartContext";
import LoginContext from "../../context/login/LoginContext";

function PrashadDetailHero({ prasadID }) {
  const PrashadState = useContext(PrashadBookingContext);
  const LoginStatus = useContext(LoginContext);
  const { loggedIn } = LoginStatus;
  const cartState = useContext(CartContext);

  const { cartValue, setCartValue } = PrashadState;
  const { setCartToast } = cartState;
  const [value, setValue] = useState(2);
  const [tabs, setTabs] = useState("1");
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setTabs(newValue);
  };

  const images = [
    {
      original: Original,
      thumbnail: Thumbnail,
    },
    {
      original: Original,
      thumbnail: Thumbnail,
    },
    {
      original: Original,
      thumbnail: Thumbnail,
    },
    {
      original: Original,
      thumbnail: Thumbnail,
    },
    {
      original: Original,
      thumbnail: Thumbnail,
    },
  ];

  const [requestItem, setRequestItem] = useState(null);
  useEffect(() => {
    SinglePrashadApi.get(prasadID, {}, (response) => {
      setRequestItem(response.data);
    });
  }, []);

  if (requestItem === null) {
    return <></>;
  } else {
  }

  function Minus() {
    if (cartValue <= 1) {
      return;
    } else {
      setCartValue(cartValue - 1);
    }
  }
  function Plush() {
    setCartValue(cartValue + 1);
  }

  async function handleRequest() {
    if (loggedIn === false) {
      navigate("/login");
    }
    if (loggedIn === true) {
      let body = {
        productId: prasadID,
        quantity: cartValue,
      };
      try {
        const res = await axios({
          method: "post",
          url: `https://starfish-app-lyrtb.ondigitalocean.app/api/checkout/prasad/updatecart`,
          data: body,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        setCartToast("Your Order Successfully Added.");
        navigate("/prashad/order-summary/" + prasadID);
      } catch (e) {}
    }
  }
  return (
    <>
      <section className="prashad-detail-hero">
        <div className="container-fluid">
          <div className="text-center">
            <h1>Prashad Delivery</h1>
            <p className="m-0 prashad-text">
              Prasad services are currently live from these select temples. We
              expect all temples to re-start offerings very soon.
            </p>
          </div>
          <div className="container">
            <div className="prashad-head">
              <p>
                Home / Online Prashad /{" "}
                <span>
                  {" "}
                  {requestItem.templeName +
                    " (" +
                    requestItem.location[0].address +
                    ")"}
                </span>
              </p>
            </div>
          </div>
          <div className="prashad-image-gallery">
            <div className="container">
              <Grid
                container
                columnSpacing={4}
                rowSpacing={4}
                justifyContent={"center"}
              >
                <Grid item md={6} lg={6}>
                  <ImageGallery items={images} />
                  <div className="delivery">
                    <h3 className="delivery-title">FREE home delivery!Â</h3>
                    <p className="delivery-text">
                      (Note: It might take a couple of extra days for shipping
                      due to lack of transportation at Bhawan.)
                    </p>
                  </div>
                </Grid>
                <Grid item md={6} lg={6}>
                  <div className="prashad-details-card-text">
                    <h2 className="m-0">
                      {requestItem.templeName +
                        " (" +
                        requestItem.location[0].address +
                        ")"}
                    </h2>
                    <p className="product-code">
                      Product Code : {requestItem.productCode}
                    </p>
                    <Rating
                      name="simple-controlled"
                      value={value}
                      onChange={(event, newValue) => {
                        setValue(newValue);
                      }}
                    />
                    <div className="price">
                      <h4>{"RS. " + requestItem.cost * cartValue + ".00"}</h4>
                    </div>
                    <div className="quantity">
                      <span className="quantity-text">Qty</span>
                      <div className="d-flex py-3">
                        <span onClick={() => Minus()}>
                          <Button
                            variant="text"
                            disabled={cartValue === 1 && true}
                            className="minus-btn quantity-box"
                          >
                            -
                          </Button>
                        </span>
                        <span className="quantity-box">{cartValue}</span>
                        <span onClick={() => Plush()}>
                          <Button
                            variant="text"
                            className="plush-btn quantity-box"
                          >
                            +
                          </Button>
                        </span>
                      </div>
                      {/*  <span>
                        <Button
                          className="add-to-cart"
                          variant="contained"
                          onClick={() => AddCart()}
                        >
                          Add To Cart
                        </Button>
                      </span> */}
                    </div>
                    <div className="prashad-request">
                      <Button
                        className="prashad-request-btn"
                        variant="contained"
                        onClick={() => handleRequest()}
                      >
                        Request Prsahad
                      </Button>
                      <p className="request-with-text">
                        With lots of Blessings get Siddhi Vinayak Temple Prasad
                        !
                      </p>
                      <p className="request-with-lots-text">
                        With lots of blessingsÂ from Siddhi Vinayak get her
                        Prasad too. You will receive a box-full of -Â
                      </p>
                      <ul className="cloth-list">
                        <li>Saffron Cloth</li>
                        <li>Mantra Card</li>
                        <li>Framed Picture of lord Ganesha</li>
                        <li>Holy thread</li>
                        <li>Modak</li>
                      </ul>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <div className="discription">
                <Box sx={{ width: "100%", typography: "body1" }}>
                  <TabContext value={tabs}>
                    <Box>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                      >
                        <Tab
                          className="tabs-btn"
                          label="Discription"
                          value="1"
                        />
                        <Tab
                          className="tabs-btn"
                          label="Specification"
                          value="2"
                        />
                        <Tab className="tabs-btn" label="Reviews" value="3" />
                        <Tab className="tabs-btn" label="Feedback" value="4" />
                        <Tab className="tabs-btn" label="Q&A" value="5" />
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <Discription />
                    </TabPanel>
                    <TabPanel value="2">Item Two</TabPanel>
                    <TabPanel value="3">Item Three</TabPanel>
                    <TabPanel value="4">Item Three</TabPanel>
                    <TabPanel value="5">Item Three</TabPanel>
                  </TabContext>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PrashadDetailHero;
