import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../header/Navbar'
import Footer from '../footer/footer'
import img from '../Assets/Group 18.png'
import img1 from '../Assets/Screenshot 2023-09-08 104013.png'
import img3 from '../Assets/Rectangle 2224.png'
import JoinUs from '../BannerSection/JionUs'
import laddu from '../Assets/Laddus (1).png'
import laddu2 from '../Assets/Laddus (2).png'
import laddu3 from '../Assets/Laddus (3).png'
import laddu4 from '../Assets/Laddus (4).png'

function PrashadListDetails() {
    const { id } = useParams();
  const [product, setProduct] = useState(null);

  useEffect(() => {
    console.log('Fetching product details for ID:', id);
    
    fetch(`https://bharat-360-app-vzet3.ondigitalocean.app/api/prasad/getprasad/${id}`)
      .then((response) => response.json())
      .then((data) => {
        console.log('Fetched product data:', data);
        setProduct(data.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [id]);

  if (!product) {
    console.log('Product is still loading...');
    return <div>Loading...</div>;
  }
  return (
   <div style={{backgroundColor:'#FFFFF0'}}>
    <Navbar/>
    <div className='TeerthYatraPackage-section'>
    <div className='TeerthYatraPackage-section-left'>
    <img src={product.displayImage}/>

    </div>
    <div className='TeerthYatraPackage-section-right'>
    <div>
    <h1>{product.templeName}</h1>
    <h3>₹{product.cost} per person*<br/>
    <span> *Excluding applicable taxes</span></h3>
    <hr/>
    <p>DESCRIPTION</p>
    <h4>Embark on a soul-enriching journey through the majestic<br/>
     Himalayan region. The Himalayan Bliss Yatra is a 12-day<br/>
      pilgrimage that takes you to some of the most revered <br/>
      spiritual destinations nestled amidst the pristine beauty of the<br/>
       Himalayas.</h4>
    <div className='PackageType'>
        <p>Package Type</p>
        <h2>Spiritual Pilgrimage</h2>
    </div>
    <div className='PackageType'>
        <p>Duration</p>
        <h2>12 days</h2>
    </div>
    <div className='PackageType'>
        <p>Package Type</p>
        <h2>Spiritual Pilgrimage</h2>
    </div>

    <p style={{fontSize:'16px'}}>Persons</p>
    <button className="qty-btn">- 1 +</button>
    <br/>
    <p style={{fontSize:'16px'}}>INCLUDES</p>
    <img style={{height:'30px' ,width:'100px'}} src={img1}/><br/>
    <button className='book'>Book</button>

    </div>

   </div>

    </div>
    <br/>
    <br/>
    <div className='TeerthPackages' id='TeerthPackagess'>
    <h1 style={{textAlign:'center' , fontSize:'32px'}}>You May Also Like</h1>
    <br/>
    <br/>

       
       <div className='scroll-container'>
     
       <div className='TeerthPackages-cards'>
       <div className='TeerthPackages-card'>
       <div className='TeerthPackages-card-img'>
       <img src={laddu}/>

       </div>
       <h3>Maha Prashad from Puri Jagannath Temple</h3>

       <p>Vorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</p>
        <h4>₹499 per package</h4>
        <button>Order now</button>

       </div>
       <div className='TeerthPackages-card'>
       <div className='TeerthPackages-card-img'>
       <img src={laddu2}/>

       </div>
       <h3>Tirupati Laddu Prashad</h3>

       <p>Vorem ipsum dolor sit amet, <br/>
       consectetur adipiscing elit. Nunc<br/>
        vulputate libero et velit interdum, <br/>
        ac aliquet odio mattis.</p>
        <h4>₹299 per package</h4>
        <button>Order now</button>

       </div>
       <div className='TeerthPackages-card'>
       <div className='TeerthPackages-card-img'>
       <img src={laddu3}/>

       </div>
       <h3>Vaishno Devi Pindi Prashad</h3>

       <p>Vorem ipsum dolor sit amet, <br/>
       consectetur adipiscing elit. Nunc<br/>
        vulputate libero et velit interdum, <br/>
        ac aliquet odio mattis.</p>
        <h4>₹199 per package</h4>
        <button>Order now</button>

       </div>
       <div className='TeerthPackages-card'>
       <div className='TeerthPackages-card-img'>
       <img src={laddu4}/>

       </div>
       <h3>Amritsar Golden Temple Karah Parshad</h3>

       <p>Vorem ipsum dolor sit amet, <br/>
       consectetur adipiscing elit. Nunc<br/>
        vulputate libero et velit interdum, <br/>
        ac aliquet odio mattis.</p>
        <h4>₹149 per package</h4>
        <button>Order now</button>

       </div>

       </div>
       </div>



       {/* <button className='vi-btn' style={{marginTop:'30px'}}>View all</button> */}

       </div>
       <br/>
       <br/>
       <br/>

<JoinUs/>

    <Footer/>

   </div>
  )
}

export default PrashadListDetails