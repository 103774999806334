export const BASE_URL = "https://starfish-app-lyrtb.ondigitalocean.app";
export const IMAGE_BASE = BASE_URL + "/static/";
let getHeader = () => {
  var headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer " + (localStorage.getItem("token")));
  return headers;
  return { name: "ljlkj" };
};

export const API = {
  get: (url, callBack, payload) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    let newUrl = url;
    for (const key in payload) {
      newUrl = url + "?";
      if (Object.hasOwnProperty.call(payload, key)) {
        const element = key;
        newUrl = newUrl + key + "=" + payload[key];
      }
    }
    fetch(BASE_URL + newUrl, requestOptions)
      .then((response) => response.text())
      .then((result) => callBack(JSON.parse(result)))
      .catch((error) => console.log("error", error));
  },

  queryGet: (url, callBack, payload) => {
    let headers =  getHeader();
    const queryString = Object.entries(payload)
      .filter(([_, value]) => value !== null) // [ [ 'pageNum', 3 ], [ 'perPageNum', 10 ], [ 'category', 'food' ] ]
      .map(([key, value], index) => `${key}=${value}`) // [ 'pageNum=3', 'perPageNum=10', 'category=food' ]
      .join("&"); // 'pageNum=3&perPageNum=10&category=food'
    var requestOptions = {
      method: "GET",
      headers,
      redirect: "follow",
    };
    let newUrl;
    if(queryString.length === 0 ){
       newUrl = url
    } else {
      newUrl = url + "?" + queryString;
    }
    fetch(BASE_URL + newUrl, requestOptions)
      .then((response) => response.text())
      .then((result) => callBack(JSON.parse(result)))
      .catch((error) => console.log("error", error));
  },

  formPost: (url, payload, callBack) => {
    console.log(payload, "<< this is payload");
    var requestOptions = {
      method: "POST",
      body: payload,
      redirect: "follow",
    };

    fetch(BASE_URL + url, requestOptions)
      .then((response) => response.text())
      .then((result) => callBack(JSON.parse(result)))
      .catch((error) => console.log("error", error));
  },

  post: async (url, payload, callBack) => {
    // var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    let headers = await getHeader();
    console.log(headers, "<<<<thisismyheader");
    var raw = JSON.stringify(payload);

    var requestOptions = {
      method: "POST",
      headers,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + url, requestOptions)
      .then((response) => response.text())
      .then((result) => callBack(JSON.parse(result)))
      .catch((error) => console.log("error", error));
  },
  update: (url, payload, callBack) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(payload);

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + url, requestOptions)
      .then((response) => response.text())
      .then((result) => callBack(JSON.parse(result)))
      .catch((error) => console.log("error", error));
  },

  delete: (url, callBack) => {
    var requestOptions = {
      method: "DELETE",
      redirect: "follow",
    };

    fetch(BASE_URL + url, requestOptions)
      .then((response) => response.text())
      .then((result) => callBack(JSON.parse(result)))
      .catch((error) => console.log("error", error));
  },
};
