import React, { useEffect, useState } from "react";
import "./select-page.css";
import Navbar from "../header/Navbar";
import Footer from "../footer/footer";
import { Grid } from "@mui/material";
import SelectPanditCard from "./SelectPanditCard";
import { AllPanditAPI } from "../../api/ProjectsAPI";

function SelectPage() {
  const [allPandit, setAllPandit] = useState([])
  const [cityLocation, setCityLocation] = useState("")
  const [allCity, setAllCity] = useState([])

  const cities = [{"City Name": "Indore"}, {"City Name": "Mumbai"}, {"City Name": "Delhi"}, {"City Name":"Surat"}, {"City Name":"Rajkot"}]

  useEffect(() => {
    AllPanditAPI.get({}, (response) => {
      setAllPandit(response.data)
      setAllCity(cities)
  })  
  }, [])

  

  const AllPandits = allPandit.map((element, index) => {
    return(
      <Grid item lg={3} key={index}>
      <SelectPanditCard panditName={element.name} exp={element.exp} panditImg={element.image} address={element.location} panditId={element._id} />
    </Grid>
    )
  })

  return (
    <>
      <Navbar />
      <section className="diwali-detail-hero">
        <div className="container-fluid">
          <div className="text-center">
            <h1>Choose a Pandit </h1>
          </div>
          <div className="container">
            <div className="diwali-head">
              <p>
                Home / Pandits /<span> Diwali Puja</span>
              </p>
            </div>
            <div className="select-city text-center">
              <select name="city" value={cityLocation} onChange={(e) => setCityLocation(e.target.value)}>
                {allCity.map((element, index) => {
                  return(
                    <>
                <option value={element[index]} key={index}>{element["City Name"]}</option>
                    </>
                  )
                })}
              </select>
            </div>
          </div>
          <div className="container">
            <div className="select-pandit-title">
              <span>Select Pandit</span>
            </div>
            <div className="select-pandit-container">
              <Grid container columnSpacing={{md: 4, lg: 0, sm: 4}} justifyContent={"center"}>
               {AllPandits}
                {/* <Grid item lg={3}>
                  <SelectPanditCard />
                </Grid>
                <Grid item lg={3}>
                  <SelectPanditCard />
                </Grid>
                <Grid item lg={3}>
                  <SelectPanditCard />
                </Grid>
                <Grid item lg={3}>
                  <SelectPanditCard />
                </Grid>
                <Grid item lg={3}>
                  <SelectPanditCard />
                </Grid>
                <Grid item lg={3}>
                  <SelectPanditCard />
                </Grid>
                <Grid item lg={3}>
                  <SelectPanditCard />
                </Grid> */}
              </Grid>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default SelectPage;
