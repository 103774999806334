import React from 'react'
import img from '../Assets/Group 1911.png'

function Blessings() {
  return (
    <>
        <div className='Blessings'>
        <div className='Guidance'>
        <div className='Guidance-right'>
 <img src={img}/>

</div>
        <div className='Guidance-left'>
        <h1>EXPERT <span>GUIDANCE</span>FOR SPIRITUAL RITUALS </h1>
        <p>Ensure your spiritual practices are conducted with authenticity and reverence by <br/>
        hiring experienced Pandits. Our Pandit Services offer personalized Poojas, rituals, <br/>
         and ceremonies tailored to your spiritual needs.
</p>
<button>Book a Pandit</button>

</div>


        </div>
        </div>
    </>
  )
}

export default Blessings