import React, { useEffect } from 'react'
import Navbar from '../components/header/Navbar'
import Footer from '../components/footer/footer'
import BlogsHeroSection from '../components/blogs/BlogsHeroSection'

function Blogs() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='full'>
    <Navbar />
    <BlogsHeroSection />
    <Footer />
    </div>
  )
}

export default Blogs