import React, { useContext, useState } from "react";
// import according_img from "../../assets/pandit-img/image 99.png";
import location from "../../assets/pandit-img/Vector (4).png";
import {
  Dialog,
  Grid,
} from "@mui/material";
// import Draggable from "react-draggable";
import modalImage from "../../assets/image 93.png";
import { Link } from "react-router-dom";
import BookingContext from "../../context/booking/bookingContext";
import axios from "axios";

function SelectPanditCard({panditName, exp, panditImg, address, panditId }) {
  const BookingStates = useContext(BookingContext);
  const { pujaName, cityLocation, selectedDate, selectedTime, setSelectPuja, selectPanditName, setPanditName} = BookingStates;
  const pujaList = [ "Dakshina", "All Puja Materrials", "Flowers & Fruits",]
  const [open, setOpen] = useState(false);

  const handleClickOpen = async (id) => {
    try {
      const pandit = await axios({
        method: "get",
        url: `https://starfish-app-lyrtb.ondigitalocean.app/api/pandit/getpandit/`+id,
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      setPanditName(pandit.data.data.name)
    } catch (e) {}
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  
  const listItems = pujaList.map((element, index) =>{
    return(
      <div className="d-flex align-items-center" key={index}>
      <label htmlFor={element}>
      <input type="radio" id={element}  name="puja" value={element} onChange={(e) => setSelectPuja(element)} />
      {element} </label>
    </div>
    )
  }
);
  return (
    <>
      <div className="according-card mb-5">
        <img src={panditImg} alt="according" />
        <div className="according-head">
          <h5>
            {panditName} <span>Exp. 
              {exp} years</span>
          </h5>
          <div className="d-flex align-items-center">
            <img src={location} alt="" />
            <p className="m-0">{address} </p>
          </div>
          <button className="according-book-now" onClick={() => handleClickOpen(panditId)}>
            Select
          </button>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ maxWidth: "100%", maxHeight: "100%" }}
      >
        <div className="select-pandit-modal">
          <Grid container columnSpacing={3}>
            <Grid item lg={6}>
              <img
                src={modalImage}
                style={{ maxWidth: "100%", height: "auto" }}
                alt=""
              />
            </Grid>
            <Grid item lg={6}>
              <div className="select-pandit-title">
                <span>{pujaName}</span>
                <p className="event-city">Event city : {cityLocation}</p>
                <p className="event-price">Rs.1,000</p>
                <p className="event-date">Event Date Time : {selectedDate} &nbsp; {selectedTime}</p>
                <p className="event-date">Select Pandit ji : {selectPanditName}</p>
                <div className="select-pandit">
                  <h6 className="select-pandit-title">Inclusion</h6>
                  {listItems}
                  {/* <div className="d-flex align-items-center">
                    <input type="checkbox" id="dakshina" />
                    <label htmlFor="dakshina">Dakshina</label>
                  </div>
                  <div className="d-flex align-items-center">
                    <input type="checkbox" id="AllPujaMaterrials" />
                    <label htmlFor="AllPujaMaterrials">
                      All Puja Materrials{" "}
                    </label>
                  </div>
                  <div className="d-flex align-items-center">
                    <input type="checkbox" id="flowers" />
                    <label htmlFor="flowers">Flowers & Fruits </label>
                  </div> */}
                  <div className="select-pandit-pandit">
                    <Link to={`/pujas/choose-pandit/book-now/${panditId}`}>
                      <button className="select-pandit-btn">Book Now</button>
                    </Link>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </>
  );
}

export default SelectPanditCard;
