import React from 'react'
import Navbar from '../header/Navbar'
import Footer from './footer'

function TandC() {
  return (
   <div className='full'>
     <Navbar/>
    <div className='ftdt'>Terms and condition</div>
    <Footer/>
   </div>
  )
}

export default TandC