import React from 'react'
import img from '../Assets/Group 48.png'
function OurVission() {
  return (
    <>
        <div className='ourVision'>
        <div className='ourvision-left' id='ourvleft'>

        <img src={img}/>


        </div>
        <div className='ourvision-right'>
<div className='vision-div' id='rvis'>
    
<h1>Our <span>Vision</span></h1>
        <p>Empowering Devotion, Connecting Hearts
Aaradhak's vision is to empower individuals in their spiritual journey by seamlessly connecting devotees and fostering unity among diverse communities. We aim to make Hindu rituals and offerings accessible, convenient, and inclusive while embracing innovation and modernity. Aaradhak is committed to building trust, promoting holistic well-being, and nurturing a global community rooted in devotion, respect, and spiritual growth.</p>
        
</div>

        </div>


        </div>
    </>
  )
}

export default OurVission