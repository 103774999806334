import React, { useEffect } from 'react'
import Navbar from "../components/header/Navbar";
import Footer from "../components/footer/footer";
import OrderPage from '../components/orderPage/OrderPage';
import { useLoaderData } from 'react-router-dom';

export async function loader({ params }) {
  return params;
}

function Order() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const data = useLoaderData();
  return (
    <>
    <Navbar />
    <OrderPage prasadID={data.prasadID} />
    <Footer />
    </>
  )
}

export default Order