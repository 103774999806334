import React, { useEffect } from 'react'
import Navbar from '../components/header/Navbar'
import Footer from '../components/footer/footer'
import DiwaliPujaPage from '../components/diwalipuja/DiwaliDetailPage'
import { useParams } from 'react-router-dom'

function DiwaliPuja() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const params = useParams();
  return (
    <>
    <Navbar />
    <DiwaliPujaPage id={params.bookingId} />
    <Footer />
    </>
  )
}

export default DiwaliPuja