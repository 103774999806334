import React, { useContext, useEffect, useState } from "react";
import "./diwali-detail.css";
import Original from "../../assets/prashad-img-gallery/image 41.png";
import Thumbnail from "../../assets/prashad-img-gallery/image 58.png";
import ImageGallery from "react-image-gallery";
import { Box, Grid, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Link, useParams } from "react-router-dom";
import DiwaliDiscription from "./DiwaliDiscription";
import axios from "axios";
import BookingContext from "../../context/booking/bookingContext";
import moment from "moment";

function DiwaliPujaPage() {
  const BookingStates = useContext(BookingContext);

  const { setPujaName, setSelectedDate, selectedDate, selectedTime, templeId, setTempleId, setSelectedTime, setTempleName, setSelectPuja, setSelectTempId, price, setPrice } = BookingStates;
  const params = useParams();
  const [tabs, setTabs] = useState("1");

  const handleChange = (event, newValue) => {
    setTabs(newValue);
  };
  const images = [
    {
      original: Original,
      thumbnail: Thumbnail,
    },
    {
      original: Original,
      thumbnail: Thumbnail,
    },
    {
      original: Original,
      thumbnail: Thumbnail,
    },
    {
      original: Original,
      thumbnail: Thumbnail,
    },
    {
      original: Original,
      thumbnail: Thumbnail,
    },
  ];
  const pujaList = [ "Dakshina", "All Puja Materrials", "Flowers & Fruits",]
  const [cityLocation, setCityLocation] = useState("");
  const [allCity, setAllCity] = useState([]);
  const [puja, setPuja] = useState([]);
  const [selectDate, setSelectDate] = useState(null);

  const cities = [
    { "City Name": "Indore" },
    { "City Name": "Mumbai" },
    { "City Name": "Delhi" },
    { "City Name": "Surat" },
    { "City Name": "Rajkot" },
  ];

  useEffect(() => {
    async function fetchData() {
      try {
        const pujas = await axios({
          method: "get",
          url:
            `https://starfish-app-lyrtb.ondigitalocean.app/api/puja/getpuja/` +
            params.bookingId,
            headers: {
              authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
        setPuja([pujas.data.data]);
        setPujaName(pujas.data.data.name);
      } catch (e) {}

      try {
        const dates = await axios({
          method: "get",
          url:
            `https://starfish-app-lyrtb.ondigitalocean.app/api/pandit/getpandit`,
          headers: {
            authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        setSelectDate([dates.data.data.availableTimings]);
      } catch (e) {}
    }
    fetchData();
    setAllCity(cities);
  }, []);

  const handleChangeDate = (e) => {
    setSelectedDate(e.target.value);
  };
  const handleChangeTime = (e) => {
    setSelectedTime(e.target.value);
  };

  const listItems = pujaList.map((element, index) =>{
    return(
      <div className="d-flex align-items-center" key={index}>
      <label htmlFor={element}>
      <input type="radio" id={element}  name="puja" value={element} onChange={(e) => setSelectPuja(element)} />
      {element} </label>
    </div>
    )
  }
);

  return (
    <>
      <section className="diwali-detail-hero">
        <div className="container-fluid">
          <div className="text-center">
          {puja && puja.length && (<h1>{puja[0].name}</h1>)}
          </div>
          <div className="container">
            <div className="diwali-head">
              <p>
                Home / Pandits / {puja && puja.length && (<span>{puja[0].name}</span>)}
              </p>
            </div>
            <div className="select-city text-center">
              <select
                name="city"
                value={cityLocation}
                onChange={(e) => setCityLocation(e.target.value)}
              >
                {allCity.map((element, index) => {
                  return (
                    <>
                      <option value={element[index]} key={index}>
                        {element["City Name"]}
                      </option>
                    </>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="prashad-image-gallery">
            <div className="container">
              <Grid
                container
                columnSpacing={4}
                rowSpacing={4}
                justifyContent={"center"}
              >
                <Grid item md={6} lg={6}>
                  <ImageGallery items={images} />
                </Grid>
                <Grid item md={6} lg={6}>
                  <div className="diwali-puja-title">
                  {puja && puja.length && (<span>{puja[0].name}</span>)}
                    <p className="event-city">Event city : Mumbai</p>
                    {puja && puja.length && (<p className="event-price">Rs.{puja[0].cost}</p>)}
                    <div className="d-flex flex-column diwali-label">
                    {selectDate && selectDate.length && (
                        <>
                          <label htmlFor="">Selected Date</label>
                         <input type="text" value={selectedDate} disabled />
                        </>
                      )}
                    </div>
                    <div className="d-flex flex-column diwali-label">
                    {selectDate && selectDate.length && (
                        <>
                          <label htmlFor="">Select Time</label>
                          <input type="text" value={selectedTime} disabled />
                        </>
                      )}
                    </div>
                    <div className="inclusion">
                      <h6 className="inclusion-title">Inclusion</h6>
                      {listItems}
                      {/* <div className="d-flex align-items-center">
                        <label htmlFor={"Dakshina"}>
                        <input type="checkbox" id={"Dakshina"}  name="puja" value={data[0].name} onChange={() => setSelectPuja()} />
                        Dakshina </label>
                      </div>
                      <div className="d-flex align-items-center">
                        <label htmlFor="AllPujaMaterrials">
                        <input type="checkbox" id="AllPujaMaterrials" name="puja" value={data[1].name} onChange={() => setSelectOnePuja(data[1].status)} />
                          All Puja Materrials{" "}
                        </label>
                      </div>
                      <div className="d-flex align-items-center">
                        <label htmlFor="flowers">
                        <input type="checkbox" value={data[1].name} id="flowers" name="puja"  onChange={() => setSelectOnePuja(data[2].status)} />
                          Flowers & Fruits </label>
                      </div> */}
                      <div className="choose-pandit">
                        <Link to="/pujas/choose-pandit">
                          <button className="choose-btn">Choose pandit</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <div className="discription">
                <Box sx={{ width: "100%", typography: "body1" }}>
                  <TabContext value={tabs}>
                    <Box>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                      >
                        <Tab
                          className="tabs-btn"
                          label="Discription"
                          value="1"
                        />
                        <Tab
                          className="tabs-btn"
                          label="Specification"
                          value="2"
                        />
                        <Tab className="tabs-btn" label="Reviews" value="3" />
                        <Tab className="tabs-btn" label="Feedback" value="4" />
                        <Tab className="tabs-btn" label="Q&A" value="5" />
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <DiwaliDiscription />
                    </TabPanel>
                    <TabPanel value="2">Item Two</TabPanel>
                    <TabPanel value="3">Item Three</TabPanel>
                    <TabPanel value="4">Item Three</TabPanel>
                    <TabPanel value="5">Item Three</TabPanel>
                  </TabContext>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default DiwaliPujaPage;
