import React, { useEffect, useState } from "react";
// import { useMain } from "../hooks/useMain";
import OurVission from "./OurVission";
import Different from "../BannerSection/Diffrent";
import img from "../Assets/Rectangle 2311.png";
import img1 from "../Assets/Rectangle 24.svg";
import img3 from "../Assets/Rectangle 1124.png";
import aboutacrd from '../Assets/about cards (1).png'
import aboutacrd2 from '../Assets/about cards (2).png'
import aboutacrd3 from '../Assets/about cards (3).png'
import aboutacrd4 from '../Assets/about cards (4).png'
import aboutacrd5 from '../Assets/about cards (5).png'
import aboutacrd6 from '../Assets/about cards (6).png'



import JionUs from "../BannerSection/JionUs";
import Navbar from "../header/Navbar";
import Footer from "../footer/footer";


const About = () => {


  return (
    <div className="absus">
    <Navbar/>
      <div className="newABOUTUS">
        <div className="about-header">

        </div>
        <div className="ourVision">
        <div className="ourvision-left" style={{ marginBottom: "50px" }}>
            <img src={img} style={{}} />
          </div>
          <div className="ourvision-right-ab">
            <div className="vision-div">
              <h1>
                Essence of <br />
                <span>Divinity</span>
              </h1>
              <p>
                At Aaradhak, we believe that everyone has the potential to
                connect with the divine. We offer a wide range of products and
                services that can help you on your spiritual journey, from
                handcrafted idols and puja essentials to home decor items that
                promote a sense of peace and tranquillity. Our aim is to help
                you rediscover the essence of divinity within yourself. We
                believe that by connecting with the divine, you can live a more
                fulfilling and meaningful life.th.
              </p>
            </div>
          </div>
         
        </div>
        <Different />
        <div className="ourVision">
        <div className="ourvision-left" style={{ marginBottom: "50px" }}>
            <img src={img} style={{}} />
          </div>
          <div className="ourvision-right-ab">
            <div className="vision-div">
              <h1>ESSENCE OF
                <br />
                <span>DIVINITY</span>
              </h1>
              <p>
                At Aaradhak, we believe that everyone has the potential to
                connect with the divine. We offer a wide range of products and
                services that can help you on your spiritual journey, from
                handcrafted idols and puja essentials to home decor items that
                promote a sense of peace and tranquillity. Our aim is to help
                you rediscover the essence of divinity within yourself. We
                believe that by connecting with the divine, you can live a more
                fulfilling and meaningful life.th.
              </p>
            </div>
          </div>
         
        </div>

        <div className="spritiualofferings">
        <h1>Our <span>Spiritual</span> Offerings</h1>
        <p>Embark on sacred pilgrimages to revered destinations across India. Our Teerth Yatras are meticulously designed to provide you with a<br/> profound spiritual experience. Explore the divine essence of each journey, immerse yourself in the spiritual heritage of India, and draw closer to the divine</p>
<div className="scroll-container">
<div className="spritiualofferings-cards">
<div className="spritiualofferings-card">
<img src={aboutacrd2}/>
<h3>Teerth Yatras</h3>
<p>Embark on sacred pilgrimages to revered destinations across India. Our Teerth Yatras are meticulously designed to provide you with a profound spiritual experience.</p>

</div>
<div className="spritiualofferings-card">
<img src={aboutacrd}/>
<h3>Pandit Services</h3>
<p>Ensure your spiritual practices are conducted with authenticity and reverence by hiring experienced Pandits.</p>

</div>
<div className="spritiualofferings-card">
<img src={aboutacrd6}/>
<h3>Prashad Delivery</h3>
<p>Receive divine blessings at your doorstep with our Prashad Delivery service. We bring you the sacred Prashad from revered temples, allowing you to savor the essence of devotion in every bite</p>

</div>
</div>

</div>

<br/>
<br/>

<div className="scroll-container">
<div className="spritiualofferings-cards">
<div className="spritiualofferings-card">
<img src={aboutacrd3}/>
<h3>Satsang and Spiritual Gatherings</h3>
<p>Connect with like-minded seekers through Satsangs and spiritual gatherings. These gatherings provide a platform to share your spiritual journey, deepen your understanding of the divine, and find community among fellow seekers.</p>

</div>
<div className="spritiualofferings-card">
<img src={aboutacrd4}/>
<h3>Live Darshan</h3>
<p>Witness real-time divine moments with our Live Darshan feature. Tune in to live streams from temples and holy places across India, allowing you to experience the presence of the divine from the comfort of your own space.</p>

</div>
<div className="spritiualofferings-card">
<img src={aboutacrd5}/>
<h3>Bharat Sikka Rewards</h3>
<p>Participate in our loyalty program, Bharat Sikka, and earn reward points with every interaction on our platform. Accumulate points and redeem them for exclusive spiritual experiences and blessings.</p>

</div>
</div>

</div>
        </div>
<br/>
<br/>
<br/>

   
    
        <JionUs />
        <Footer/>
      </div>
    </div>
  );
};

export default About;
