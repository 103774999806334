import React, { useEffect } from 'react'
import Navbar from '../components/header/Navbar'
import Footer from '../components/footer/footer'
import Booking from '../components/bookingpage/BookingPage'

function BookingPage() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
    <Navbar />
    <Booking />
    <Footer />
    </>
  )
}

export default BookingPage