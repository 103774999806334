import React, { useEffect, useState } from 'react';
import img from '../Assets/Group 191.png'
import './blog.css'
import { Link } from 'react-router-dom';

function BlogsHeroSection() {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch('https://bharat-360-app-vzet3.ondigitalocean.app/api/blog/getallblogs');
        const data = await response.json();
        
        if (data.message === "Successfully fetched all the blogs") {
          setBlogs(data.data);
        } else {
          console.error('Error fetching blogs:', data.message);
        }
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };

    fetchBlogs();
  }, []);
  return (
   <div className='full'>
  <div className='newblog'>
  <div className='newBlogcards'>
    <div className='row'>

      {blogs.map(blog => (
        <div className='col-md-6' key={blog._id}>
          <Link className='linksss' to={`/blogsdetail/${blog._id}`}>

            <div className='newblofgimg'>
              <img src={blog.image} alt={blog.title} />
            </div>
            <div className='BlogTEXT'>
              <h2>{blog.title}</h2>
              <p dangerouslySetInnerHTML={{ __html: blog.content }} />
              {/* <p>Created At: {blog.createdAt}</p>
              <p>Updated At: {blog.updatedAt}</p> */}
            </div>
          
          </Link>
        </div>
      ))}
      
    </div>
  </div>
</div>
   </div>
  )
}

export default BlogsHeroSection