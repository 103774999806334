import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Navbar from '../header/Navbar';
import img from '../Assets/profile-picture.png';
import Joinus from '../BannerSection/JionUs';
import Footer from '../footer/footer';
import logo from "../Assets/bharat 360 primary logo prototype A 2.png";
import like from "../Assets/solar_heart-outline.png";
import cart from "../Assets/Shopping--cart.png";
import profile from "../Assets/Vector.png";

import search from "../Assets/search.svg";

import {Modal , ModalBody, ModalHeader} from "reactstrap";


function NewProfile() {
  const [modal ,setModal] = useState(false)
  let location = useLocation();
  const [path, setPath] = useState("/");
  // const navigate = useNavigate();

  useEffect(() => {
    setPath(location.pathname);
  }, [location]);
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Fetch data from the API
    fetch('https://bharat-360-app-vzet3.ondigitalocean.app/api/auth/login')
      .then((response) => response.json())
      .then((data) => {
        setUserData(data.data.other);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data: ', error);
        setIsLoading(false);
      });
  }, []);

  return (
    <div className='Npro'>
      <Navbar />
      <div className='NPHeading'></div>
      <div className='Profile'>
        <div className='profile-list '>
          <div className='profile-list-top'>
            <ul className='scrolprofile'>
              <li class="active"><Link className='prolink' to='/profiles'>PROFILE</Link></li>

              <li><Link className='prolink' to='/profilestwo'>ORDERS</Link></li>
              <li>SAVE ADDRESS</li>
              <li>FAQ AND HELP</li>
              <li>LOGOUT</li>
            </ul>
          </div>
         
        </div>
        <div className='profile-mainprofile'>
          <img src={img} alt='Profile Picture' onClick={()=>setModal(true)} />
          <Modal
   size='lg'
    isOpen={modal}
    toggle={()=> setModal(!modal)}
    >
<div className="EditProfile">
<div className='pro-name'>
            <div className='pro-nameIP'>
              <label>First Name</label>
              <input value={isLoading ? 'Loading...' : userData.displayName} readOnly />
            </div>
            <div className='pro-nameIP'>
              <label>Email</label>
              <input value={isLoading ? 'Loading...' : userData.email} readOnly />
            </div>
          </div>
          <div className='pro-No'>
            <div className='pro-noIP'>
              <label>Phone Number</label>
              <div className='no'>
                <input className='code' readOnly />
                <input className='mobno' value={isLoading ? 'Loading...' : userData.phone} readOnly />
              </div>
            </div>
          </div>
          <div className='pro-DOB'>
            <div className='pro-DOBIP'>
              <label>Date of Birth</label>
              <input value={isLoading ? 'Loading...' : userData.dateOfBirth} readOnly />
            </div>
          </div>
          <h1 className='abcd'>CHANGE PASSWORD</h1>
          <br />
          <div className='CHNG-PASS'>
            <div className='changePass'>
              <label>Current Password</label>
              <input type='password' />
            </div>
          </div>
          <div className='CHNG-PASS'>
            <div className='changePass'>
              <label>New Password</label>
              <input type='password' />
            </div>
          </div>
          <div className='CHNG-PASS'>
            <div className='changePass'>
              <label>Confirm Password</label>
              <input type='password' />
            </div>
          </div>
          <button className='profileSave'>Save</button>

</div>

   

    </Modal>
          <br />
          <br />
          <div className='pro-name'>
            <div className='pro-nameIP'>
              <label>First Name</label>
              <input value={isLoading ? 'Loading...' : userData.displayName} readOnly />
            </div>
            <div className='pro-nameIP'>
              <label>Email</label>
              <input value={isLoading ? 'Loading...' : userData.email} readOnly />
            </div>
          </div>
          <div className='pro-No'>
            <div className='pro-noIP'>
              <label>Phone Number</label>
              <div className='no'>
                <input className='code' readOnly />
                <input className='mobno' value={isLoading ? 'Loading...' : userData.phone} readOnly />
              </div>
            </div>
          </div>
          <div className='pro-DOB'>
            <div className='pro-DOBIP'>
              <label>Date of Birth</label>
              <input value={isLoading ? 'Loading...' : userData.dateOfBirth} readOnly />
            </div>
          </div>
          <h1 className='abcd'>CHANGE PASSWORD</h1>
          <br />
          <div className='CHNG-PASS'>
            <div className='changePass'>
              <label>Current Password</label>
              <input type='password' />
            </div>
          </div>
          <div className='CHNG-PASS'>
            <div className='changePass'>
              <label>New Password</label>
              <input type='password' />
            </div>
          </div>
          <div className='CHNG-PASS'>
            <div className='changePass'>
              <label>Confirm Password</label>
              <input type='password' />
            </div>
          </div>
          <button className='profileSave'>Save</button>
          <h1 className='abcd' style={{ marginTop: '60px' }}>Save Travellers </h1>
          <br />
          <input className='Travellers' />
        </div>
      </div>
      <br />
      <br />
      <br />
      <Joinus />
      <Footer />
    </div>
  );
}

export default NewProfile;
