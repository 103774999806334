import React from 'react'
import Navbar from './components/header/Navbar'
import Footer from './components/footer/footer'
import img from './components/Assets/adk.png'
import img2 from './components/Assets/Group 20.png'

import aaradhak from './components/Assets/aradhak logo-06.png'
import Diffrent from './components/BannerSection/Diffrent'
import Offering from './components/BannerSection/Offering'
import JionUs from './components/BannerSection/JionUs'

function Aaradhak() {
  return (
    <div className='full'>
        <Navbar/>
        <div className="ourVision">
      
          <div className="ourvision-right-ab">
            <div className="vision-div">
            <img className='aaradhakLogo' style={{marginLeft:'-20px'}} src={aaradhak}/>
            <br/>
            <br/>

         
              <h1>
                Essence of <br />
                <span>Divinity</span>
              </h1>
              <p>
                At Aaradhak, we believe that everyone has the potential to
                connect with the divine. We offer a wide range of products and
                services that can help you on your spiritual journey, from
                handcrafted idols and puja essentials to home decor items that
                promote a sense of peace and tranquillity. Our aim is to help
                you rediscover the essence of divinity within yourself. We
                believe that by connecting with the divine, you can live a more
                fulfilling and meaningful life.th.
              </p>
            </div>
          </div>
          <div className="ourvision-left" style={{ marginBottom: "50px" }}>
            <img className='aaradhak-imgs' src={img}/>
          </div>
         
        </div>
        <div className="ourVision">
        <div className="ourvision-left" style={{ marginBottom: "50px" }}>
        <img className='aaradhak-imgs'  src={img2}/>
      </div>
      <div className="ourvision-right-ab">
        <div className="vision-div">
          <h1>
            Essence of <br />
            <span>Divinity</span>
          </h1>
          <p>
            At Aaradhak, we believe that everyone has the potential to
            connect with the divine. We offer a wide range of products and
            services that can help you on your spiritual journey, from
            handcrafted idols and puja essentials to home decor items that
            promote a sense of peace and tranquillity. Our aim is to help
            you rediscover the essence of divinity within yourself. We
            believe that by connecting with the divine, you can live a more
            fulfilling and meaningful life.th.
          </p>
        </div>
      </div>
     
     
    </div>
<br/>
        <Diffrent/>
        <div className="ourVision">
        <div className="ourvision-left" style={{ marginBottom: "50px" }}>
        <img className='aaradhak-imgs'  src={img}/>
      </div>
      <div className="ourvision-right-ab">
        <div className="vision-div">
          <h1>
            Essence of <br />
            <span>Divinity</span>
          </h1>
          <p>
            At Aaradhak, we believe that everyone has the potential to
            connect with the divine. We offer a wide range of products and
            services that can help you on your spiritual journey, from
            handcrafted idols and puja essentials to home decor items that
            promote a sense of peace and tranquillity. Our aim is to help
            you rediscover the essence of divinity within yourself. We
            believe that by connecting with the divine, you can live a more
            fulfilling and meaningful life.th.
          </p>
        </div>
      </div>
     
     
    </div>
<br/>
<br/>
<br/>

  

    <JionUs/>
        <Footer/>
    </div>
  )
}

export default Aaradhak