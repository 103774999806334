const { API } = require("./BaseCall");

/* Create User API */

const CreateUser = {
  get: (payload, callBack) => API.queryGet("/api/user/getuser", callBack, payload,),
  search: (payload, callBack) => API.post("/api/auth/register", payload, callBack),
  postUser: (payload, callBack) =>
  API.post("/api/auth/register", payload, callBack),
  deleteProject: (id, callBack) =>
  API.delete("/project/delete/" + id, callBack),
  sendReview: (payload, callBack) =>
  API.post("/review/create", payload, callBack),
};




/* Login User API */


const LoginUser = {
  get: (payload, callBack) => API.queryGet("/api/auth/login", callBack, payload),
  // search: (payload, callBack) => API.post("/api/auth/register", payload, callBack),
  postUser: (payload, callBack) =>
  API.post("/api/auth/login", payload, callBack),
  deleteProject: (id, callBack) =>
  API.delete("/project/delete/" + id, callBack),
  sendReview: (payload, callBack) =>
  API.post("/review/create", payload, callBack),
};




/* Prashad API */

const PrashadApi = {
  get: (payload, callBack) => API.queryGet("/api/prasad/getallprasad", callBack, payload),
  getId: (payload, callBack) => API.queryGet("/api/prasad/getallprasad/", callBack, payload),
  search: (payload, callBack) => API.post("/project/search", payload, callBack),
  postProject: (payload, callBack) =>
    API.post("/project/create", payload, callBack),
  deleteProject: (id, callBack) =>
    API.delete("/project/delete/" + id, callBack),
  sendReview: (payload, callBack) =>
    API.post("/review/create", payload, callBack),
};




/* Blog API */

const BlogAPI = {
  get: (payload, callBack) => API.queryGet("/api/blog/getallblogs", callBack, payload),
  getId: (payload, callBack) => API.queryGet("/api/prasad/getallprasad/", callBack, payload),
  search: (payload, callBack) => API.post("/project/search", payload, callBack),
  postProject: (payload, callBack) =>
  API.post("/project/create", payload, callBack),
  deleteProject: (id, callBack) =>
  API.delete("/project/delete/" + id, callBack),
  sendReview: (payload, callBack) =>
  API.post("/review/create", payload, callBack),
};


/* Cart API */



const CartAPI = {
  get: (payload, callBack) => API.queryGet("/api/checkout/prasad/prasadhistory", callBack, payload),
  search: (payload, callBack) => API.post("/project/search", payload, callBack),
  postCart: ( payload, callBack) =>
    API.post("/api/checkout/prasad/updatecart", payload, callBack),
    deleteProject: (id, callBack) =>
    API.delete("/project/delete/" + id, callBack),
    sendReview: (payload, callBack) =>
    API.post("/review/create", payload, callBack),
};



const SinglePrashadApi = {
  get: (prasadId, payload, callBack) => API.queryGet("/api/prasad/getprasad/" + prasadId, callBack, payload),
  postCart: (prasadId, payload, callBack) =>
  API.post("/api/prasad/getprasad/"+ prasadId, payload, callBack),
};



/* Blog API */

const AllPanditAPI = {
  get: (payload, callBack) => API.queryGet("/api/pandit/getallpandit", callBack, payload),
  getId: (payload, callBack) => API.queryGet("/api/prasad/getallprasad/", callBack, payload),
  search: (payload, callBack) => API.post("/project/search", payload, callBack),
  postProject: (payload, callBack) =>
  API.post("/project/create", payload, callBack),
  deleteProject: (id, callBack) =>
  API.delete("/project/delete/" + id, callBack),
  sendReview: (payload, callBack) =>
  API.post("/review/create", payload, callBack),
};


const SelectPujaAPI = {
  get: (basedName, payload, callBack) => API.queryGet("/api/puja/getbasedpuja/" + basedName, callBack, payload),
  search: (payload, callBack) => API.post("/project/search", payload, callBack),
  postProject: (payload, callBack) =>
  API.post("/project/create", payload, callBack),
  deleteProject: (id, callBack) =>
  API.delete("/project/delete/" + id, callBack),
  sendReview: (payload, callBack) =>
  API.post("/review/create", payload, callBack),
};
const PanditCheckout = {
  postProject: (payload, callBack) =>
  API.post("/api/checkout/puja/createhistory", payload, callBack),
  deleteProject: (id, callBack) =>
  API.delete("/project/delete/" + id, callBack),
  sendReview: (payload, callBack) =>
  API.post("/review/create", payload, callBack),
};




module.exports = { BlogAPI, PrashadApi, LoginUser, CreateUser, CartAPI, SinglePrashadApi, AllPanditAPI, SelectPujaAPI, PanditCheckout };