import React, { useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./nav.css";
import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import logo from "../Assets/bharat 360 primary logo prototype A 2.png";
import { Link, useLocation } from "react-router-dom";
// import like from "../Assets/solar_heart-outline.png";
// import cart from "../Assets/Shopping--cart.png";
// import profile from "../Assets/Vector.png";
import navLogo from "../Assets/bharat 360 primary logo prototype A 2.png";
import './nav.css'
import {Modal , ModalBody, ModalHeader} from "reactstrap";
import img from '../Assets/Rectangle 401.png';




function Topnav() {
  
  // const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState('');
  const [modal ,setModal] = useState(false)

  const [signmodal ,setSignmodal] = useState(false)
  let location = useLocation();
  const [path, setPath] = useState("/");
  // const navigate = useNavigate();


  const handleSignUp = () => {
    // Your sign-up logic goes here

    // Close the sign-up modal and reset the form
    setSignmodal(false);
    setEmail('');
    setPassword('');
    setError(false);
    setSubmitted(false);
  };

  useEffect(() => {
    setPath(location.pathname);
  }, [location]);


  const navigate = useNavigate(); // Get the navigate function

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    // Validate form inputs
    if (!email || !password) {
      setError(true);
      return;
    }

    // Assuming you have a login API endpoint
    try {
      const response = await fetch('https://bharat-360-app-vzet3.ondigitalocean.app/api/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      });

      if (response.ok) {
        // Login successful
        const data = await response.json();
        // Store user token or data if needed
        // Redirect to the dashboard or wherever you want using navigate
        navigate('/'); // Use navigate here
      } else {
        // Login failed
        setError(true);
      }
    } catch (error) {
      console.error('Login error:', error);
      setError(true);
    }
  };
  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);

    // Perform navigation based on the selected option
    switch (selectedValue) {
      case '/pandit':
        navigate('/pandit'); // Use navigate instead of history.push
        break;
      case '/prashad':
        navigate('/prashad');
        break;
      case '/shop':
        navigate('/shop');
        break;

      case '/teerthhome':
        navigate('/teerthhome');
        break;
        case '/bharatsikka':
          navigate('/bharatsikka');
          break;
      default:
        // Handle the default case
        break;
    }
  };
  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };
  const pathName = useLocation();

  return (
    <header>
      <i class="bi bi-person" onClick={()=>setModal(true)}></i>
      <Modal
   size='lg'
    isOpen={modal}
    toggle={()=> setModal(!modal)}
    >

    {/* <ModalHeader className="ModalHeader"
    toggle={()=> setModal(!modal)}>
 <p className='Runwithus'>Confirm Your Pandit Booking</p>

    </ModalHeader> */}
    <div className="container">
    <ModalBody className='modelbody'>

    {/* <div className="modelbody-left">
    <img src={img}/>

    </div>

    
    <div className="modelbody-right">
    <div>
    <h1>Himalayan Bliss Yatra</h1>
    <h3>₹30,053 per person*<br/>
    <span> *Excluding applicable taxes</span></h3>
    <hr/>
    
    <div className='PackageTypess'>
        <p>Package Type</p>
        <h2>Spiritual Pilgrimage</h2>
    </div>
    <div className='PackageTypess'>
        <p>Duration</p>
        <h2>12 days</h2>
    </div>
    <div className='PackageTypess'>
        <p>Package Type</p>
        <h2>Spiritual Pilgrimage</h2>
    </div>

    
    <button className='book'>Book</button>
    <button className='book-cancel'>Cancel</button>


    </div>

    </div> */}
    <div className="NewLoginPage">
      <div className="NewLoginPage-left">
        <img src={img} alt="Profile" />
      </div>
      <div className="NewLoginPage-right">
        <img src={logo} alt="Logo" />
        <h1>SIGN IN</h1>
        <p>Join Us in the spiritual journey</p>
        <label>E-mail or phone number</label>
        {error && email.length <= 0 && submitted ? (
          <p className="error-text">*Please enter the Email.</p>
        ) : null}
        <input
          className="logInput"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setError(false);
          }}
          type="email"
          name="email"
          placeholder="Enter your Email"
        />
        <label>Password</label>
        {error && password.length <= 0 && submitted ? (
          <p className="error-text">*Please enter the Password.</p>
        ) : null}
        <input
          className="logInput"
          name="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            setError(false);
          }}
          type="password"
          placeholder="Enter your Password"
        />
        <div className="checks">
          <input type="checkbox" />
          <p>I agree to all terms and conditions</p>
        </div>
        <button onClick={(e) => handleOnSubmit(e)}>Login</button>
        <h3 className="Forgot-Password">Forgot Password?</h3>
        {/* <div className="socialbtnss">
          <button className="authbtn">
            <img src={google} alt="Google" /> Sign up with Google
          </button>
          <br/>
          <button className="authbtn">
            <img src={fb} alt="Facebook" /> Sign up with Facebook
          </button>
        </div> */}
        <h4 className="dnthvacc">
          Don’t have an account? <span><Link onClick={()=>setSignmodal(true)}>Signup</Link></span>
        </h4>
        
      </div>
    </div>
   

   </ModalBody>

   <Modal
   size='lg'
    isOpen={signmodal}
    toggle={() => {
          setSignmodal(!signmodal);
          setModal(false);}}
    >

    {/* <ModalHeader className="ModalHeader"
    toggle={()=> setModal(!modal)}>
 <p className='Runwithus'>Confirm Your Pandit Booking</p>

    </ModalHeader> */}
    <div className="container">
    <ModalBody className='modelbody'>

    {/* <div className="modelbody-left">
    <img src={img}/>

    </div>

    
    <div className="modelbody-right">
    <div>
    <h1>Himalayan Bliss Yatra</h1>
    <h3>₹30,053 per person*<br/>
    <span> *Excluding applicable taxes</span></h3>
    <hr/>
    
    <div className='PackageTypess'>
        <p>Package Type</p>
        <h2>Spiritual Pilgrimage</h2>
    </div>
    <div className='PackageTypess'>
        <p>Duration</p>
        <h2>12 days</h2>
    </div>
    <div className='PackageTypess'>
        <p>Package Type</p>
        <h2>Spiritual Pilgrimage</h2>
    </div>

    
    <button className='book'>Book</button>
    <button className='book-cancel'>Cancel</button>


    </div>

    </div> */}
    <div className="NewLoginPage">
      <div className="NewLoginPage-left">
        <img src={img} alt="Profile" />
      </div>
      <div className="NewLoginPage-right">
        <img src={logo} alt="Logo" />
        <h1>SIGN UP</h1>
        <p>Join Us in the spiritual journey</p>
        <label>E-mail or phone number</label>
        {error && email.length <= 0 && submitted ? (
          <p className="error-text">*Please enter the Email.</p>
        ) : null}
        <input
          className="logInput"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setError(false);
          }}
          type="email"
          name="email"
          placeholder="Enter your Email"
        />
        <label>E-mail or phone number</label>
        {error && email.length <= 0 && submitted ? (
          <p className="error-text">*Please enter the Email.</p>
        ) : null}
        <input
          className="logInput"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setError(false);
          }}
          type="email"
          name="email"
          placeholder="Enter your Email"
        />
        <label>Password</label>
        {error && password.length <= 0 && submitted ? (
          <p className="error-text">*Please enter the Password.</p>
        ) : null}
        <input
          className="logInput"
          name="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            setError(false);
          }}
          type="password"
          placeholder="Enter your Password"
        />
        <div className="checks">
          <input type="checkbox" />
          <p>I agree to all terms and conditions</p>
        </div>
        <button onClick={(e) => handleOnSubmit(e)}>Login</button>
        <h3 className="Forgot-Password">Forgot Password?</h3>
        {/* <div className="socialbtnss">
          <button className="authbtn">
            <img src={google} alt="Google" /> Sign up with Google
          </button>
          <br/>
          <button className="authbtn">
            <img src={fb} alt="Facebook" /> Sign up with Facebook
          </button>
        </div> */}
        <h4 className="dnthvacc">
          Already Have an Account <span><Link onClick={handleSignUp}>Login</Link></span>
        </h4>
      </div>
    </div>
   

   </ModalBody>





    </div>
   

    </Modal>
    </div>
   

    </Modal>

      <h3>
       <Link to='/'> <img className="logo" src={logo} /></Link>
      </h3>
      <nav ref={navRef}>
        <Link to="/" className={`${pathName.pathname === "/" ? "active" : ""}`}>HOME</Link>
        <Link to="/aboutus" className={`${pathName.pathname === "/categories" ? "active" : ""}`}>ABOUT US</Link>
        {/* <Link to="/bharat">{pathName.pathname === "/bharat" ? (<img src={navLogo} alt="" />) : "SERVICES"}</Link> */}
        <select name="cars" id="cars" value={selectedOption} onChange={handleOptionChange}>
        <option value="">SERVICES</option>
        <option value="/pandit">Pandit Booking</option>
        <option value="/prashad">Prashad Booking</option>
        <option value="/shop">Satgan</option>
        <option value="/teerthhome">Teerth Yatra</option>
        <option value="/bharatsikka">Bharat Sikka</option>

      
      </select>
      <label className='touch' for="touch"><span className='span'>SERVICES <i class="bi bi-caret-down"></i></span></label>               
  <input type="checkbox" id="touch"/> 

  <ul class="slide">
  <br/>
    <li><a href="#"><Link to='/pandit'>Pandit Booking</Link></a></li> 
    <li><a href="#"><Link to='/prashad'>Prashad Booking</Link></a></li>
    <li><a href="#"><Link to='/shop'>Satgan</Link></a></li>
    <li><a href="#"><Link to='/teerthhome'>Teerth Yatra</Link></a></li>
    <li><a href="#"><Link to='/bharatsikka'>Bharat Sikka</Link></a></li>
  

  </ul>



        <Link to="/aaradhak" className={`${pathName.pathname === "/blog" ? "active" : ""}`}>AARADHAK</Link>
        <Link to="/live-darshan">LIVE</Link>

        <button className="nav-btn nav-close-btn" onClick={showNavbar}>
          <FaTimes />
        </button>
      </nav>
      <button className="nav-btn" onClick={showNavbar}>
        <FaBars />
      </button>
    </header>
  );
}

export default Topnav;
