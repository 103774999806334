import React from "react";
import "./pujaBooking.css";
import OccasionSlider from "./occasionSlider/OccasionSlider";
import GoddessesSlider from "./goddessesSlider/GoddessesSlider";
import IndividualSlider from "./individualSlider/IndividualSlider";

function PujaBooking() {
  return (
    <>
      <div className="container">
        <div className="puja-booking-section">
          <h1>Pooja Bookings</h1>
        </div>
        <div className="puja-hero-section">
          <div className="text-left">
            <h6>
              Home / <span>Pujas</span>
            </h6>
          </div>
          <div className="occasion">
            <span>Occasion Based Puja</span>
          </div>
            <OccasionSlider />
            <GoddessesSlider />
            <IndividualSlider />
        </div>
      </div>
    </>
  );
}

export default PujaBooking;
