import React, { useState } from "react";
import PrashadBookingContext from "./PrashadBooking";

const PrashadBookingState = (props) => {
  const [cartValue, setCartValue] = useState(1);
  const [email, setEmail] = useState("");
  const [apartment, setApartment] = useState("");
  const [country, setCountry] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [gotra, setGotra] = useState("");
  return (
    <PrashadBookingContext.Provider
      value={{
        cartValue,
        setCartValue,
        email,
        setEmail,
        apartment,
        setApartment,
        gotra,
        setGotra,
        country,
        setCountry,
        firstName,
        setFirstName,
        lastName, setLastName,
        address, setAddress,
        city, setCity,
        state, setState,
        pinCode, setPinCode,
        mobileNumber, setMobileNumber
      }}
    >
      {props.children}
    </PrashadBookingContext.Provider>
  );
};

export default PrashadBookingState;
