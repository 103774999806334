import React, { useEffect, useState } from "react";
import GoddessesCard from "./GoddessesCard";
import nextArrow from "../../../assets/next-arrow.png";
import prevArrow from "../../../assets/prev-arrow.png";
import Slider from "react-slick";
import img1 from '../../../assets/puja/image 81.png'
import img2 from '../../../assets/puja/image 82.png'
import img3 from '../../../assets/puja/image 83.png'
import { SelectPujaAPI } from "../../../api/ProjectsAPI";
import { ConstructionOutlined } from "@mui/icons-material";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <span
        className="next"
        style={{ backgroundImage: `url(${nextArrow})` }}
      ></span>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <span
        className="prev"
        style={{ backgroundImage: `url(${prevArrow})` }}
      ></span>
    </div>
  );
}

function GoddessesSlider() {
  var settings = {
    dots: false,
    infinite: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    speed: 1000,
    autoplay: false,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1324,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  const [god, setGod] = useState([]);

  useEffect(() => {
    SelectPujaAPI.get("GOD-GODESSES", {}, (response) => {
      setGod(response.data);
    });
  }, []);

  const GodPuja = god.map((element, index) => {
    return (
      <div key={index}>
        <GoddessesCard img={element.displayImages[0].url} title={element.name} id={element._id} />
      </div>
    );
  });
  return (
    <>
      <div className="container">
        <div className="goddesses-section">
          <div className="goddesses-title">
            <span>God - Goddesses Based Puja</span>
          </div>
          <Slider {...settings}>
            {GodPuja}
          </Slider>
        </div>
      </div>
    </>
  );
}

export default GoddessesSlider;
