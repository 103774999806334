import React from 'react'
import img from '../Assets/Rectangle 2422.png'
import img1 from '../Assets/Rectangle 2224.png'
import Navbar from '../header/Navbar'
import JionUs from '../BannerSection/JionUs'
import Footer from '../footer/footer'

function SatsangListing() {
  return (
   <>
    <div className='full'>
    <Navbar/>
    <div className='TeerthList'>
    <div className='TeerthList-heading'>


    

    </div>
    <br/>
    <br/>
    <h1 style={{textAlign:'center' , fontSize:'32px'}}>Explore <snap>Spiritual</snap> Diversity</h1>
    <br/>
  
  <button className='TeerthPackages-drop-btn'>Sort by : Relevance</button>

    
      
    <div className='TeerthPackages' id='TeerthPackagess'>
       
        <div className='scroll-container'>
      
        <div className='TeerthPackages-cards'>
        <div className='TeerthPackages-card'>
        <div className='TeerthPackages-card-img'>
        <img src={img1}/>

        </div>
        <h3>Divine Dwarka Darshan</h3>

        <p>Vorem ipsum dolor sit amet, <br/>
        consectetur adipiscing elit. Nunc<br/>
         vulputate libero et velit interdum, <br/>
         ac aliquet odio mattis.</p>
         <h4>Package 20,000 for 4 people</h4>
         <button>View More</button>

        </div>
        <div className='TeerthPackages-card'>
        <div className='TeerthPackages-card-img'>
        <img src={img1}/>

        </div>
        <h3>Divine Dwarka Darshan</h3>

        <p>Vorem ipsum dolor sit amet, <br/>
        consectetur adipiscing elit. Nunc<br/>
         vulputate libero et velit interdum, <br/>
         ac aliquet odio mattis.</p>
         <h4>Package 20,000 for 4 people</h4>
         <button>View More</button>

        </div>
        <div className='TeerthPackages-card'>
        <div className='TeerthPackages-card-img'>
        <img src={img1}/>

        </div>
        <h3>Divine Dwarka Darshan</h3>

        <p>Vorem ipsum dolor sit amet, <br/>
        consectetur adipiscing elit. Nunc<br/>
         vulputate libero et velit interdum, <br/>
         ac aliquet odio mattis.</p>
         <h4>Package 20,000 for 4 people</h4>
         <button>View More</button>

        </div>
        <div className='TeerthPackages-card'>
        <div className='TeerthPackages-card-img'>
        <img src={img1}/>

        </div>
        <h3>Divine Dwarka Darshan</h3>

        <p>Vorem ipsum dolor sit amet, <br/>
        consectetur adipiscing elit. Nunc<br/>
         vulputate libero et velit interdum, <br/>
         ac aliquet odio mattis.</p>
         <h4>Package 20,000 for 4 people</h4>
         <button>View More</button>

        </div>

        </div>
        </div>



        {/* <button className='vi-btn' style={{marginTop:'30px'}}>View all</button> */}

        </div>
        <br/>
        <div className='TeerthPackages' id='TeerthPackagess'>
       
        <div className='scroll-container'>
      
        <div className='TeerthPackages-cards'>
        <div className='TeerthPackages-card'>
        <div className='TeerthPackages-card-img'>
        <img src={img1}/>

        </div>
        <h3>Divine Dwarka Darshan</h3>

        <p>Vorem ipsum dolor sit amet, <br/>
        consectetur adipiscing elit. Nunc<br/>
         vulputate libero et velit interdum, <br/>
         ac aliquet odio mattis.</p>
         <h4>Package 20,000 for 4 people</h4>
         <button>View More</button>

        </div>
        <div className='TeerthPackages-card'>
        <div className='TeerthPackages-card-img'>
        <img src={img1}/>

        </div>
        <h3>Divine Dwarka Darshan</h3>

        <p>Vorem ipsum dolor sit amet, <br/>
        consectetur adipiscing elit. Nunc<br/>
         vulputate libero et velit interdum, <br/>
         ac aliquet odio mattis.</p>
         <h4>Package 20,000 for 4 people</h4>
         <button>View More</button>

        </div>
        <div className='TeerthPackages-card'>
        <div className='TeerthPackages-card-img'>
        <img src={img1}/>

        </div>
        <h3>Divine Dwarka Darshan</h3>

        <p>Vorem ipsum dolor sit amet, <br/>
        consectetur adipiscing elit. Nunc<br/>
         vulputate libero et velit interdum, <br/>
         ac aliquet odio mattis.</p>
         <h4>Package 20,000 for 4 people</h4>
         <button>View More</button>

        </div>
        <div className='TeerthPackages-card'>
        <div className='TeerthPackages-card-img'>
        <img src={img1}/>

        </div>
        <h3>Divine Dwarka Darshan</h3>

        <p>Vorem ipsum dolor sit amet, <br/>
        consectetur adipiscing elit. Nunc<br/>
         vulputate libero et velit interdum, <br/>
         ac aliquet odio mattis.</p>
         <h4>Package 20,000 for 4 people</h4>
         <button>View More</button>

        </div>

        </div>
        </div>



        {/* <button className='vi-btn' style={{marginTop:'30px'}}>View all</button> */}

        </div>
        <br/>

        <div className='TeerthPackages' id='TeerthPackagess'>
       
        <div className='scroll-container'>
      
        <div className='TeerthPackages-cards'>
        <div className='TeerthPackages-card'>
        <div className='TeerthPackages-card-img'>
        <img src={img1}/>

        </div>
        <h3>Divine Dwarka Darshan</h3>

        <p>Vorem ipsum dolor sit amet, <br/>
        consectetur adipiscing elit. Nunc<br/>
         vulputate libero et velit interdum, <br/>
         ac aliquet odio mattis.</p>
         <h4>Package 20,000 for 4 people</h4>
         <button>View More</button>

        </div>
        <div className='TeerthPackages-card'>
        <div className='TeerthPackages-card-img'>
        <img src={img1}/>

        </div>
        <h3>Divine Dwarka Darshan</h3>

        <p>Vorem ipsum dolor sit amet, <br/>
        consectetur adipiscing elit. Nunc<br/>
         vulputate libero et velit interdum, <br/>
         ac aliquet odio mattis.</p>
         <h4>Package 20,000 for 4 people</h4>
         <button>View More</button>

        </div>
        <div className='TeerthPackages-card'>
        <div className='TeerthPackages-card-img'>
        <img src={img1}/>

        </div>
        <h3>Divine Dwarka Darshan</h3>

        <p>Vorem ipsum dolor sit amet, <br/>
        consectetur adipiscing elit. Nunc<br/>
         vulputate libero et velit interdum, <br/>
         ac aliquet odio mattis.</p>
         <h4>Package 20,000 for 4 people</h4>
         <button>View More</button>

        </div>
        <div className='TeerthPackages-card'>
        <div className='TeerthPackages-card-img'>
        <img src={img1}/>

        </div>
        <h3>Divine Dwarka Darshan</h3>

        <p>Vorem ipsum dolor sit amet, <br/>
        consectetur adipiscing elit. Nunc<br/>
         vulputate libero et velit interdum, <br/>
         ac aliquet odio mattis.</p>
         <h4>Package 20,000 for 4 people</h4>
         <button>View More</button>

        </div>

        </div>
        </div>



       
        </div>
        <br/>
    

    </div>

    <JionUs/>
    <Footer/>

    </div>
   </>
  )
}

export default SatsangListing