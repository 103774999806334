import React from 'react'
import Footer from './footer'
import Navbar from '../header/Navbar'

function ReturnandRefund() {
  return (
<div className='full'>
<Navbar/>
<div className='ftdt'>Return and Refund</div>
<Footer/>
</div>  )
}

export default ReturnandRefund