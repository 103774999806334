import React from "react";
import { Link } from "react-router-dom";

function OccasionCard({title, img, id}) {
  return (
    <>
      {/* <div className="occasion-card">
        <img src={img} alt="" />
        <div className="occasion-card-title">
          <h5>{title}</h5>
          <div className="text-center">
            <Link to={`/pujas/puja-booking/${id}`}>
            <button className="book-now">Book Now</button>
            </Link>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default OccasionCard;
