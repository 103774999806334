import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import IndividualCard from "./IndividualCard";
import img1 from "../../../assets/puja/image 86.png";
import img2 from "../../../assets/puja/image 87.png";
import img3 from "../../../assets/puja/image 88.png";
import nextArrow from "../../../assets/next-arrow.png";
import prevArrow from "../../../assets/prev-arrow.png";
import { SelectPujaAPI } from "../../../api/ProjectsAPI";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <span
        className="next"
        style={{ backgroundImage: `url(${nextArrow})` }}
      ></span>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <span
        className="prev"
        style={{ backgroundImage: `url(${prevArrow})` }}
      ></span>
    </div>
  );
}

function IndividualSlider() {
  var settings = {
    dots: false,
    infinite: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    speed: 1000,
    autoplay: false,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1324,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  const [individual, setIndividual] = useState([]);

  useEffect(() => {
    SelectPujaAPI.get("INDIVIDUAL", {}, (response) => {
      setIndividual(response.data);
    });
  }, []);

  const IndividualPuja = individual.map((element, index) => {
    return (
      <div key={index}>
        <IndividualCard img={element.image} title={element.name} id={element._id} />
      </div>
    );
  });
  return (
    <>
      <div className="individual-section">
        <div className="container">
          <div className="individual-title">
            <span>Individual Based Puja</span>
          </div>
          <Slider {...settings}>
            {IndividualPuja}
            <div>
              <IndividualCard img={img1} title={"Birthday puja"} />
            </div>
            <div>
              <IndividualCard img={img2} title={"Anniversary puja"} />
            </div>
            <div>
              <IndividualCard img={img3} title={"Engagement puja"} />
            </div>
            <div>
              <IndividualCard img={img1} title={"Birthday puja"} />
            </div>
            <div>
              <IndividualCard img={img2} title={"Anniversary puja"} />
            </div>
            <div>
              <IndividualCard img={img3} title={"Engagement puja"} />
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
}

export default IndividualSlider;
