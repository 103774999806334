import React, { useEffect } from 'react'
import SignInPage from '../components/signinPage/signinPage'
import Navbar from '../components/header/Navbar'
import Footer from '../components/footer/footer'
import img from '../components/Assets/Rectangle 401.png'
import logo from '../components/Assets/bharat 360 primary logo prototype A 2.png'
import google from '../components/Assets/image 157.png'
import fb from '../components/Assets/image 156.png'
import {Link} from 'react-router-dom';

function SignIn() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>

    {/* <SignInPage /> */}

    <div className="NewLoginPage">
        <div className="NewLoginPage-left">
        <img src={img}/>

        

</div>
 <div className="NewLoginPage-right">
 <img className='singuplogo' src={logo}/>
 <h1>SIGN UP</h1>
 <p>Join Us in the spiritual journey</p>
 <label>E-mail or phone number</label>
 <input className="logInput" placeholder="Enter your Email"/>
 <label>Mobile Number</label>
 <input className="logInput" placeholder="Enter your Mobile Number"/>
 <label>Password</label>
 <input className="logInput" placeholder="Enter your Password"/>
 <div className="checks">

 <input type="checkbox"/>
  <p>I agree to all terms and condition</p>
 </div>
 <Link to="/"><button>Sign Up</button></Link>

 <h3 className="Forgot-Password">Forgot Password?</h3>


<h4 className="dnthvacc">Already have an account <span> <Link to="/login">Login</Link></span></h4>

        

</div>


        </div>

    </>
  )
}

export default SignIn