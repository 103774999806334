
import React from "react";

function DiwaliDiscription() {
  return (
    <>
      <div className="discription-section">
        {/* <div className="discription-title">
          <span>About Shree Siddhivinayak Ganapati Temple At Mumbai.</span>
        </div> */}
        <div className="discription-text">
          <p className="text-one">
            Goddess Lakshmi puja is very important to worship in every Indian
            household. There is a meaning of Lakshmi that the word “Laksh” is
            the root word derived from Lakshmi. Goddess Lakshmi confers many
            blessings and prosperity upon every household.
          </p>
          <p className="text-one">
            Along with Goddess Lakshmi, Lord Ganesh is worshiped by placing
            their idols on the platform. In the household a lot of people keep a
            silver coin of Lakshmi and Ganesh at puja with Incense sticks and
            dhoop are lit and offerings of sweets, coconut, fruits, and tambulam
            are made. As per the drik panchang calendar the Diwali Lakshmi pujan
            is performed in the Pradosh kaal and starts after the sunset in
            evening.
          </p>
          <p className="text-one">
            The Diwali Lakshmi Pooja is considered to be a good time to worship
            Mata Laxmi Ji as our Sastras direct to us. Some of the people know
            the rituals for Diwali Lakshmi puja to perform but some of the
            people call priests to perform Lakshmi pujan.
          </p>
          <p className="text-one">
            You can come to the 99Pandit platform to get the best and
            well-experienced pandit for Diwali puja service. However, the
            services of 99Pandit will help you to meet with the best and
            one-stop solution for all the activities related to Hinduism like
            Diwali puja.
          </p>
          <p className="text-one">
            You can come to 360 Bharat platform to book the online pandit for
            Diwali Lakshmi pujan.
          </p>
          <p className="text-one">
            Diwali is the festival of lights and joy and this is a five day
            celebration, every day a different puja is performed. Diwali is
            celebrated every year of Karthik Krishna Amavasya and there is a
            myth that Goddess Lakshmi visited every home on Diwali night. After
            Diwali Puja every person lights diyas (lamps) in their homes to
            usher in light and clear the darkness from the world.
          </p>
        </div>
      </div>
    </>
  );
}

export default DiwaliDiscription;
