import { Container, Grid, Typography } from "@mui/material";
import Typewriter from "typewriter-effect";

import React from "react";
import './home.css'
import LiveCard from "./LiveCard/LiveCard";
import img1 from '../../assets/img1.png'
import img2 from '../../assets/img2.png'
import img3 from '../../assets/img3.png'
import img4 from '../../assets/img4.png'
import logo1 from '../../assets/yt-logo.png'
import logo2 from '../../assets/laddu-logo.png'
import logo3 from '../../assets/agg-logo.png'
import logo4 from '../../assets/test-logo.png'

function LiveDarshan() {
  return (
    <div>
      <Container fixed>
        <Typography textAlign="center" component={"div"} className="live-title" marginTop="74px" marginBottom="29px" variant="h4" fontFamily="Libre Baskerville" fontSize={36}>
          Connecting Faith
          <div className="text-orange">
            <Typewriter
                options={{
                  strings: ['To Live Darshan', 'To Prashads', 'To Online Pujas', 'To Pandit on Call'],
                  autoStart: true,
                  loop: true,
                }}
              />
          </div>
        </Typography>
        <Typography variant="subtitle1" component={"div"} className="live-text" textAlign="center" maxWidth="1013px" margin="auto" color="#000000">We're bringing our beautiful religion and philosophy into the modern era so that people can feel closer to God and to each other in their shared pursuit of happiness. To help you along your spiritual path, we've set up all the necessary networks and connections and are working to digitalize all possible facets of spirituality.</Typography>
        <Grid container display="flex" justifyContent="center"  spacing={{ xs: 4, md: 4 }} columns={{ xs: 4, sm: 8, md: 0 }}>
            <Grid item md={6} lg={3}>
                <LiveCard title="Live Darshan" img={img1} logo={logo1} link={"/live-darshan"} />
            </Grid>
            <Grid item md={6} lg={3}>
                <LiveCard title="Prashad" img={img2} logo={logo2} link={"/prashad"} />
            </Grid>
            <Grid item md={6} lg={3}>
                <LiveCard title="Pujas" img={img3} logo={logo3} link={"/puja"} />
            </Grid>
            <Grid item md={6} lg={3}>
                <LiveCard title="Pandit on Call" img={img4} logo={logo4} link={"/pandit"} />
            </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default LiveDarshan;
