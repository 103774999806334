import React, { useEffect, useState } from "react";
import OccasionCard from "./OccasionCard";
import Slider from "react-slick";
import nextArrow from "../../../assets/next-arrow.png";
import prevArrow from "../../../assets/prev-arrow.png";
import img1 from '../../../assets/puja/image 89.png'
import img2 from '../../../assets/puja/image 90.png'
import img3 from '../../../assets/puja/image 91.png'
import { SelectPujaAPI } from "../../../api/ProjectsAPI";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <span
        className="next"
        style={{ backgroundImage: `url(${nextArrow})` }}
      ></span>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <span
        className="prev"
        style={{ backgroundImage: `url(${prevArrow})` }}
      ></span>
    </div>
  );
}

function OccasionSlider() {
    var settings = {
        dots: false,
        infinite: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        speed: 1000,
        autoplay: false,
        autoplaySpeed: 2000,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 1324,
            settings: {
              slidesToShow: 3,
            },
          },
        ],
      };

      const [occasionData, setOccasionData] = useState([])


      useEffect(() => {

        SelectPujaAPI.get("OCCASION", {}, (response) => {
          setOccasionData(response.data)
        })
      }, [])

      const occasionPuja = occasionData.map((element, index) => {
        return(
          <div key={index}>
            <OccasionCard img={element.image} title={element.name} id={element._id} />
          </div>
        )
      })
      
  return (
    <>
      <div className="occasionSlider">
        <Slider {...settings}>
          {occasionPuja}
          <div>
            <OccasionCard img={img1} title={"Diwali Puja"} />
          </div>
          <div>
            <OccasionCard img={img2} title={"akshay Tratiya Puja"} />
          </div>
          <div>
            <OccasionCard img={img3} title={"Dussehra Puja"} />
          </div>
          <div>
            <OccasionCard img={img1} title={"Diwali Puja"} />
          </div>
          <div>
            <OccasionCard img={img2} title={"akshay Tratiya Puja"} />
          </div>
          <div>
            <OccasionCard img={img3} title={"Dussehra Puja"} />
          </div>
        </Slider>
      </div>
    </>
  );
}

export default OccasionSlider;
