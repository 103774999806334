import React, { useContext, useState } from "react";
import "./checkout-page.css";
import Navbar from "../header/Navbar";
import Footer from "../footer/footer";
import { Dialog, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import img1 from "../../assets/image 17-2.png";
import BookingContext from "../../context/booking/bookingContext";
import axios from "axios";

function CheckoutPage() {
  const navigate = useNavigate();
  const BookingStates = useContext(BookingContext);
  const { pujaId, selectedDate, selectedTime, price, templeId } = BookingStates;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [address, setAddress] = useState("");
  const [selectCity, setSelectCity] = useState("");
  const [selectState, setSelectState] = useState("");
  const [status, setStatus] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [open, setOpen] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([
    { name: "Credit / Debit Card, Net Banking", status: true },
    { name: "Sash free Payment", status: false },
    { name: "Payments from outside india", status: false },
  ]);
  const [billingAddresses, setBillingAddresses] = useState([
    { name: "Same as shipping address", status: true },
    { name: "Use a different Billing Address", status: false },
  ]);
  const data = paymentMethods.map((allJobs) => allJobs);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOnSubmit = async () => {
    const data = JSON.stringify({
      amount: "10.00",
      // "amount": (sippingData.cost * cartValue).toString(),
      purpose: "Prashad / Puja",
      buyer_name: name,
      email: email,
      phone: number,
      redirect_url: "http://localhost:3000",
      orderId: pujaId,
    });
    try {
      const res = await axios({
        method: "post",
        url: `https://starfish-app-lyrtb.ondigitalocean.app/api/payment/generatepaymentlink`,
        data: data,
        headers: {
          "Content-Type": "application/json",
          // authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      setStatus(res.status);
      if (res.status) {
        window.location.replace(res.data.data.longurl);
      }
    } catch (e) {}
    if (status === "200") {
      var raw = JSON.stringify({
        address: {
          loc: address,
        },
        name: name,
        puja: pujaId,
        date: selectedDate,
        time: selectedTime,
        cost: price,
        payment_mode: "COD",
        templeId: templeId,
      });
      setOpen(false);
      try {
        const bookPuja = await axios({
          method: "post",
          url: `https://starfish-app-lyrtb.ondigitalocean.app/api/checkout/puja/create/online`,
          data: raw,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        setName("");
        setAddress("");
        setPinCode("");
        setSelectCity("");
        setSelectState("");
        navigate("/puja");
      } catch (e) {}
    }
  };

  return (
    <>
      <Navbar />
      <section className="diwali-detail-hero">
        <div className="container-fluid">
          <div className="text-center">
            <h1>Choose a Pandit </h1>
          </div>
          <div className="container">
            <div className="diwali-head">
              <p>
                Home / Pujas / Diwali Puja / <span>Checkout</span>
              </p>
            </div>
            <Grid container>
              <Grid item lg={6} sm={12} xs={12} md={6}>
                <div className="input-container">
                  <div className="d-flex flex-column">
                    <label className="book-label" htmlFor="">
                      Name
                    </label>
                    <input
                      className="book-input"
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="d-flex flex-column">
                    <label className="book-label" htmlFor="">
                      Email
                    </label>
                    <input
                      className="book-input"
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="d-flex flex-column">
                    <label className="book-label" htmlFor="">
                      Number
                    </label>
                    <input
                      className="book-input"
                      type="text"
                      value={number}
                      onChange={(e) => setNumber(e.target.value)}
                    />
                  </div>
                  <div className="d-flex flex-column">
                    <label className="book-label" htmlFor="">
                      Address
                    </label>
                    <input
                      className="book-input"
                      type="text"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>
                  <div className="d-flex flex-column">
                    <label className="book-label" htmlFor="">
                      Select City
                    </label>
                    <input
                      className="book-input"
                      type="text"
                      value={selectCity}
                      onChange={(e) => setSelectCity(e.target.value)}
                    />
                  </div>
                  <div className="d-flex flex-column">
                    <label className="book-label" htmlFor="">
                      Select State
                    </label>
                    <input
                      className="book-input"
                      type="text"
                      value={selectState}
                      onChange={(e) => setSelectState(e.target.value)}
                    />
                  </div>
                  <div className="d-flex flex-column">
                    <label className="book-label" htmlFor="">
                      Pin Code
                    </label>
                    <input
                      className="book-input"
                      type="number"
                      value={pinCode}
                      onChange={(e) => setPinCode(e.target.value)}
                    />
                  </div>
                  <Dialog
                    open={open}
                    className="modal-width"
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                  >
                    <div className="checkout-modal">
                      <div className="text-center">
                        <h2 className="checkout-title">Payment Method</h2>
                      </div>
                      <Grid container columnSpacing={3}>
                        <Grid item lg={6}>
                          <div className="payment-grid">
                            <h6 className="payment-grid-title">Payment</h6>
                            <p className="payment-grid-text">
                              All transactions are secure and encrypted.
                            </p>
                            <div className="input-box d-flex align-items-center">
                              <input
                                type="radio"
                                className="radio-field"
                                defaultChecked
                                name="payment"
                                value={paymentMethod}
                                onChange={() =>
                                  setPaymentMethod(data[0].status)
                                }
                              />
                              <label>Credit / Debit Card, Net Banking</label>
                            </div>
                            <div className="input-box d-flex align-items-center">
                              <input
                                type="radio"
                                className="radio-field"
                                value={paymentMethod}
                                name="payment"
                                onChange={() =>
                                  setPaymentMethod(data[1].status)
                                }
                              />
                              <label>Cash free Payment</label>
                            </div>
                            <div className="input-box d-flex align-items-center">
                              <input
                                type="radio"
                                className="radio-field"
                                value={paymentMethod}
                                name="payment"
                                onChange={() =>
                                  setPaymentMethod(data[2].status)
                                }
                              />
                              <label>Payments from outside india</label>
                            </div>
                          </div>
                        </Grid>
                        <Grid item lg={6}>
                          <div className="payment-grid">
                            <h6 className="payment-grid-title">
                              Billing Address
                            </h6>
                            <p className="payment-grid-text">
                              Select the address that matches your card or
                              payment method.
                            </p>
                            <div className="input-box d-flex align-items-center">
                              <input
                                type="radio"
                                className="radio-field"
                                defaultChecked
                                name="billing"
                                value={billingAddress}
                                onChange={() =>
                                  setBillingAddress(billingAddresses[0].status)
                                }
                              />
                              <label>Same as shipping address</label>
                            </div>
                            <div className="input-box d-flex align-items-center">
                              <input
                                type="radio"
                                className="radio-field"
                                value={billingAddress}
                                name="billing"
                                onChange={() =>
                                  setBillingAddress(billingAddresses[1].status)
                                }
                              />
                              <label>Use a different Billing Address</label>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                      <div className="text-center">
                        <button
                          className="complete-order-btn"
                          onClick={() => handleOnSubmit()}
                        >
                          Complete Order
                        </button>
                      </div>
                    </div>
                  </Dialog>
                </div>
              </Grid>
              <Grid item lg={6} sm={12} xs={12} md={6}>
                <div className="checkout-img">
                  <img src={img1} alt="" />
                </div>
              </Grid>
            </Grid>
            <div className="text-center">
              <button
                className="continue-btn"
                onClick={() => handleClickOpen()}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default CheckoutPage;
