import React from 'react'
import Navbar from '../header/Navbar'
import img from '../Assets/Group 18.png'
import img1 from '../Assets/Screenshot 2023-09-08 104013.png'
import img2 from '../Assets/Screenshot 2023-09-08 104934.png'
import JionUs from '../BannerSection/JionUs'
import Footer from '../footer/footer'

function TeerthYatraPackageTwo() {
  return (
    <div style={{backgroundColor:'#FFFFF0'}}>
    <Navbar/>
    <div className='TeerthYatraPackage-section'>
    <div className='TeerthYatraPackage-section-left'>
    <img src={img}/>

    </div>
    <div className='TeerthYatraPackage-section-right'>
    <div>
    <h1>Himalayan Bliss Yatra</h1>
    <h3>₹30,053 per person*<br/>
    <span> *Excluding applicable taxes</span></h3>
    <hr/>
    <p>DESCRIPTION</p>
    <h4>Embark on a soul-enriching journey through the majestic<br/>
     Himalayan region. The Himalayan Bliss Yatra is a 12-day<br/>
      pilgrimage that takes you to some of the most revered <br/>
      spiritual destinations nestled amidst the pristine beauty of the<br/>
       Himalayas.</h4>
    <div className='PackageType'>
        <p>Package Type</p>
        <h2>Spiritual Pilgrimage</h2>
    </div>
    <div className='PackageType'>
        <p>Duration</p>
        <h2>12 days</h2>
    </div>
    <div className='PackageType'>
        <p>Package Type</p>
        <h2>Spiritual Pilgrimage</h2>
    </div>

    <p style={{fontSize:'16px'}}>Persons</p>
    <button className="qty-btn">- 1 +</button>
    <br/>
    <p style={{fontSize:'16px'}}>INCLUDES</p>
    <img style={{height:'30px' ,width:'100px'}} src={img1}/><br/>
    <button className='book'>Book</button>

    </div>

   </div>

    </div>

    <div className='gallary'>
  <div className='gallaryy'>
  <h1>GALLERY</h1>
    <img src={img2}/>
  </div>
    <br/>
    <button>View All</button>

    </div>
    <br/>

    <div className='TRAVEL-SCHEDULE'>
  <div className='TRAVEL-SCHEDULE-center'>
  <h3>TRAVEL SCHEDULE</h3>
  <br/>

    <h4><i class="bi bi-bus-front-fill"></i> Delhi to Rishikesh</h4>
    <br/> <div className='rishikesh'>
    <div className='rishikesh-heading'>
    <h1>Rishikesh</h1>

    

    

</div>
  <div class="box-section">
    <div class="boxA">
<h1>Day 1</h1>
<p>Dd/mm/yy</p>

    </div>
   
    <div class="boxB" >
      <span>
        <i class="fa-solid fa-bed"></i>
        <p>Check in to Regenta Inn On The Ganges <br/> - Holiday Collection, 4 Star </p>
      </span>
      <hr class="hr"/>

    </div>
   
    <div class="boxB">

      <span>
        <i class="fa-solid fa-utensils"></i>
        <p>Day Meals; Dinner : Included in <br/>  Rishikesh </p>
      </span>
      <hr class="hr1"/>
    </div>

 
    <div class="boxA">
<h1>Day 1</h1>
<p>Dd/mm/yy</p>

    </div>
   
    <div class="boxB" >
      <span>
        <i class="fa-solid fa-bed"></i>
        <p>Check in to Regenta Inn On The Ganges <br/> - Holiday Collection, 4 Star </p>
      </span>
      <hr class="hr"/>

    </div>
   
    <div class="boxB">

      <span>
        <i class="fa-solid fa-utensils"></i>
        <p>Day Meals; Dinner : Included in <br/>  Rishikesh </p>
      </span>
      <hr class="hr1"/>
    </div>
  </div>
  </div>





    </div>


    </div>


    <div className='Notes'>
    <div className='important-notes'>
   <h1>IMPORTANT NOTES</h1>
<p>
    This Teerth Yatra package is designed for spiritual enrichment and cultural exploration. Please be respectful of local customs, traditions, and sacred sites throughout the journey.
Itineraries and activities are subject to change based on weather conditions, unforeseen circumstances, or the discretion of the tour organizers. Flexibility and adaptability are appreciated.
Participants are encouraged to bring suitable clothing for varying weather conditions, comfortable walking shoes, and any specific items needed for personal spiritual practices.
</p>
 <br/>

 <h1>IMPORTANT NOTES</h1>
<p>
    This Teerth Yatra package is designed for spiritual enrichment and cultural exploration. Please be respectful of local customs, traditions, and sacred sites throughout the journey.
Itineraries and activities are subject to change based on weather conditions, unforeseen circumstances, or the discretion of the tour organizers. Flexibility and adaptability are appreciated.
Participants are encouraged to bring suitable clothing for varying weather conditions, comfortable walking shoes, and any specific items needed for personal spiritual practices.
</p>
<br/>

<h1>IMPORTANT NOTES</h1>
<p>
    This Teerth Yatra package is designed for spiritual enrichment and cultural exploration. Please be respectful of local customs, traditions, and sacred sites throughout the journey.
Itineraries and activities are subject to change based on weather conditions, unforeseen circumstances, or the discretion of the tour organizers. Flexibility and adaptability are appreciated.
Participants are encouraged to bring suitable clothing for varying weather conditions, comfortable walking shoes, and any specific items needed for personal spiritual practices.
</p>
  

   

    </div>

    </div>
    <br/>
    <JionUs/>
    <Footer/>
   </div>
  )
}

export default TeerthYatraPackageTwo