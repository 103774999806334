import React, { useEffect, useState } from 'react';
import Navbar from '../components/header/Navbar'
import Footer from '../components/footer/footer'
import HeroSection from '../components/liveDarshan/HeroSection'
import img1 from "../components/Assets/Rectangle 241.png"
import JoinUs from '../components/BannerSection/JionUs'
import { Link } from 'react-router-dom';

function LiveDarshan() {
  const [darshans, setDarshans] = useState([]);

  useEffect(() => {
    // Fetch the darshan data from your API
    fetch('https://bharat-360-app-vzet3.ondigitalocean.app/api/darshan/getalldarshan')
      .then((response) => response.json())
      .then((data) => setDarshans(data.data))
      .catch((error) => console.error('Error fetching darshan data:', error));
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='full'>
    <Navbar />
    <div className='banner livedrashanbanner'>
  
  <h1>Witness Divinity in Real-Time</h1>
  <p>Welcome to our Live Darshan page, where you can experience the divine presence of sacred temples<br/> and shrines in real-time. Join us as we bring you closer to the spiritual heart of India.</p>
  

  <div className='filterinputs'>
  <div className='filterinputs-input find-satnag' >
  <i class="bi bi-search"></i>
  <input placeholder='Search for a place'/>

  </div>
  <button className='bannerbtn'>Search</button>
  

  </div>
  </div>
  <br/><br/>
  <br/><br/>
  <div className="offerings">
        <h1>Live   <span>from Sacred</span> Temples</h1>
        <p> Provide a list of sacred temples and spiritual places that offer live streaming of their darshan (worship) ceremonies. Include images of <br/>the temples and a brief description of their significance.</p>
        <br/>
        <br/>
        <div class="scroll-container">
        <div className="offring-cards">
      {darshans.map((darshan) => (

        <Link to={`/live-darshan-details/${darshan._id}`} key={darshan._id} className='links'>
        <div className="offring-card" key={darshan._id}>
          <div className="offring-card-img">
            {/* You can use an image related to the darshan here */}
            <img src={img1} alt={darshan.name} />
          </div>
          <h3 className='drsanname'>{darshan.name}</h3>
          
        </div>
        </Link>
      ))}
    </div>
        </div>
      </div>
   
        <br/>
        <br/>

        <JoinUs/>
    <Footer />
    </div>
  )
}

export default LiveDarshan