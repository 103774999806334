import React, { useEffect, useState } from 'react';
import Navbar from '../header/Navbar'
import img from '../Assets/Rectangle 2422.png'
import img1 from '../Assets/Rectangle 2224.png'

import Joinus from '../BannerSection/JionUs'
import Footer from '../footer/footer'

function TeerthList() {
  const [temples, setTemples] = useState([]);

  useEffect(() => {
    // Fetch the temple data from your API
    fetch('https://bharat-360-app-vzet3.ondigitalocean.app/api/temple/getalltemple')
      .then((response) => response.json())
      .then((data) => setTemples(data.data))
      .catch((error) => console.error('Error fetching data:', error));
  }, []);
  return (
   <div className='TeerthList-section'>
    <Navbar/>
    <div className='TeerthList'>
    <div className='TeerthList-heading'>


    

    </div>
    <br/>
    <br/>
    <h1 style={{textAlign:'center' , fontSize:'32px'}}>Explore <snap>Spiritual</snap> Diversity</h1>
    <br/>
  
  <button className='TeerthPackages-drop-btn'>Sort by : Relevance</button>

    
      
  <div className='TeerthPackages' id='TeerthPackagess'>
      <div className='scroll-container'>
        <div className='TeerthPackages-cards'>
          <div className="temple-list">
            {temples.map((temple) => (
              <div className='TeerthPackages-card' key={temple._id}>
                <h3>{temple.name}</h3>
                <p>Location: {temple.location}</p>
                {/* Assuming displayImage, templeName, description, and cost are available */}
                <img src={temple.displayImage} alt={temple.templeName} />
                <p>{temple.description}</p>
                <h4>Package {temple.cost} for 4 people</h4>
                <button>View More</button>
              </div>
            ))}
          </div>
        </div>
      </div>

      <br />
      <br />
      <br />
    </div>
     
       <Joinus/>
       <Footer/>

    </div>

   </div>
  )
}

export default TeerthList