import React, { useEffect } from 'react'
import Navbar from '../components/header/Navbar'
import Footer from '../components/footer/footer'
import SippingPage from '../components/Sipping/SippingPage'
import { useLoaderData } from 'react-router-dom';

export async function loader({ params }) {
  return params;
}

function Sipping() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const data = useLoaderData();
  return (
    <>
    <Navbar />
    <SippingPage sippingID={data.sippingID} />
    <Footer />
    </>
  )
}

export default Sipping