import React, { useEffect } from 'react'
import HeroSection from '../components/homepage/HeroSection'
import Navbar from '../components/header/Navbar'
import LiveDarshan from '../components/homepage/LiveDarshan'
import Prashad from '../components/homepage/Prashad'
import Rituals from '../components/homepage/Rituals'
import Pujas from '../components/homepage/Pujas'
import Works from '../components/homepage/Works'
import Pandit from '../components/homepage/Pandit'
import Blog from '../components/homepage/Blog'
import Footer from '../components/footer/footer'
import Banner from '../components/BannerSection/Banner'
import Experience from '../components/BannerSection/Experience'
import Diffrent from '../components/BannerSection/Diffrent'
import Offering from '../components/BannerSection/Offering'
import Tapestry from '../components/BannerSection/Tapestry'
import Guidance from '../components/BannerSection/Guidance'
import TeerthPackages from '../components/BannerSection/TeerthPackages'
import Blessings from '../components/BannerSection/Blessings'
import RealTime from '../components/BannerSection/RealTime'
import Testimonials from '../components/BannerSection/Testimonials'
import JionUs from '../components/BannerSection/JionUs'
// import Topnav from '../components/header/Topnav'

function HomePage() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='homepages'>
    {/* <Topnav/> */}
        <Navbar />
        <Banner/>
        <Experience/>
        <Diffrent/>
        {/* <HeroSection /> */}
        <Offering/>
        <br/>

        <Tapestry/>
        <br/>
        <br/>
        <Guidance/>
        <TeerthPackages/>
        <Blessings/>
        <RealTime/>
        <Testimonials/>
        <JionUs/>
        {/* <LiveDarshan />
        <Prashad />
        <Rituals />
        <Pujas />
        <Works />
        <Pandit />
        <Blog /> */}
        <Footer />
    </div>
  )
}

export default HomePage