import React, { useEffect } from 'react'
import Navbar from '../components/header/Navbar'
import Footer from '../components/footer/footer'
import HeroSection from '../components/prashadpage/HeroSection'

function Prashad() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='full'>
    <Navbar/>
    <HeroSection/>
    <Footer/>
 
    </div>
  )
}

export default Prashad