import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams from react-router-dom
import './blog.css';
import '../Assets/Group 18.png'
import Navbar from '../header/Navbar';
import JionUs from '../BannerSection/JionUs';
import Footer from '../footer/footer';


function NewBlogDetails() {
    const { id } = useParams(); // Get the blog ID from the URL

    const [blog, setBlog] = useState(null);
  
    useEffect(() => {
      const fetchBlogDetails = async () => {
        try {
          const response = await fetch(`https://bharat-360-app-vzet3.ondigitalocean.app/api/blog/getblog/${id}`);
          const data = await response.json();
          
          if (data.message === "Successfully fetched the blog") {
            setBlog(data.data);
          } else {
            console.error('Error fetching blog details:', data.message);
          }
        } catch (error) {
          console.error('Error fetching blog details:', error);
        }
      };
  
      fetchBlogDetails();
    }, [id]);
  
    if (!blog) {
      return <div>Loading...</div>;
    }
  return (
   <div className='full'>
   <Navbar/>
     <div className='blogDetailspage'>
      <div className='blogdetail-img'>
        <img src={blog.image} alt={blog.title} />
      </div>
      <div className='blogdetailtext'>
        <h1>{blog.title}</h1>
        <p dangerouslySetInnerHTML={{ __html: blog.content }} />
        <p>Created At: {blog.createdAt}</p>
        <p>Updated At: {blog.updatedAt}</p>
      </div>
    </div>
    <JionUs/>
    <Footer/>
   </div>
   
  )
}

export default NewBlogDetails