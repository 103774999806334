import logo from "../Assets/bharat 360 primary logo prototype A 2.png";
import fb from "../Assets/Logo--facebook.png";
import insta from "../Assets/Logo--instagram.png";
import twiter from "../Assets/x-logo.svg";
import linkdn from "../Assets/Logo--linkedin.png";
import { Link, useLocation, useNavigate } from "react-router-dom";

function Footer() {
  return (
    <>
    <div className="newfooter">
      <div className="footerlogo">
        <img src={logo} style={{ marginBottom: "30px" }} />
        <div className="footer-social">
          <img src={fb} />
          <img src={insta} />
          <img src={twiter} style={{height: "24px", width: "24px"}} />
          <img src={linkdn} />
        </div>
      </div>
      {/* <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-2 gap-8 xl:gap-10"> */}
      <div className="ul-div">
      <div className="uls">
          <ul>
            Quick Links
            <li>
              {" "}
              <Link className="links" to="/">Home</Link>
            </li>
            <li>
              <Link className="links" to="/blogs">Blogs</Link>
            </li>
            <li>
              <Link className="links" to="/categories">Collections</Link>{" "}
            </li>
            <li>New arrivals</li>
            <li>
              <Link className="links" to="/about">About Us</Link>
            </li>
          </ul>
        </div>
        <div className="uls">
          <ul>
            Menu
            <li><Link className="links" to="/Termsandcondition">Terms and condition</Link></li>
            <li><Link className="links" to="/policy">Privacy Policy</Link></li>
            <li><Link className="links" to="/returnrefund">Return and Refund</Link></li>
            {/* <li><Link className="links" to="/popularthisweek">Popular this week</Link></li> */}
            <li><Link className="links" to="/sippingandreturn">Sipping and Return policy</Link></li>

            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        {/* </div> */}
        {/* <div className="uls">
          <ul>
            Categories
            <li>Puja Essentials</li>
            <li>Spiritual Books</li>
            <li>Holy Water and Vibhuti</li>
            <li>Idols and Deity Statues</li>
          </ul>
        </div> */}
      </div>
      </div>

      <div className="mainList">
        <label>JOIN OUR MAILING LIST</label>
        <div className="footerinput">
          <input placeholder="your@email.com" />
          <button>Sign up</button>
        </div>
      </div>
    </div>
    <div className="sub-footer">
      <div className="container">
        <p className="sub-footer-text">Copyright 2023 Bharat360</p>
      </div>
    </div>
  </>
  );
}

export default Footer;
