import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../header/Navbar'
import Footer from '../footer/footer'
import img from '../Assets/Group 18.png'
import img1 from '../Assets/Screenshot 2023-09-08 104013.png'
import img3 from '../Assets/Rectangle 2224.png'
import JoinUs from '../BannerSection/JionUs'


function LiveDarshanDetails() {
    const { id } = useParams();
  const [darshanDetails, setDarshanDetails] = useState({});

  useEffect(() => {
    // Fetch darshan details based on the 'id' parameter
    fetch(`https://bharat-360-app-vzet3.ondigitalocean.app/api/darshan/getadarshan/${id}`)
      .then((response) => response.json())
      .then((data) => setDarshanDetails(data.data))
      .catch((error) => console.error('Error fetching darshan details:', error));
  }, [id]);
  return (
    <div style={{backgroundColor:'#FFFFF0'}}>
    <Navbar/>
    <div className='TeerthYatraPackage-section'>
    <div className='TeerthYatraPackage-section-left'>
    {/* <img src={product.displayImage}/> */}

    </div>
    <div className='TeerthYatraPackage-section-right'>
    <div>
    <h1>{darshanDetails.name}</h1>
    <h3>₹222 per person*<br/>
    <span> *Excluding applicable taxes</span></h3>
    <hr/>
    <p>DESCRIPTION</p>
    <h4>Embark on a soul-enriching journey through the majestic<br/>
     Himalayan region. The Himalayan Bliss Yatra is a 12-day<br/>
      pilgrimage that takes you to some of the most revered <br/>
      spiritual destinations nestled amidst the pristine beauty of the<br/>
       Himalayas.</h4>
    <div className='PackageType'>
        <p>Package Type</p>
        <h2>Spiritual Pilgrimage</h2>
    </div>
    <div className='PackageType'>
        <p>Duration</p>
        <h2>12 days</h2>
    </div>
    <div className='PackageType'>
        <p>Package Type</p>
        <h2>Spiritual Pilgrimage</h2>
    </div>

    <p style={{fontSize:'16px'}}>Persons</p>
    <button className="qty-btn">- 1 +</button>
    <br/>
    <p style={{fontSize:'16px'}}>INCLUDES</p>
    <img style={{height:'30px' ,width:'100px'}} src={img1}/><br/>
    <button className='book'>Book</button>

    </div>

   </div>

    </div>
    <br/>
    <br/>
    <div className='TeerthPackages' id='TeerthPackagess'>
    <h1 style={{textAlign:'center' , fontSize:'32px'}}>Explore <snap>Spiritual</snap> Diversity</h1>
    <br/>
    <br/>

       
       <div className='scroll-container'>
     
       <div className='TeerthPackages-cards'>
       <div className='TeerthPackages-card'>
       <div className='TeerthPackages-card-img'>
       <img src={img3}/>

       </div>
       <h3>Divine Dwarka Darshan</h3>

       <p>Vorem ipsum dolor sit amet, <br/>
       consectetur adipiscing elit. Nunc<br/>
        vulputate libero et velit interdum, <br/>
        ac aliquet odio mattis.</p>
        <h4>Package 20,000 for 4 people</h4>
        <button>View More</button>

       </div>
       <div className='TeerthPackages-card'>
       <div className='TeerthPackages-card-img'>
       <img src={img3}/>

       </div>
       <h3>Divine Dwarka Darshan</h3>

       <p>Vorem ipsum dolor sit amet, <br/>
       consectetur adipiscing elit. Nunc<br/>
        vulputate libero et velit interdum, <br/>
        ac aliquet odio mattis.</p>
        <h4>Package 20,000 for 4 people</h4>
        <button>View More</button>

       </div>
       <div className='TeerthPackages-card'>
       <div className='TeerthPackages-card-img'>
       <img src={img3}/>

       </div>
       <h3>Divine Dwarka Darshan</h3>

       <p>Vorem ipsum dolor sit amet, <br/>
       consectetur adipiscing elit. Nunc<br/>
        vulputate libero et velit interdum, <br/>
        ac aliquet odio mattis.</p>
        <h4>Package 20,000 for 4 people</h4>
        <button>View More</button>

       </div>
       <div className='TeerthPackages-card'>
       <div className='TeerthPackages-card-img'>
       <img src={img3}/>

       </div>
       <h3>Divine Dwarka Darshan</h3>

       <p>Vorem ipsum dolor sit amet, <br/>
       consectetur adipiscing elit. Nunc<br/>
        vulputate libero et velit interdum, <br/>
        ac aliquet odio mattis.</p>
        <h4>Package 20,000 for 4 people</h4>
        <button>View More</button>

       </div>

       </div>
       </div>



       {/* <button className='vi-btn' style={{marginTop:'30px'}}>View all</button> */}

       </div>
       <br/>
       <br/>
       <br/>

<JoinUs/>

    <Footer/>

   </div>
  )
}

export default LiveDarshanDetails