import React from 'react'

function JionUs() {
  return (
    <>
        <div className='join-us'>
        <div className='journey'>
        <h1>Join Us in the spiritual <br/>journey</h1>
        <p>Be the first to know about our latest deals, events, and more. <br/>Subscribe to our newsletter today.</p>
        <div className='joinus-input'>
        <input placeholder='your@email.com'/>
        <button>Sign up</button>

        </div>
        </div>

        </div>
    </>
  )
}

export default JionUs