import { Star } from "@mui/icons-material";
import {
  Button,
  CardMedia,
  Grid,
  Rating,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";

function RequestPrashad({ img, title, cost, location, id}) {
  const [value, setValue] = useState(2);
  return (
    <>
      <Grid item sm={12} md={6} lg={4}>
        <div className="request-box">
          <CardMedia
            className="request-img"
            component="img"
            height="536px"
            style={{height: "491px", objectFit: "cover"}}
            image={img}
            alt="Paella dish"
          />
          <Typography component="div" className="request-card">
            <Typography
              variant="h4"
              className="request-title"
              fontFamily="Libre Baskerville"
              color="#FFFFFF"
            >
             {title + "(" + location + ")"}
            </Typography>
            <Typography>
              <Rating
                name="simple-controlled"
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
                emptyIcon={
                  <Star style={{ opacity: 0.55 }} fontSize="inherit" />
                }
              />
            </Typography>
            <Typography
              component="div"
              display="flex"
              justifyContent="space-between"
              paddingBottom="16px"
            >
              <Typography
                variant="h6"
                fontFamily="Poppins"
                color="#FF6000"
                className="request-prise"
              >
                {"RS. "+ cost + ".00"}
              </Typography>
              <Typography>
                <Link to={`/prashad/prashad-detail/${id}`}>
                  <Button className="boot-nav-btn" variant="contained">
                    Request Prashad
                  </Button>
                </Link>
              </Typography>
            </Typography>
          </Typography>
        </div>
      </Grid>
    </>
  );
}

export default RequestPrashad;
